import React from "react"
import { Link } from "react-router-dom"

const ContractCards = ({ item: { id, cover, title, description, paragraph, category, date, url } }) => {
    return (
        <>
            <Link to={`${url}`} style={{ color: 'black' }}>
                <div className='items'>
                    <div className='img'>
                        <img src={cover} alt='Gallery' />
                    </div>

                    <div className='category flex_space'>
                        <span>{date}</span>
                        <label>{category}</label>
                    </div>

                    <div className='details'>
                        <h3>{title}</h3>
                        <p>{paragraph}</p>
                    </div>

                    <Link to={`${url}`} >
                        SÖZLEŞME OLUŞTUR <i className='fa fa-long-arrow-right'></i>
                    </Link>
                </div>
            </Link>
        </>
    )
}

export default ContractCards