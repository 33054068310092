import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import contractReducer from "./contractReducer";
// import backlogReducer from "./backlogReducer";
import securityReducer from "./securityReducer";

export const reducer = combineReducers({
    errors: errorReducer,
    contract: contractReducer,
    //   backlog: backlogReducer,
    security: securityReducer
});