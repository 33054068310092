import {
  GET_CONTRACTS,
  SET_CONTRACTS,
  DELETE_CONTRACTS,
} from "../actions/types";

const initialState = {
  contracts: [],
  contract: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CONTRACTS:
    case SET_CONTRACTS:
      return {
        ...state,
        contracts: action.payload,
      };
    case DELETE_CONTRACTS:
      return {
        ...state,
        contracts: state.contracts.filter(
          (contract) =>
            contract.renterInformations.identifierNo !== action.payload
        ),
      };
    default:
      return state;
  }
}
