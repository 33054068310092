import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import axios from "axios";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const EInvoiceContractTransform = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
  const [firmName] = useState(
    props.eInvoiceServiceContractInformation["firmName"]
  );
  const [firmType] = useState(
    props.eInvoiceServiceContractInformation["firmType"]
  );
  const [publicOrPrivateFirm] = useState(
    props.eInvoiceServiceContractInformation["publicOrPrivateFirm"]
  );
  const [taxID] = useState(props.eInvoiceServiceContractInformation["taxID"]);
  const [title] = useState(props.eInvoiceServiceContractInformation["title"]);
  const [phoneNumber] = useState(
    props.eInvoiceServiceContractInformation["phoneNumber"]
  );
  const [email] = useState(props.eInvoiceServiceContractInformation["email"]);
  const [taxAdminstration] = useState(
    props.eInvoiceServiceContractInformation["taxAdminstration"]
  );
  const [city] = useState(props.eInvoiceServiceContractInformation["city"]);
  const [district] = useState(
    props.eInvoiceServiceContractInformation["district"]
  );
  const [address] = useState(
    props.eInvoiceServiceContractInformation["address"]
  );
  const [contractPeriod] = useState(
    props.eInvoiceServiceContractInformation["contractPeriod"]
  );
  const [periodValue] = useState(
    props.eInvoiceServiceContractInformation["periodValue"]
  );
  const [contractStartDate] = useState(
    props.eInvoiceServiceContractInformation["contractStartDate"]
  );
  const [contractFinishDate] = useState(
    props.eInvoiceServiceContractInformation["contractFinishDate"]
  );
  const [serviceFee] = useState(
    props.eInvoiceServiceContractInformation["serviceFee"]
  );
  const [currency] = useState(
    props.eInvoiceServiceContractInformation["currency"]
  );
  const [monthlyDocumentLimit] = useState(
    props.eInvoiceServiceContractInformation["monthlyDocumentLimit"]
  );
  const [overLimitFee] = useState(
    props.eInvoiceServiceContractInformation["overLimitFee"]
  );
  const [serviceFeeUpdate] = useState(
    props.eInvoiceServiceContractInformation["serviceFeeUpdate"]
  );
  const [invoiceFeePeriod] = useState(
    props.eInvoiceServiceContractInformation["invoiceFeePeriod"]
  );
  const [invoiceDelayPaymentRate] = useState(
    props.eInvoiceServiceContractInformation["invoiceDelayPaymentRate"]
  );

  const sendEInvoiceContract = () => {
    const eInvoiceContract = props.eInvoiceServiceContractInformation;
    const contractHeader = {
      //contractType: 1,
      userType: "PrivateIntegrator",
      //userId: userId
    };

    Object.entries(contractHeader).forEach(([key, value]) => {
      eInvoiceContract[key] = value;
    });

    console.log(eInvoiceContract);

    axios
      .post(`api/contract/create-contract`, eInvoiceContract)
      .then((res) => {
        setShow(false);

        if (res.status === 200) {
          toast.success(
            "Sözleşmeniz Oluşturuldu. Anasayfaya Yönlendiriliyorsunuz ...",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );

          setTimeout(() => {
            history.push("/dashboard");
          }, 7000);
        }
      })
      .catch((error) => {
        toast.success("Sözleşmeniz Oluşturulurken hata oluştu.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <div>
      <Table stripped bordered hover>
        <th>Firma Bilgileri</th>
        <tbody>
          <tr>
            <td>Firma Adı</td>
            <td>{firmName}</td>
          </tr>
          <tr>
            <td>Firma Tipi</td>
            <td>{publicOrPrivateFirm}</td>
          </tr>
          <tr>
            <td>Firma Kişi Tipi</td>
            <td>{firmType}</td>
          </tr>
          <tr>
            <td>Vergi Kimlik Numarası</td>
            <td>{taxID}</td>
          </tr>
          <tr>
            <td>Vergi Dairesi İsmi</td>
            <td>{taxAdminstration}</td>
          </tr>
          <tr>
            <td>Unvan</td>
            <td>{title}</td>
          </tr>
          <tr>
            <td>Telefon Numarası</td>
            <td>{phoneNumber}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{email}</td>
          </tr>
          <tr>
            <td>İl</td>
            <td>{city}</td>
          </tr>
          <tr>
            <td>İlçe</td>
            <td>{district}</td>
          </tr>
          <tr>
            <td>Adres</td>
            <td>{address}</td>
          </tr>
        </tbody>
        <th>Sözleşme Süresi</th>
        <tbody>
          <tr>
            <td>Sözleşme Periyodu</td>
            <td>
              {periodValue} {contractPeriod}
            </td>
          </tr>
          <tr>
            <td>Sözleşme Başlangıç Tarihi</td>
            <td>{contractStartDate}</td>
          </tr>
          <tr>
            <td>Sözleşme Bitiş Tarihi</td>
            <td>{contractFinishDate}</td>
          </tr>
        </tbody>
        <th>Hizmet Bedeli</th>
        <tbody>
          <tr>
            <td>Hizmet Ücreti</td>
            <td>{serviceFee}</td>
          </tr>
          <tr>
            <td>Kur Bilgisi</td>
            <td>{currency}</td>
          </tr>
          <tr>
            <td>Aylık Doküman Limiti</td>
            <td>{monthlyDocumentLimit}</td>
          </tr>
          <tr>
            <td>Limit Aşım Ücreti</td>
            <td>{overLimitFee}</td>
          </tr>
          <tr>
            <td>Hizmet Ücreti Güncelleme Durumu</td>
            <td>{serviceFeeUpdate}</td>
          </tr>
        </tbody>
        <th>Ödeme Bilgileri</th>
        <tbody>
          <tr>
            <td>Fatura Ödeme Vadesi</td>
            <td>{invoiceFeePeriod}</td>
          </tr>
          <tr>
            <td>Fatura Gecikme Vade Farkı</td>
            <td>{invoiceDelayPaymentRate}</td>
          </tr>
          {/* <tr>
                    <td>Banka Adı</td>
                    <td>{bankName}</td>
                </tr>
                <tr>
                    <td>Şube Adı</td>
                    <td>{branchName}</td>
                </tr>
                <tr>
                    <td>IBAN Numarası</td>
                    <td>{IBAN}</td>
                </tr> */}
        </tbody>
      </Table>
      {/* <button onClick={(e) => create()} className="btn btn-danger btn-cli" type="button" id="button-addon2"><i className="far fa-file-pdf"></i> Sözleşmeyi İndir</button> */}
      <span> </span>
      <button className="btn btn-success" onClick={handleShow} type="button">
        Gönder
      </button>
      <ToastContainer />
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Firma Bilgileri</Modal.Title>
          </Modal.Header>
          <Modal.Body>{firmName}</Modal.Body>
          <Modal.Body>Telefon: {phoneNumber} </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Kapat
            </Button>
            <Button variant="primary" onClick={(e) => sendEInvoiceContract()}>
              Gönder
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default EInvoiceContractTransform;
