import PageHeader from "../Layout/PageHeader";
import PeopleIcon from "@mui/icons-material/People";
import React from "react";

const AdminDashboard = () => {
  return (
    <PageHeader
      title="Admin Panel"
      subTitle=""
      icon={<PeopleIcon fontSize="large" color="action" />}
    />
  );
};

export default AdminDashboard;
