import 'bootstrap/dist/css/bootstrap.min.css';
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { Provider, useSelector } from "react-redux";
import { Route, HashRouter as Router, Switch } from "react-router-dom";
import { logout } from "./actions/securityActions";
import { SET_CURRENT_USER } from "./actions/types";
import './App.css';
import About from "./components/About/About";
import AdminDashboard from "./components/AdminComponents/AdminDashboard";
import CompanySubscription from './components/Company/CompanySubscription.tsx';
import Customers from "./components/Company/Customers";
import Contact from "./components/Contact/Contact";
import BasicContract from "./components/Contract/BasicContracts";
import ContractArchive from "./components/Contract/ContractArchive";
import ContractDraft from "./components/Contract/ContractDraft";
import ContractIncoming from "./components/Contract/ContractIncoming";
import ContractOutgoing from "./components/Contract/ContractOutgoing";
//import OfferForm from './components/Contract/CostItems.ts';
import { styled } from '@mui/material/styles';
import ArchivedDocuments from './components/Contract/ArchivedDocuments';
import CreateContractDrafts from "./components/Contract/CreateContractDrafts";
import DraftDocuments from './components/Contract/DraftDocuments';
import EntryBasicContract from "./components/Contract/EntryBasicContract";
import EntryEInvoiceIntegratorContract from "./components/Contract/EntryEInvoiceIntegratorContract";
import EntryRentalContracts from "./components/Contract/EntryRentalContracts";
import EstateAgentContracts from "./components/Contract/EstateAgentContract";
import IncomingDocuments from "./components/Contract/IncomingDocuments";
import InvoiceArchive from "./components/Contract/InvoiceArchive";
import InvoiceDraft from "./components/Contract/InvoiceDraft";
import InvoiceIncoming from "./components/Contract/InvoiceIncoming";
import InvoiceOutgoing from "./components/Contract/InvoiceOutgoing";
import OfferArchive from "./components/Contract/OfferArchive";
import OfferDraft from "./components/Contract/OfferDraft";
import OfferIncoming from "./components/Contract/OfferIncoming";
import OfferOutgoing from "./components/Contract/OfferOutgoing";
import OutgoingDocuments from './components/Contract/OutgoingDocuments';
import PrivateIntegratorContracts from "./components/Contract/PrivateIntegratorContracts";
import Dashboard from "./components/Dashboard";
import EntryInvoice from "./components/Invoice/EntryInvoice";
import InvoiceCards from "./components/Invoice/InvoiceCards";
import Footer from "./components/Layout/Footer";
import Navbarr from "./components/Layout/Navbarr";
import Sidebar from './components/Layout/Sidebarr';
import Home from "./components/Pages/Home";
import Profile from "./components/Profile";
import Service from "./components/Services/Service";
import ServiceSingle from "./components/Services/Service-single-page/Service-single";
import Templates from "./components/Template/Templates";
import Login from "./components/UserManagement/Login";
import Register from "./components/UserManagement/Register";
import ResetPassword from "./components/UserManagement/ResetPassword.js";
import ContractQuery from "./components/ViewContract/ContractQuery";
import setup from './localization/setup';
import SecuredRoute from "./securityUtils/SecureRoute";
import setJWTToken from "./securityUtils/setJWTToken";
import store from "./store";

const AppLayout = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const MainContentWrapper = styled('div')({
  display: 'flex',
  flexGrow: 1,
});

const SidebarWrapper = styled('div')({
  width: '250px', // Sidebar genişliği
  backgroundColor: '#f4f4f4',
});

const ContentArea = styled('div')({
  flexGrow: 1,
  padding: '20px',
  overflow: 'auto',
});


const jwtToken = localStorage.jwtToken;

if (jwtToken) {
  setJWTToken(jwtToken);
  const decoded_jwtToken = jwt_decode(jwtToken);
  store.dispatch({
    type: SET_CURRENT_USER,
    payload: decoded_jwtToken
  });

  const currentTime = Date.now() / 1000;
  if (decoded_jwtToken.exp < currentTime) {
    store.dispatch(logout());
    window.location.href = "/";
  }
}

const AppContent = () => {
  const validToken = useSelector(state => state.security.validToken);
  const user = useSelector(state => state.security.user);

  return (
    <MainContentWrapper>
      {validToken && (user.role === "ROLE_USER" || user.role === "ROLE_COMPANY_REPRESENTATIVE") && (
        <SidebarWrapper>
          <Sidebar />
        </SidebarWrapper>
      )}
      
      <ContentArea>
            
            
              <Route exact path="/" component={Home} />
              <Route path='/about' exact component={About} />
              <Route path='/contact' exact component={Contact} />
              <Route exact path="/register" component={Register} />
              <Route path='/services' exact component={Service} />
              <Route path='/serviceSingle/:id' component={ServiceSingle} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/contractQuery" component={ContractQuery} />
              <Route exact path="/ResetPassword" component={ResetPassword} />
              <Route exact path="/companysubscription" component={CompanySubscription} />


              <Switch>
                {/*Client Routes */}
                <SecuredRoute exact path="/dashboard" component={Dashboard} />
                <SecuredRoute exact path="/entryRentalContracts" component={EntryRentalContracts} />
                <SecuredRoute exact path="/entryEInvoiceIntegratorContract" component={EntryEInvoiceIntegratorContract} />
                <SecuredRoute exact path="/entryBasicContract" component={EntryBasicContract} />
                <SecuredRoute exact path="/entryInvoice" component={EntryInvoice} />
                <SecuredRoute exact path="/privateIntegratorContracts" component={PrivateIntegratorContracts} />
                <SecuredRoute exact path="/estateAgentContracts" component={EstateAgentContracts} />
                <SecuredRoute exact path="/basicContracts" component={BasicContract} />
                <SecuredRoute exact path="/draftContracts" component={ContractDraft} />
                <SecuredRoute exact path="/incomingContracts" component={ContractIncoming} />
                <SecuredRoute exact path="/outgoingContracts" component={ContractOutgoing} />
                <SecuredRoute exact path="/archiveContracts" component={ContractArchive} />
                <SecuredRoute exact path="/draftOffers" component={OfferDraft} />
                <SecuredRoute exact path="/incomingOffers" component={OfferIncoming} />
                <SecuredRoute exact path="/outgoingOffers" component={OfferOutgoing} />
                <SecuredRoute exact path="/archiveOffers" component={OfferArchive} />
                <SecuredRoute exact path="/draftInvoices" component={InvoiceDraft} />
                <SecuredRoute exact path="/incomingInvoices" component={InvoiceIncoming} />
                <SecuredRoute exact path="/outgoingInvoices" component={InvoiceOutgoing} />
                <SecuredRoute exact path="/archiveInvoices" component={InvoiceArchive} />
                <SecuredRoute exact path="/invoiceCards" component={InvoiceCards} />
                <SecuredRoute exact path="/profile" component={Profile} />
                <SecuredRoute exact path="/customers" component={Customers} />
                <SecuredRoute exact path="/templates" component={Templates} />
                <SecuredRoute exact path="/archivedDocuments" component={ArchivedDocuments} />
                <SecuredRoute exact path="/incomingDocuments" component={IncomingDocuments} />
                <SecuredRoute exact path="/draftDocuments" component={DraftDocuments} />
                <SecuredRoute exact path="/outgoingDocuments" component={OutgoingDocuments} />

                {/* <SecuredRoute exact path="/createContractDrafts" component={CreateContractDrafts} /> */}







                {/*Admin Routes */}
                <SecuredRoute exact path="/createContractDrafts" adminComponent={CreateContractDrafts} />
                <SecuredRoute exact path="/adminDashboard" adminComponent={AdminDashboard} />

              </Switch>
            </ContentArea>
    </MainContentWrapper>
  );
};


const App = () => {
  useEffect(() => {
    setup();
    // Storage olayını dinleyici ekleme
    const handleStorageChange = (event) => {
      console.log('Storage event detected:', event); // Olayı konsola yazdır
      if (event.key === 'jwtToken' && event.newValue) {
        const decoded_jwtToken = jwt_decode(event.newValue);
        store.dispatch({
          type: SET_CURRENT_USER,
          payload: decoded_jwtToken
        });
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Temizlik: Dinleyiciyi kaldırma
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [store.dispatch]);

  return (
    <Provider store={store}>
      <Router>
      <Navbarr />
        <AppLayout>
          
           <AppContent/>
            <Footer />
            
        </AppLayout>
      </Router>
    </Provider>
  );
}

export default App;
