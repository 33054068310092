import AccountTree from "@mui/icons-material/AccountTree";
import ArchiveIcon from "@mui/icons-material/Archive";
import Autorenew from "@mui/icons-material/Autorenew";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid, trTR } from "@mui/x-data-grid";
import axios from "axios";
import { FileSignature } from "lucide-react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { DocumentCreationType, DocumentTypes } from "../../actions/types";
import { convertHtmlToPdf } from "../../util/pdf/PdfConverter";
import "../Layout/datatable.scss";
import { userColumns } from "../Layout/datatablesource";
import PageHeader from "../Layout/PageHeader";
import RelatedDocuments from "./RelatedDocuments";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      Teklif Kaydı Bulunmamaktadır
    </Stack>
  );
}

const OfferOutgoing = () => {
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.security.user);
  const userId = user.id;
  const [url, setUrl] = useState(null);
  const [show, setShow] = useState(false);
  const [archiveShow, setArchiveShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [relatedDocument, setRelatedDocument] = useState(null);
  const [relatedDocuments, setRelatedDocuments] = useState(null);

  async function contractData(userId) {
    let request = {
      userId: userId,
      documentType: DocumentTypes.Offer,
      status: null,
    };
    await axios
      .post(`api/contract/get-outgoing-contracts`, request)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    contractData(userId);
  }, [userId]);

  async function handleDelete() {
    await axios
      .post(`api/contract/delete-contract/${selectedRow.contractId}`)
      .then((res) => {
        contractData(userId);
        setDeleteShow(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleArchive() {
    await axios
      .post(`api/contract/move-contract-archive/${selectedRow.contractId}`)
      .then((res) => {
        contractData(userId);
        setArchiveShow(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const create = (docDefinition) => {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      setUrl(url);
    });
  };

  async function showModal(contractId, show) {
    if (show) {
      setShow(false);
      return;
    }
  
    try {
      const response = await axios.get(`api/contract/get-document-file/${contractId}`);
      
      if (response.data.isSigned) {
        setUrl(response.data.preSignedUrl);
        setShow(true);
        return;
      }
  
      const content = response.data.htmlContent;
      
      // Sadece PDF Blob'ı al
      const pdfBlob = await convertHtmlToPdf(content);
      
      // Blob'dan URL oluştur - bu kısım görüntüleme mantığına ait
      const pdfUrl = URL.createObjectURL(pdfBlob);
      
      setUrl(pdfUrl);
      setShow(true);
  
    } catch (error) {
      console.error('Error in contract modal:', error);
    }
  }


  async function handleCellClick(e) {
    setSelectedRow(e.row);
  }

  async function getRelatedDocuments(row) {
    setRelatedDocuments(null);
    setRelatedDocument(row);
    await axios
      .get(
        `api/contract/get-all-document-by-related-document-id/${row.contractId}`
      )
      .then((res) => {
        if (res.data.length > 0) {
          setRelatedDocuments(res.data);
        } else {
          toast.info("İlişkili Belge Bulunamadı", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Modal show={show} onHide={() => setShow(false)}>
              <div onClick={() => setShow(false)}></div>
              <div style={{ position: "fixed" }}>
                {url && (
                  <object
                    style={{
                      width: "200%",
                      height: "75vh",
                    }}
                    data={url}
                    type="application/pdf"
                  >
                    <embed
                      frameborder="0"
                      width="300%"
                      height="500px"
                      src={url}
                      type="application/pdf"
                    />
                  </object>
                )}
              </div>
            </Modal>
            <Modal show={archiveShow} onHide={() => setArchiveShow(false)}>
              <Modal.Body>Teklif Arşivlensin mi?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setArchiveShow(false)}
                >
                  Hayır
                </Button>
                <Button variant="primary" onClick={(e) => handleArchive()}>
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={deleteShow} onHide={() => setDeleteShow(false)}>
              <Modal.Body>Teklif Silinsin mi?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setDeleteShow(false)}
                >
                  Hayır
                </Button>
                <Button variant="primary" onClick={(e) => handleDelete()}>
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            {params.row.isSigned ? (
              <Tooltip title="İmzalı">
              <FileSignature color="#673AB7" size={48} />
            </Tooltip>
            ): null}
            <Tooltip title="Görüntüle" onHide={() => setShow(false)}>
              <IconButton>
                <SlideshowIcon
                  color="success"
                  onClick={() =>
                    showModal(
                      params.row.contractId,
                      show
                    )
                  }
                  onHide={() => setShow(false)}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="İlişkili Belge Oluştur">
              <IconButton>
                <Link
                  to={{
                    pathname: "/entryBasicContract",
                    state: {
                      documentCreationType:
                        DocumentCreationType.RelatedDocument,
                      documentType: params.row.documentType,
                      id: params.row.contractId,
                    },
                  }}
                  style={{ color: "gray" }}
                >
                  <NoteAddIcon
                    style={{ color: "purple", variant: "fullwidth" }}
                  />
                </Link>
              </IconButton>
            </Tooltip>
            <Tooltip title="Doküman Yenile">
              <IconButton>
                <Link
                  to={{
                    pathname: "/entryBasicContract",
                    state: {
                      documentCreationType: DocumentCreationType.Renewal,
                      id: params.row.contractId,
                    },
                  }}
                  style={{ color: "gray" }}
                >
                  <Autorenew
                    style={{ color: "orange", variant: "fullwidth" }}
                  />
                </Link>
              </IconButton>
            </Tooltip>

            <Tooltip title="İlişkili Belge Listele">
              <IconButton
                onClick={() => {
                  getRelatedDocuments(params.row);
                }}
              >
                <AccountTree
                  style={{ color: "darkblue", variant: "fullwidth" }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Arşivle">
              <IconButton onClick={() => setArchiveShow(true)}>
                <ArchiveIcon color="info" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sil">
              <IconButton onClick={() => setDeleteShow(true)}>
                <DeleteIcon color="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <>
      {relatedDocuments != null ? (
        <RelatedDocuments
          document={relatedDocument}
          relatedDocuments={relatedDocuments}
        />
      ) : null}
      <ToastContainer />
      <PageHeader
        title="Gönderilen Teklifler"
        subTitle="Gönderilen Teklifleri görüntüleyebilirsiniz."
        icon={<ArchiveIcon fontSize="large" color="action" />}
      />
      <div className="home">
        <div className="homeContainer">
          <div className="listContainer">
            <div className="datatable">
              <div className="datatableTitle">Teklifler</div>
              <DataGrid
                className="datagrid"
                rows={data}
                getRowId={(row) => row.contractId}
                columns={userColumns.concat(actionColumn)}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                components={{ NoRowsOverlay }}
                pageSize={8}
                onCellClick={handleCellClick}
                rowsPerPageOptions={[10]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferOutgoing;
