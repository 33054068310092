import { fileTypeFromBuffer } from 'file-type';

// Çalıştırılabilir MIME türlerini tanımlama
const executableMimeTypes = [
  "application/x-executable",
            "application/x-elf",
            "application/x-sharedlib",
            "application/x-mach-binary",
            "application/x-msdownload",
            "application/x-msdos-program",
            "application/vnd.microsoft.portable-executable",
            "application/x-dosexec",
            "application/x-ms-dos-executable",
            "application/x-winexe",
            "application/x-winhelp",
            "application/x-ms-shortcut",
            "application/x-ms-wmd",
            "application/x-mach-binary",
            "application/x-apple-diskimage",
            "application/x-sh",
            "application/x-shar",
            "application/x-bat",
            "application/x-csh",
            "application/x-ksh",
            "text/x-shellscript",
            "application/x-python",
            "text/x-python",
            "application/x-ruby",
            "text/x-ruby",
            "application/x-perl",
            "text/x-perl",
            "application/x-php",
            "text/x-php",
            "application/x-httpd-php",
            "application/x-tcl",
            "text/x-tcl",
            "application/x-javascript",
            "text/javascript",
            "application/vnd.ms-excel.sheet.macroEnabled.12",
            "application/vnd.ms-word.document.macroEnabled.12",
            "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
            "application/java-archive",
            "application/x-java-archive",
            "application/x-java-jnlp-file",
            "application/vnd.android.package-archive",
            "application/x-msdownload",
            "application/x-ms-application",
            "application/vnd.ms-xpsdocument",
            "application/x-silverlight-app",
            "application/zip",
            "application/x-7z-compressed",     // .sh (Shell Script)
];

export const isExecutable = async (file) => {
  const fileBuffer = await file.arrayBuffer(); // File nesnesinden buffer elde et
  const { mime } = await fileTypeFromBuffer(fileBuffer);
  return executableMimeTypes.includes(mime);
};

export const getMimeType = async (file) => {
  const fileBuffer = await file.arrayBuffer(); // File nesnesinden buffer elde et
  const { mime } = await fileTypeFromBuffer(fileBuffer);
  return mime;
};

// Yeni fonksiyon: MIME tipi ve çalıştırılabilirlik durumunu döndürme
export const getContentType = async (file) => {
  const fileBuffer = await file.arrayBuffer(); // File nesnesinden buffer elde et
  let mime = null;
  try{
   ({ mime } = await fileTypeFromBuffer(fileBuffer));
  }
  catch(error){
    mime = file.type;
  }
  const isExecutable = executableMimeTypes.includes(mime);
  return { contentType: mime, isExecutable };
};
