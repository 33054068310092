import React from "react";
import HeadTitle from "../Layout/HeadTitle";
import AllService from "./AllService";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const Service = () => {
  const token = useSelector((state) => state.security.validToken);

  return (
    <>
      {token ? (
        <Redirect to="/dashboard" />
      ) : (
        <>
          <HeadTitle title="Hizmetler" />
          <AllService />
        </>
      )}
    </>
  );
};

export default Service;
