const contractStatus = [
    { value: 1, label: "Taslak" },
    { value: 2, label: "Onay Bekliyor" },
    { value: 3, label: "Onaylandı" },
    { value: 4, label: "Reddedildi" },
  ];

export const userColumns = [
    { field: "generatedId", headerName: "Numarası", width: 200 },
    {
        field: "contractType",
        headerName: "Doküman Tipi",
        width: 230,
    },
    {
        field: "contractorName",
        headerName: "Doküman Tarafı",
        width: 400,
    },
    {
        field: "contractCreateDate",
        headerName: "Oluşturma Tarihi",
        width: 230,
    },
    {
        field: "status",
        headerName: "Statü",
        width: 150,
        type: "singleSelect",
        valueOptions: contractStatus,
        valueFormatter: ({ id, value, field }) => {
          const option = contractStatus.find(
            ({ value: optionValue }) => optionValue === value
          );
    
          return option.label;
        },
    },
];

