import React, { useState } from "react";
import { TextField, Button, Stepper, Step, StepLabel } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useForm, FormProvider } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Fatura Bilgileri", "Alıcı Bilgileri"];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <InvoiceInformations />;
    case 1:
      return <ReceiverInformation />;
    default:
      return null;
  }
}

const InvoiceInformations = () => {
  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 4;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  return (
    <>
      <Box
        component="form"
        sx={{ width: "50%", margin: "auto" }}
        noValidate
        align="center"
        autoComplete="off"
      >
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-label">Senaryo</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper-label"
            label="Sözleşme Periyodu"
            margin="normal"
            fullWidth
            MenuProps={MenuProps}
          >
            <MenuItem sx={{ minWidth: 200 }} value={"Günlük"}>
              Temel Fatura
            </MenuItem>
            <MenuItem sx={{ minWidth: 200 }} value={"Aylık"}>
              Ticari Fatura
            </MenuItem>
            <MenuItem sx={{ minWidth: 200 }} value={"Yıllık"}>
              İhracat Fatura
            </MenuItem>
            <MenuItem sx={{ minWidth: 200 }} value={"Tek Seferlik"}>
              Özel Fatura
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="contract-start-date"
          type="date"
          label="Fatura Tarihi"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          id="contract-start-date"
          type="date"
          label="Fatura Zamanı"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-label">Fatura Tipi</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper-label"
            label="Sözleşme Periyodu"
            margin="normal"
            fullWidth
            MenuProps={MenuProps}
          >
            <MenuItem sx={{ minWidth: 200 }} value={"Günlük"}>
              SATIŞ
            </MenuItem>
            <MenuItem sx={{ minWidth: 200 }} value={"Aylık"}>
              İADE
            </MenuItem>
            <MenuItem sx={{ minWidth: 200 }} value={"Yıllık"}>
              TEVKİFAT
            </MenuItem>
            <MenuItem sx={{ minWidth: 200 }} value={"Tek Seferlik"}>
              İSTİSNA
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="contract-start-date"
          type="text"
          label="Para Birimi"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
    </>
  );
};

const ReceiverInformation = () => {

  return (
    <>
      <Box
        component="form"
        sx={{ width: "50%", margin: "auto" }}
        noValidate
        align="center"
        autoComplete="off"
      >
        <TextField
          id="contract-start-date"
          type="text"
          label="Ad Soyad"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          id="contract-start-date"
          type="text"
          label="VKN / TCKN"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          id="contract-start-date"
          type="text"
          label="Adres"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          id="contract-start-date"
          type="text"
          label="Telefon"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          id="contract-start-date"
          type="text"
          label="E-posta"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
    </>
  );
};

const InvoiceLinearStrepper = () => {
  const classes = useStyles();

  const methods = useForm({
    defaultValues: {},
  });

  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();
  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };
  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = (data) => {
    console.log(data);
    if (activeStep === steps.length - 1) {
      setActiveStep(activeStep + 1);
      toast.info("Sözleşme Bilgilerinizi Kontrol Ediniz", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
    
          if (isStepFalied() && activeStep === index) {
            labelProps.error = true;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <div></div>
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleNext)}>
              {getStepContent(activeStep)}
              <Box
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "75ch" },
                }}
                noValidate
                align="right"
                autoComplete="off"
              >
                <Button
                  className={classes.button}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  variant="outlined"
                  size="large"
                  color="primary"
                >
                  geri
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={activeStep === 1}
                  // onClick={handleNext}
                  type="submit"
                >
                  {activeStep === steps.length - 1 ? "Tamamla" : "İleri"}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};

export default InvoiceLinearStrepper;
