import React from "react"
import "./Footer.css"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <>
      <footer>
        <div className='container grid'>
          <div className='box'>
            <h2>HAKKIMIZDA</h2>
            <p>Sözleşmelerinizi elektronik ortamda oluşturup, onaylamanıza, raporlamanıza  imkan sağlayan uygulamadır.</p>
            <br />
            <p>Fatura Modülü ile Gelir İdaresi Başkanlığı fatura gereksinimlerine uygun e-fatura/e-arşiv üretimine imkan verir.</p>
            <div className='icon flex_space'>
              <i className='fab fa-facebook-f'></i>
              <i className='fab fa-twitter'></i>
              <i className='fab fa-linkedin'></i>
              <i className='fab fa-instagram'></i>
              <i className='fab fa-pinterest'></i>
              <i className='fab fa-youtube'></i>
            </div>
          </div>

          <div className='box'>
            <h2>SAYFALAR</h2>
            <ul>
              <li>
                <Link to='/'>Anasayfa</Link>
              </li>
              <li>
                <Link to='/login'>Giriş Yap</Link>
              </li>
              <li>
                <Link to='/register'>Kayıt Ol</Link>
              </li>
              <li>
                <Link to='/contractQuery'>Sözleşme Sorgula</Link>
              </li>
              <li>
                <Link to='/about'>Hakkımızda</Link>
              </li>
              <li>
                <Link to='/services'>Hizmetler</Link>
              </li>
              <li>
                <Link to='/contact'>İletişim</Link>
              </li>
            </ul>
          </div>

          <div className='box post'>
            <h2>GÖNDERİLER</h2>
            <ul>
              <li>
                <p>Dijital Sözleşme Platformu Kira Sözleşme Modülü yayına alındı.</p>
                <label className='fa fa-calendar-alt'></label>
                <span>01 Eyl 2022</span>
              </li>
              <li>
                <p>Dijital Sözleşme Platformu Entegratör Modülü Yayında.</p>
                <label className='fa fa-calendar-alt'></label>
                <span>15 Eki 2022</span>
              </li>
              <li>
                <p>Dijital Sözleşme Platformu Temel Sözleşme Modülü Yayında.</p>
                <label className='fa fa-calendar-alt'></label>
                <span>06 Kas 2022</span>
              </li>
            </ul>
          </div>

          <div className='box'>
            <h2>HABERLER</h2>
            <p>Diji Söz hakkında haberler için sosyal medya hesaplarımızı takip edebilirsiniz.</p>

            <input type='text' name='' id='' />
            <input type='text' className='primary-btn' value='SUBSCRIBE' />
          </div>
        </div>
      </footer>
      <div className='legal'>
        <p>© 2022 All Rights Reserved.</p>
      </div>
    </>
  )
}

export default Footer