import axios from "axios";
import jwt_decode from "jwt-decode";
import setJWTToken from "../securityUtils/setJWTToken";
import {
  GET_ERRORS,
  GET_ESTATE_AGENT_USER,
  RegisterFrom,
  SET_CURRENT_USER,
  SET_USER,
} from "./types";

export const createNewUser = (registerRequest, history) => async (dispatch) => {
  try {
    await axios.post("api/users/register", registerRequest);
    history.push("/login");
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    console.log(err)
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const login = (LoginRequest) => async (dispatch) => {
  try {
    // post => Login Request
    const res = await axios.post("api/users/login", LoginRequest);
    // extract token from res.data
    console.log(res.data);
    if(res.data.authenticated) {
    const { token } = res.data;
    // store the token in the localStorage
    localStorage.setItem("jwtToken", token);
    // set our token in header ***
    setJWTToken(token);
    // decode token on React
    const decoded = jwt_decode(token);
    // dispatch to our securityReducer
    //console.log(decoded);
    dispatch({
      type: SET_CURRENT_USER,
      payload: decoded,
    });} else {
      dispatch({
        type: GET_ERRORS,
        payload: { message: res.data.message } ,
      });
    }
  } catch (err) {
    console.log("errora girdi");
    console.log(err);
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const registerLoginExternal = (User) => async (dispatch) => {
  try {
    // post => Login Request
    console.log(User);
    const res = await axios.post("api/users/register-login-external", User);
    // extract token from res.data
    const { token } = res.data;
    // store the token in the localStorage
    localStorage.setItem("jwtToken", token);
    // set our token in header ***
    setJWTToken(token);
    // decode token on React
    const decoded = jwt_decode(token);
    // dispatch to our securityReducer
    //console.log(decoded);
    dispatch({
      type: SET_CURRENT_USER,
      payload: decoded,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload:
        User.registerFrom === RegisterFrom.Facebook
          ? { facebook: "Facebook ile girişte hata oluştu" }
          : { google: "Google ile girişte hata oluştu" },
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  setJWTToken(false);
  dispatch({
    type: SET_CURRENT_USER,
    payload: {},
  });
};

export const getEstateAgentUser = (id) => async (dispatch) => {
  const res = await axios.get(`api/users/get-estate-agent/${id}`);

  try {
    dispatch({
      type: GET_ESTATE_AGENT_USER,
      payload: res.data,
    });
  } catch (err) {}
};

export const getUser = (id) => async (dispatch) => {
  const res = await axios.get(`api/users/get-user/${id}`);
  console.log(res.data);
  try {
    dispatch({
      type: SET_USER,
      payload: res.data,
    });
  } catch (err) {}
};

export const estateAgentRegister = (newUser, history) => async (dispatch) => {
  try {
    await axios.post("api/users/register-estate-agent", newUser);
    history.push("/dashboard");
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
