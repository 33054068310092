import { Button, TextField } from "@material-ui/core";
import BorderColorSharpIcon from "@mui/icons-material/BorderColorSharp";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import axios from "axios";
import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageHeader from "../Layout/PageHeader";

const CreateContractDrafts = () => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [contractName, setContractName] = useState("");
  const [userId, setUserId] = useState();
  const [userList, setUserList] = useState();
  const [documentTypeList, setDocumentTypeList] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [documentType, setDocumentType] = useState(null);

  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 4;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 350,
      },
    },
  };

  async function informationForDraft() {
    await axios
      .get(`api/v1/admin/get-all-user`)
      .then((res) => {
        setUserList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

    await axios
      .get(`api/v1/admin/get-all-document-type`)
      .then((res) => {
        setDocumentTypeList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    informationForDraft();
  }, []);

  const contentFieldChanaged = (data) => {
    setContent(data);
  };

  const createContractDraft = () => {
    let contractTemplates = {
      userId: userId,
      contractName: contractName,
      contractHtmlTemplate: content,
      documentType: documentType,
    };

    axios
      .post("api/contract-template/create", contractTemplates)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Taslak Kaydedildi", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        toast.error("Hata oluştu, Taslak Kayıt Edilemedi", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    handleClose();
  };

  return (
    <>
      <PageHeader
        title="Şablon Oluşturma Editörü"
        subTitle="Editörü kullanarak Sözleşme Şablonlarınızı Oluşturabilirsiniz."
        icon={<BorderColorSharpIcon fontSize="medium" color="action" />}
      />

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <FormControl>
          <InputLabel id="test-select-label">Kullanıcı Adı</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper-label"
            label="Kullanıcı Adı"
            value={userId}
            onChange={(e) => {
              setUserId(e.target.value);
            }}
            style={{ width: 200 }}
            MenuProps={MenuProps}
          >
            {userList ? (
              userList.map((user, index) => {
                return (
                  <MenuItem
                    key={index}
                    sx={{ minWidth: 180 }}
                    value={user.userId}
                  >
                    {user.userName}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem></MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="test-select-label">Belge Tipi</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper-label"
            label="Belge Tipi"
            value={documentType}
            onChange={(e) => {
              setDocumentType(e.target.value);
            }}
            style={{ width: 200 }}
            MenuProps={MenuProps}
          >
            {documentTypeList ? (
              documentTypeList.map((documentType, index) => {
                return (
                  <MenuItem
                    key={index}
                    sx={{ minWidth: 180 }}
                    value={documentType.id}
                  >
                    {documentType.name}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem></MenuItem>
            )}
          </Select>
        </FormControl>

        <TextField
          label="Sözleşme Adı"
          placeholder="Sözleşme Tipi"
          variant="outlined"
          name="contractName"
          value={contractName}
          onChange={(e) => {
            setContractName(e.target.value);
          }}
        />

        <button className="btn btn-success" onClick={handleShow} type="button">
          Şablonu Kaydet
        </button>
        <ToastContainer />
        <div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Body>Taslak Kaydedilsin mi?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Hayır
              </Button>
              <Button variant="primary" onClick={() => createContractDraft()}>
                Evet
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Stack>

      <div></div>

      <div margin="normal" style={{ marginTop: `20px` }}>
        <JoditEditor
          ref={editor}
          // config={config}
          value={content}
          onChange={(newContent) => contentFieldChanaged(newContent)}
        />
      </div>
    </>
  );
};

export default CreateContractDrafts;
