import React from "react";
import HeadTitle from "../Layout/HeadTitle";
import ContactForm from "./ContactForm";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const Contact = () => {
  const token = useSelector((state) => state.security.validToken);

  return (
    <>
      {token ? (
        <Redirect to="/dashboard" />
      ) : (
        <>
          <HeadTitle title="İletişim" />
          <ContactForm />
        </>
      )}
    </>
  );
};

export default Contact;
