import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import "../../App.css";
import Download from "../HomeSection/Download/Download";
import Hero from "../HomeSection/Hero";
import HomeAbout from "../HomeSection/HomeAbout";
import Works from "../HomeSection/Works/Works";
import Gallery from "../HomeSection/gallery/Gallery";
import MostPopular from "../HomeSection/popular/MostPopular";

const Home = () => {
  const token = useSelector((state) => state.security.validToken);
  const user = useSelector((state) => state.security.user);

  function pageRedirect(role) {
    if (role === "ROLE_COMPANY_REPRESENTATIVE" || role === "ROLE_USER") {
      return "/dashboard";
    } else if (role === "ROLE_ADMIN") {
      return "/adminDashboard";
    }
  }

  return (
    <>
      {token ? (
        <Redirect to={pageRedirect(user["role"])} />
      ) : (
        <>
          <Hero />
          <HomeAbout />
          <MostPopular />
          <Download />
          <Works />
          <Gallery />
        </>
      )}
    </>
  );
};

export default Home;
