import axios from 'axios';
import { UseFormSetError } from 'react-hook-form';
import { Endpoint } from '../../constant/Endpoint';
import { ResponseCode } from '../../constant/ResponseCode';
import { ApiResponse } from '../../type/ApiResponse';
import { CompanySubscriptionRequest } from "../../type/CompanySubscriptionRequest";
import { transformAndSetApiValidationError } from '../transformApiValidationError';

export const CompanySubscriptionService = async (request: CompanySubscriptionRequest, 
    setError: UseFormSetError<any>
): Promise<ApiResponse> => {
    const response = await axios.post<ApiResponse>(Endpoint.Customer.CompanySubscription, request);
    console.log("CompanySubscriptionService 1")
    if(response.data.code === ResponseCode.VALIDATION_ERROR){
        console.log("CompanySubscriptionService")
        console.log(response.data)
        transformAndSetApiValidationError(response.data.data, setError);
    }

    return response.data;
};