import * as Validation from "yup";
import { isValidIdentityNumber, isValidMobilePhoneNumber, isValidPassword } from "../util/validation/Validator";
//const {t} = useTranslation();

export interface UserInfo {
  id: number | null;
  firstName: string;
  lastName: string;
  identityNumber: string;
  username: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
}

export class UserInfo implements UserInfo {
  id: number | null = null;
  firstName = '';
  lastName = '';
  identityNumber = '';
  username = '';
  phoneNumber = '';
  password = '';
  confirmPassword = '';

  static create(): UserInfo {
    return new UserInfo();
  }
}


export const UserInfoSchema = (t: Function) =>
  Validation.object().shape({
  id: Validation.number().nullable().default(null),
  firstName: Validation.string().required(t('validation:NotBlank')),
  lastName: Validation.string().required(t('validation:NotBlank')),
  identityNumber: Validation.string()
    .required(t('validation:NotBlank'))
    .test("ValidIdentityNumber", t('validation:ValidIdentityNumber'), isValidIdentityNumber),
  username: Validation.string()
    .required(t('validation:NotBlank'))
    .email(t('validation:ValidEmailAddress')),
  phoneNumber: Validation.string()
    .required(t('validation:NotBlank'))
    .test("ValidMobilePhoneNumber", t('validation:ValidMobilePhoneNumber'), isValidMobilePhoneNumber ),
  password: Validation.string()
    .required(t('validation:NotBlank'))
    .test("ValidPassword", t('validation:ValidPassword'), isValidPassword),
  confirmPassword: Validation.string()
    .oneOf([Validation.ref("password")], t('validation:PasswordMatch'))
    .required(t('validation:NotBlank')),
});
