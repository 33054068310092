import { SET_CURRENT_USER, GET_ESTATE_AGENT_USER, SET_USER } from "../actions/types";

const initialState = {
  validToken: false,
  user: {},
  estateAgentUser: {},
  userAllInformation: {}
};

const booleanActionPayload = payload => {
  if (payload) {
    return true;
  } else {
    return false;
  }
};

export default function (state = initialState, action) {

  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        validToken: booleanActionPayload(action.payload),
        user: action.payload
      };

    case GET_ESTATE_AGENT_USER:
      return {
        ...state,
        estateAgentUser: action.payload
      };

    case SET_USER:
      return {
        ...state,
        userAllInformation: action.payload
      };
    default:
      return state;
  }
}
