import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import ServiceData from "../ServiceData"
import { useParams } from "react-router-dom"
import HeadTitle from "../../Layout/HeadTitle"
import "./singlepage.css"

const SinglePage = () => {
    const { id } = useParams()
    const [item, setItem] = useState(null)

    useEffect(() => {
        let item = ServiceData.find((item) => item.id === parseInt(id))
        if (item) {
            setItem(item)
        }
    }, [id])

    return (
        <>
            {item ? (
                <>
                    <HeadTitle title={item.title} />
                    <section className='single-page top'>
                        <div className='container'>
                            <Link to='/services' className='primary-btn back'>
                                <i className='fas fa-long-arrow-alt-left'></i> Önceki Sayfa
                            </Link>

                            {/* --------- main-content--------- */}

                            <article className='content flex'>
                                <div className='main-content'>
                                    <img src={item.image} alt='' />
                                    <h1>{item.title} Nedir ?</h1>
                                    <p>{item.desc}</p>
                                    <p>{item.desc}</p>

                                    {/* <div className='para flex_space'>
                                        <p>{item.sidepara}</p>
                                        <p>{item.sidepara}</p>
                                    </div> */}
                                    {/* <h1>{item.title} Nedir ?</h1>
                                    <p>{item.desc}</p> */}

                                    {/* <div className='image grid1 '>
                                        <img src={item.paraImage_one} alt='' />
                                        <img src={item.paraImage_two} alt='' />
                                    </div>
                                    <p>{item.desc}</p> */}
                                </div>
                                {/* --------- main-content--------- */}

                                {/* --------- side-content--------- */}
                                <div className='side-content'>
                                    <div className='box'>
                                        <h2>Size Nasıl Yardımcı Olabiliriz?</h2>
                                        <p>{item.sidepara}</p>
                                        <Link to="/contact" style={{ color: 'white' }}>
                                            <button className='outline-btn'>
                                                <i className='fa fa-phone-alt'></i> İletişim
                                            </button>
                                        </Link>
                                    </div>
                                    {/* <div className='box2'>
                                        <p>{item.sidepara}</p>
                                    </div> */}
                                </div>
                                {/* --------- side-content--------- */}
                            </article>
                        </div>
                    </section>
                </>
            ) : (
                <div></div>
                // <EmptyFile />
            )}
        </>
    )
}

export default SinglePage
