import { AttachFile, DocumentScanner } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Card, CardContent, IconButton, List, ListItem, Typography } from '@mui/material';
import axios from "axios";
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  useFormContext
} from "react-hook-form";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getContentType } from './ContentTypeDetector';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB
const MAX_FILES = 3;

const FileUpload = ({ initialDocuments, onFileSelect }) => {
  const [attachedDocuments, setAttachedDocuments] = useState([]);
  const {
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();


  useEffect(() => {
    setAttachedDocuments(initialDocuments);
  }, []);

  const onDrop = useCallback(async (acceptedFiles) => {
    if (attachedDocuments.length + acceptedFiles.length > MAX_FILES) {
      toast.error(`En fazla ${MAX_FILES} adet dosya yükleyebilirsiniz.`);
      return;
    }

    const newFiles = await Promise.all(acceptedFiles.map(async (file) => {
      if (file.size > MAX_FILE_SIZE) {
        toast.error(`${file.name} 10 MB'dan büyük. Lütfen daha küçük bir dosya seçin.`);
        return null;
      }

      // Dosya bilgilerini al
      const { contentType, isExecutable } = await getContentType(file);
      if (isExecutable) {
        toast.error(`${file.name} çalıştırılabilir veya sıkıştırılmış bir dosya. Bu tür dosyalar yüklenemez.`);
        return null;
      }

      const content = await readFileAsBase64(file);
      const isDuplicate = attachedDocuments.some(doc => doc.attachment.name === file.name);
      if (isDuplicate) {
        toast.error(`${file.name}, bu isimde bir dosya yüklenmiş.`);
        return null;
      }

      return {
        attachment: {
          id: 0,
          documentId: 0,
          name: file.name,
          contentType: contentType, // MIME tipi burada
          path: ''
        },
        content: content // Sadece base64 string
      };
    }));

    const validFiles = newFiles.filter(file => file !== null);
    const updatedDocuments = [...attachedDocuments, ...validFiles];
    setAttachedDocuments(updatedDocuments);
    onFileSelect(updatedDocuments);
  }, [attachedDocuments, onFileSelect]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const removeFile = useCallback(async (index) => {
    const newAttachedDocuments = attachedDocuments.filter((_, i) => i !== index);
    setAttachedDocuments(newAttachedDocuments);
    onFileSelect(newAttachedDocuments);
  }, [attachedDocuments, onFileSelect]);

  async function getSignedDocumentUrl(id) {
    await axios
      .get(
        `api/contract/get-attached-document-url/${getValues("documentId")}/${id}`
      )
      .then((res) => {
        const link = document.createElement('a');
        link.href = res.data;
        link.target = '_blank';
        //link.setAttribute('download', ''); // İndirme işlemi başlatacak
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
            
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const openFile = useCallback((file) => {
    let id = file.attachment.id;
    if(id > 0){
      getSignedDocumentUrl(id);
      return;
    }
    const base64Content = file.content;

    // MIME tipini attachment nesnesinden al
    const contentType = file.attachment.contentType;

    // Base64 içeriğini Blob'a çevir
    const byteCharacters = atob(base64Content);
    const byteNumbers = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const blob = new Blob([byteNumbers], { type: contentType });

    // Blob'dan URL oluştur
    const fileURL = URL.createObjectURL(blob);

    // Dosyayı yeni sekmede aç
    window.open(fileURL, '_blank');
  }, []);

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result.split(',')[1]); // Sadece base64 kısmını al
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Card sx={{ maxWidth: 600, margin: 'auto', boxShadow: '0 4px 20px rgba(90, 0, 160, 0.3)' }}>
        <CardContent>
          <div {...getRootProps()} style={{
            border: '2px dashed #cccccc',
            borderRadius: '4px',
            padding: '20px',
            textAlign: 'center',
            cursor: 'pointer',
            marginBottom: '20px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)'
          }}>
            <input {...getInputProps()} />
            <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <AttachFile style={{ marginRight: '8px' }} />
              {isDragActive ? 'Dosyaları buraya bırakın ...' : 'Dosyaları seçmek için tıklayın veya buraya sürükleyin'}
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
              Dosya boyutu en fazla 10 MB olabilir.
            </Typography>
            <Typography variant="body2" color="textSecondary">
              En fazla {MAX_FILES} dosya yükleyebilirsiniz.
            </Typography>
          </div>

          <List>
            {attachedDocuments.map((doc, index) => (
              <ListItem key={doc.attachment.name + index} secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => removeFile(index)}>
                  <DeleteIcon />
                </IconButton>
              }>
                <DocumentScanner style={{ marginRight: '8px' }} />
                <Typography
                  onClick={() => openFile(doc)}
                  style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                >
                  {doc.attachment.name}
                </Typography>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
      <ToastContainer />
    </Box>
  );
};

export default FileUpload;
