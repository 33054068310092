import { Button, Step, StepLabel, Stepper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import cities from "../Data/cities";
import RentalContractTransform from "./RentalContractTransform";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Sözleşme Şablonu",
    "Kiracı Bilgileri",
    "Kefil Bilgileri",
    "Mülk Sahibi Bilgileri",
    "Mülk Bilgileri",
    "Sözleşme Maddeleri",
  ];
}

const TenantInformations = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [city] = useState(cities);
  const [district, setDistrict] = useState([]);

  const handleAssetProvince = (e) => {
    const counties = city.filter((obj) => {
      return obj.il === e.target.value;
    });

    setDistrict(counties[0]["ilceleri"]);
  };

  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box
          align="center"
          sx={{
            display: "grid",
            rowGap: 3,
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: 3,
          }}
        >
          <FormControl fullWidth variant="outlined">
            <TextField
              label="Kiracı Adı*"
              placeholder="Kiracı Adı"
              variant="outlined"
              fullWidth
              {...register("tenantFirstName", {
                required: "error message",
              })}
            />
            {errors.tenantFirstName && (
              <p style={{ color: "red" }}>Hatalı İsim</p>
            )}
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <TextField
              label="Kiracı Soyadı*"
              placeholder="Kiracı Soyadı"
              variant="outlined"
              fullWidth
              {...register("tenantLastName", {
                required: "error message",
              })}
            />
            {errors.tenantLastName && (
              <p style={{ color: "red" }}>Hatalı Soyisim</p>
            )}
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <TextField
              label="TC/VK No*"
              placeholder="TC/VK No"
              variant="outlined"
              fullWidth
              {...register("tenantIdentifierNumber", {
                required: "error message",
                pattern: /^\d{11}$/,
              })}
            />
            {errors.tenantIdentifierNumber && (
              <p style={{ color: "red" }}>Hatalı Kimlik No</p>
            )}
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <TextField
              label="Kiracı Telefon Numarası*"
              placeholder="Kiracı Telefon Numarası"
              variant="outlined"
              fullWidth
              {...register("tenantPhoneNumber", {
                required: "error message",
                pattern: /^5(0[5-7]|[3-5]\d)\d{3}\d{4}$/,
              })}
            />
            {errors.tenantPhoneNumber && (
              <p style={{ color: "red" }}>
                Hatalı Telefon No Örnek: 5554443322
              </p>
            )}
          </FormControl>
        </Box>
      </div>

      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 3,
          }}
        >
          <Box sx={{ margin: "12px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">İl*</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="İl"
                margin="normal"
                fullWidth
                {...register("tenantCity")}
                MenuProps={MenuProps}
                onChange={(e) => {
                  handleAssetProvince(e);
                }}
              >
                {city.map((c, index) => {
                  return (
                    <MenuItem key={index} sx={{ minWidth: 180 }} value={c.il}>
                      {c.il}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ margin: "12px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">İlçe*</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="İlçe*"
                margin="normal"
                fullWidth
                {...register("tenantDistrict")}
                MenuProps={MenuProps}
              >
                {district.map((countie, index) => {
                  return (
                    <MenuItem
                      key={index}
                      sx={{ minWidth: 280 }}
                      value={countie}
                    >
                      {countie}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </div>

      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <TextField
              label="Adres*"
              placeholder="Adres bilgisi giriniz"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("tenantAddress", {
                required: "error message",
                maxLength: 250,
              })}
            />
            {errors.address && (
              <p style={{ color: "red" }}>Hatalı Adres Bilgisi</p>
            )}
          </FormControl>
        </Box>
      </div>
    </>
  );
};

const GuarantorInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  console.log(errors);
  return (
    <>
      <div style={{ width: "100%" }}>
        <Box
          align="center"
          sx={{
            display: "grid",
            rowGap: 3,
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 3,
            margin: "6px",
          }}
        >
          <FormControl fullWidth variant="outlined">
            <TextField
              label="Kefil Adı"
              placeholder="Kefil Adı"
              variant="outlined"
              fullWidth
              {...register("guarantorFirstName", {
                // required: 'error message'
              })}
            />
            {errors.guarantorFirstName && (
              <p style={{ color: "red" }}>Hatalı İsim</p>
            )}
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <TextField
              label="Kefil Soyadı"
              placeholder="Kefil Soyadı"
              variant="outlined"
              fullWidth
              {...register("guarantorLastName", {
                // required: 'error message'
              })}
            />
            {errors.guarantorLastName && (
              <p style={{ color: "red" }}>Hatalı Soyisim</p>
            )}
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <TextField
              label="Kiracı Telefon Numarası"
              placeholder="Kiracı Telefon Numarası"
              variant="outlined"
              fullWidth
              {...register("guarantorPhoneNumber", {
                // required: 'error message'
              })}
            />
            {errors.guarantorPhoneNumber && (
              <p style={{ color: "red" }}>Hatalı Telefon Numarası</p>
            )}
          </FormControl>
        </Box>
      </div>
    </>
  );
};

const OwnerInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [city] = useState(cities);
  const [district, setDistrict] = useState([]);

  const handleAssetProvince = (e) => {
    const counties = city.filter((obj) => {
      return obj.il === e.target.value;
    });

    setDistrict(counties[0]["ilceleri"]);
  };

  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box
          align="center"
          sx={{
            display: "grid",
            rowGap: 3,
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: 3,
          }}
        >
          <FormControl fullWidth variant="outlined">
            <TextField
              label="Mülk Sahibi Adı*"
              placeholder="Kiracı Adı"
              variant="outlined"
              fullWidth
              {...register("ownerFirstName", {
                required: "error message",
              })}
            />
            {errors.ownerFirstName && (
              <p style={{ color: "red" }}>Hatalı İsim</p>
            )}
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <TextField
              label="Mülk Sahibi Soyadı Soyadı*"
              placeholder="Mülk Sahibi Soyadı Soyadı"
              variant="outlined"
              fullWidth
              {...register("ownerLastName", {
                required: "error message",
              })}
            />
            {errors.ownerLastName && (
              <p style={{ color: "red" }}>Hatalı Soyisim</p>
            )}
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <TextField
              label="TC/VK No*"
              placeholder="TC/VK No"
              variant="outlined"
              fullWidth
              {...register("ownerIdentifierNumber", {
                required: "error message",
                pattern: /^\d{11}$/,
              })}
            />
            {errors.ownerIdentifierNumber && (
              <p style={{ color: "red" }}>Hatalı Kimlik No</p>
            )}
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <TextField
              label="Mülk Sahibi Telefon Numarası*"
              placeholder="Kiracı Telefon Numarası"
              variant="outlined"
              fullWidth
              {...register("ownerPhoneNumber", {
                required: "error message",
                pattern: /^5(0[5-7]|[3-5]\d)\d{3}\d{4}$/,
              })}
            />
            {errors.ownerPhoneNumber && (
              <p style={{ color: "red" }}>
                Hatalı Telefon No Örnek: 5554443322
              </p>
            )}
          </FormControl>
        </Box>
      </div>

      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 3,
          }}
        >
          <Box sx={{ margin: "12px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">İl*</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="İl"
                margin="normal"
                fullWidth
                {...register("ownerCity")}
                MenuProps={MenuProps}
                onChange={(e) => {
                  handleAssetProvince(e);
                }}
              >
                {city.map((c, index) => {
                  return (
                    <MenuItem key={index} sx={{ minWidth: 180 }} value={c.il}>
                      {c.il}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ margin: "12px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">İlçe*</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="İlçe*"
                margin="normal"
                fullWidth
                {...register("ownerDistrict")}
                MenuProps={MenuProps}
              >
                {district.map((countie, index) => {
                  return (
                    <MenuItem
                      key={index}
                      sx={{ minWidth: 280 }}
                      value={countie}
                    >
                      {countie}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </div>

      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <TextField
              label="Adres*"
              placeholder="Adres bilgisi giriniz"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("ownerAddress", {
                required: "error message",
                maxLength: 250,
              })}
            />
            {errors.address && (
              <p style={{ color: "red" }}>Hatalı Adres Bilgisi</p>
            )}
          </FormControl>
        </Box>
      </div>
    </>
  );
};

const AssetInformations = () => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [city] = useState(cities);
  const [district, setDistrict] = useState([]);

  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (e) => {
    setValue("assetType", e.target.value);
  };

  const handleAssetProvince = (e) => {
    const counties = city.filter((obj) => {
      return obj.il === e.target.value;
    });

    setDistrict(counties[0]["ilceleri"]);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 3,
          }}
        >
          <Box sx={{ margin: "12px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">
                Varlık Tipi*
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="Varlık Tipi"
                margin="normal"
                fullWidth
                {...register("assetType")}
                MenuProps={MenuProps}
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                <MenuItem value={"Konut"}>Konut</MenuItem>
                <MenuItem value={"İş yeri"}>İş yeri</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ margin: "12px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">Tapu İli*</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="Tapu İli"
                margin="normal"
                fullWidth
                {...register("assetProvince")}
                MenuProps={MenuProps}
                onChange={(e) => {
                  handleAssetProvince(e);
                }}
              >
                {city.map((c, index) => {
                  return (
                    <MenuItem key={index} sx={{ minWidth: 180 }} value={c.il}>
                      {c.il}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ margin: "12px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">
                Tapu İlçesi*
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="Tapu İlçesi"
                margin="normal"
                fullWidth
                {...register("assetDistrict")}
                MenuProps={MenuProps}
              >
                {district.map((countie, index) => {
                  return (
                    <MenuItem
                      key={index}
                      sx={{ minWidth: 280 }}
                      value={countie}
                    >
                      {countie}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </div>

      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <TextField
              label="Tapu Mahallesi*"
              placeholder="Tapu Mahallesi"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("assetNeighbourhood", {
                required: "error message",
                maxLength: 150,
              })}
            />
            {errors.assetNeighbourhood && (
              <p style={{ color: "red" }}>Hatalı Tapu Mahallesi Bilgisi</p>
            )}
          </FormControl>
          <FormControl>
            <TextField
              label="Tapu Cadde/Sokak*"
              placeholder="Tapu Cadde/Sokak"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("assetStreet", {
                required: "error message",
                maxLength: 150,
              })}
            />
            {errors.assetStreet && (
              <p style={{ color: "red" }}>Hatalı Tapu Cadde/Sokak Bilgisi</p>
            )}
          </FormControl>
          <FormControl>
            <TextField
              label="Bina No - İç Kapı No Giriniz*"
              placeholder="Bina No - İç Kapı No"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("assetBuildingNo", {
                required: "error message",
                maxLength: 150,
              })}
            />
            {errors.assetBuildingNo && (
              <p style={{ color: "red" }}>
                Hatalı Bina No - İç Kapı No Bilgisi
              </p>
            )}
          </FormControl>
        </Box>
      </div>

      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <TextField
              label="Oda Sayısı*"
              placeholder="Oda Sayısı"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("numberOfRoom", {
                required: "error message",
                maxLength: 150,
              })}
            />
            {errors.assetNeighbourhood && (
              <p style={{ color: "red" }}>Hatalı Tapu Mahallesi Bilgisi</p>
            )}
          </FormControl>
          <FormControl>
            <TextField
              label="Metre Kare*"
              placeholder="Metre Kare"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("assetSquareMeters", {
                required: "error message",
                maxLength: 150,
              })}
            />
            {errors.assetStreet && (
              <p style={{ color: "red" }}>Hatalı Tapu Cadde/Sokak Bilgisi</p>
            )}
          </FormControl>
        </Box>
      </div>
    </>
  );
};

const ContractClauses = () => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [typeValue, setTypeValue] = useState("");

  const [period, setPeriod] = useState("Aylık");

  const menuItems = [];

  for (let i = 1; i <= 31; i++) {
    menuItems.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }

  const handleChange = (e) => {
    setTypeValue(e.target.value);
    setValue("rentalPaymentMethod", e.target.value);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <TextField
              label="Kira Başlangıç Tarihi*"
              placeholder="Kira Başlangıç Tarihi"
              type="date"
              variant="outlined"
              margin="normal"
              InputLabelProps={{ shrink: true }}
              fullWidth
              {...register("rentalStartDate", {
                required: "error message",
                maxLength: 150,
              })}
            />
            {errors.rentalStartDate && (
              <p style={{ color: "red" }}>Hatalı Kira Başlangıç Bilgisi</p>
            )}
          </FormControl>
          <Box sx={{ margin: "15px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">
                Kiralama Periyodu*
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="Kiralama Periyodu"
                margin="normal"
                fullWidth
                {...register("rentalPeriod")}
                MenuProps={MenuProps}
                onChange={(e) => {
                  setPeriod(e.target.value);
                }}
              >
                <MenuItem value={"Aylık"}>Aylık</MenuItem>
                <MenuItem value={"Yıllık"}>Yıllık</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {period === "Aylık" ? (
            <Box sx={{ margin: "15px 0px 0px 0px" }}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="demo-simple-select-label">
                  Kira Süresi (Aylık)
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper-label"
                  label="Kira Süresi (Yıllık)"
                  margin="normal"
                  fullWidth
                  {...register("rentalPeriodMonth", {
                    required: "error message",
                  })}
                  MenuProps={MenuProps}
                >
                  {menuItems}
                </Select>
                {errors.rentalPeriodMonth && (
                  <p style={{ color: "red" }}>Lütfen Periyot Tarihi Giriniz</p>
                )}
              </FormControl>
            </Box>
          ) : (
            <>
              <Box sx={{ margin: "15px 0px 0px 0px" }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-label">
                    Kira Süresi (Yıllık)
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper-label"
                    label="Kira Süresi (Yıllık)"
                    margin="normal"
                    fullWidth
                    {...register("rentalPeriodYear", {
                      required: "error message",
                    })}
                    MenuProps={MenuProps}
                  >
                    {menuItems}
                  </Select>
                  {errors.paymentDay && (
                    <p style={{ color: "red" }}>
                      Lütfen Ödeme Günü Bilgisi Giriniz
                    </p>
                  )}
                </FormControl>
              </Box>
            </>
          )}
        </Box>
      </div>

      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <TextField
              label="Depozito*"
              type="number"
              placeholder="Depozito"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("deposit", {
                required: "error message",
                maxLength: 150,
              })}
            />
            {errors.deposit && (
              <p style={{ color: "red" }}>Hatalı Depozito Bilgisi</p>
            )}
          </FormControl>
          <FormControl>
            <TextField
              label="Aylık Kira Bedeli*"
              placeholder="Aylık Kira Bedeli"
              type="number"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("monthlyRentalFee", {
                required: "error message",
              })}
            />
            {errors.monthlyRentalFee && (
              <p style={{ color: "red" }}>Hatalı Aylık Kira Bedeli Bilgisi</p>
            )}
          </FormControl>
          <FormControl>
            <TextField
              label="Toplam Kira Bedeli*"
              placeholder="Toplam Kira Bedeli"
              type="number"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("yearlyRentalPay", {
                required: "error message",
              })}
            />
            {errors.yearlyRentalPay && (
              <p style={{ color: "red" }}>Hatalı Toplam Kira Bedeli Bilgisi</p>
            )}
          </FormControl>
        </Box>
      </div>

      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <TextField
              label="Kira Artış Oranı*"
              type="number"
              placeholder="%"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("rentalRaiseRate", {
                required: "error message",
                maxLength: 150,
              })}
            />
            {errors.rentalRaiseRate && (
              <p style={{ color: "red" }}>Hatalı Kira Artış Oranı Bilgisi</p>
            )}
          </FormControl>
          <Box sx={{ margin: "15px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">
                Kira Ödeme Günü*
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="Kira Ödeme Günü*"
                margin="normal"
                fullWidth
                {...register("timeToPayRent", {
                  required: "error message",
                })}
                MenuProps={MenuProps}
              >
                {menuItems}
              </Select>
              {errors.timeToPayRent && (
                <p style={{ color: "red" }}>
                  Lütfen Ödeme Günü Bilgisi Giriniz
                </p>
              )}
            </FormControl>
          </Box>
          <FormControl>
            <TextField
              label="Demirbaş*"
              placeholder="Demirbaş"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("fixture", {
                required: "error message",
              })}
            />
            {errors.fixture && (
              <p style={{ color: "red" }}>Hatalı Demirbaş Bilgisi</p>
            )}
          </FormControl>
        </Box>
      </div>

      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <TextField
              label="Kullanım Amacı*"
              placeholder="Kullanım Amacı"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("purposeOfUsage", {
                required: "error message",
              })}
            />
            {errors.purposeOfUsage && (
              <p style={{ color: "red" }}>Hatalı Kullanım Amacı Bilgisi</p>
            )}
          </FormControl>
          <Box sx={{ margin: "15px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">
                Kira Ödeme Yöntemi*
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="Kira Ödeme Yöntemi*"
                margin="normal"
                fullWidth
                onChange={handleChange}
                // {...register("rentalPaymentMethod", {
                //      required: 'error message'
                // })}
                MenuProps={MenuProps}
              >
                <MenuItem value={"Elden"}>Elden Ödeme</MenuItem>
                <MenuItem value={"Havale"}>Havale ile Ödeme</MenuItem>
              </Select>
              {errors.rentalPaymentMethod && (
                <p style={{ color: "red" }}>
                  Lütfen Kira Ödeme Yöntemi Bilgisi Giriniz
                </p>
              )}
            </FormControl>
          </Box>
          <Box sx={{ margin: "15px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">
                Kullanım Durumu*
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="Kullanım Durumu* "
                margin="normal"
                fullWidth
                {...register("stateOfAsset", {
                  required: "error message",
                })}
                MenuProps={MenuProps}
              >
                <MenuItem value={"Boş"}>Boş</MenuItem>
                <MenuItem value={"Kiracılı"}>Kiracılı</MenuItem>
              </Select>
              {errors.stateOfAsset && (
                <p style={{ color: "red" }}>
                  Lütfen Kullanım Durumu Bilgisi Giriniz
                </p>
              )}
            </FormControl>
          </Box>
        </Box>
      </div>

      {typeValue === "Havale" ? (
        <div style={{ width: "100%" }}>
          <Box
            sx={{
              display: "grid",
              rowGap: 5,
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 3,
            }}
          >
            <FormControl>
              <TextField
                label="Banka Adı*"
                placeholder="Banka Adı"
                variant="outlined"
                margin="normal"
                fullWidth
                {...register("bankName", {
                  required: "error message",
                })}
              />
              {errors.bankName && (
                <p style={{ color: "red" }}>Hatalı Banka Adı Bilgisi</p>
              )}
            </FormControl>
            <FormControl>
              <TextField
                label="Banka Şube Adı*"
                placeholder="Banka Şube Adı"
                variant="outlined"
                margin="normal"
                fullWidth
                {...register("bankBranchName", {
                  required: "error message",
                })}
              />
              {errors.bankBranchName && (
                <p style={{ color: "red" }}>Hatalı Banka Şube Adı Bilgisi</p>
              )}
            </FormControl>
            <FormControl>
              <TextField
                label="IBAN Numarası*"
                placeholder="IBAN Numarası"
                variant="outlined"
                margin="normal"
                fullWidth
                {...register("ownerIBAN", {
                  required: "error message",
                })}
              />
              {errors.ownerIBAN && (
                <p style={{ color: "red" }}>Hatalı IBAN Numarası Bilgisi</p>
              )}
            </FormControl>
          </Box>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const ContractDraft = () => {
  const [userTemplateList, setUserTemplateList] = useState();

  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  let userId = getValues("userId");
  let contractTypeId = getValues("contractTypeId");

  async function informationForDraft() {
    await axios
      .get(`api/contract-template/get/${userId}/${contractTypeId}`)
      .then((res) => {
        setUserTemplateList(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    informationForDraft();
  }, []);

  return (
    <>
      <Box
        component="form"
        sx={{ width: "50%", margin: "auto" }}
        noValidate
        align="center"
        autoComplete="off"
      >
        <FormControl fullWidth variant="outlined">
          <InputLabel id="test-select-label">Kontrat Şablonu</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper-label"
            label="Kontrat Şablonu"
            {...register("contractName", {
              required: "error message",
            })}
            fullWidth
            MenuProps={MenuProps}
          >
            {userTemplateList ? (
              userTemplateList.map((template, index) => {
                return (
                  <MenuItem
                    key={index}
                    sx={{ minWidth: 200 }}
                    value={template.contractName}
                  >
                    {template.contractName}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem></MenuItem>
            )}
          </Select>
        </FormControl>
        {errors.contractName && (
          <p style={{ color: "red" }}>Lütfen Sözleşme Şablonu Seçiniz</p>
        )}
      </Box>
    </>
  );
};

function getStepContent(step) {
  switch (step) {
    case 0:
      return <ContractDraft />;
    case 1:
      return <TenantInformations />;
    case 2:
      return <GuarantorInformation />;
    case 3:
      return <OwnerInformation />;
    case 4:
      return <AssetInformations />;
    case 5:
      return <ContractClauses />;
    default:
      return "unknown step";
  }
}

const RentalContractLinearStepper = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.security.user);
  const userId = user.id;

  let contractDateNow = new Date().toLocaleDateString();

  const methods = useForm({
    defaultValues: {
      tenantFirstName: "",
      tenantLastName: "",
      tenantIdentifierNumber: "",
      tenantCity: "",
      tenantDistrict: "",
      tenantAddress: "",
      tenantPhoneNumber: "",
      guarantorFirstName: "",
      guarantorLastName: "",
      guarantorPhoneNumber: "",
      ownerFirstName: "",
      ownerLastName: "",
      ownerIdentifierNumber: "",
      ownerCity: "",
      ownerDistrict: "",
      ownerAddress: "",
      ownerPhoneNumber: "",
      ownerIBAN: "",
      mernisId: "",
      assetType: "",
      assetProvince: "",
      assetDistrict: "",
      assetNeighbourhood: "",
      assetStreet: "",
      assetBuildingNo: "",
      numberOfRoom: 0,
      assetSquareMeters: 0,
      processType: "",
      rentalStartDate: "",
      rentalPeriod: "",
      rentalPeriodYear: "",
      rentalPeriodMonth: "",
      deposit: "",
      monthlyRentalFee: 0,
      rentalRaiseRate: 0,
      timeToPayRent: "",
      fixture: "",
      purposeOfUsage: "",
      rentalPaymentMethod: "",
      stateOfAsset: "",
      yearlyRentalPay: "",
      bankName: "",
      bankBranchName: "",
      evacuationNotice: "",

      contractDateNow: contractDateNow,

      contractName: "",
      contractTypeId: 2,
      userId: userId,
    },
  });
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();

  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = (data) => {
    if (activeStep === steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          // if (isStepOptional(index)) {
          //     labelProps.optional = (
          //         <Typography
          //             variant="caption"
          //             align="center"
          //             style={{ display: "block" }}
          //         >
          //             Opsiyonel
          //         </Typography>
          //     );
          // }
          if (isStepFalied() && activeStep === index) {
            labelProps.error = true;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        // <Typography variant="h3" align="center">
        //     Thank You
        // </Typography>
        <RentalContractTransform
          rentalContractInformations={methods.getValues()}
        />
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleNext)}>
              {getStepContent(activeStep)}
              <Box
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "75ch" },
                }}
                noValidate
                align="right"
                autoComplete="off"
              >
                <Button
                  className={classes.button}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  variant="outlined"
                  size="large"
                  color="primary"
                >
                  geri
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  size="large"
                  color="primary"
                  // onClick={handleNext}
                  type="submit"
                >
                  {activeStep === steps.length - 1 ? "Tamamla" : "İleri"}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};

export default RentalContractLinearStepper;
