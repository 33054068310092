const Wdata = [
  {
    id: 1,
    cover: "/images/works/work-1.png",
    title: "Sözleşme Oluşturma",
    desc: "Sözleşme üzerinde bulunan taraf bilgileri kullanıcı tarafından doldurulur.",
  },
  {
    id: 1,
    cover: "/images/works/work-3.png",
    title: "Sözleşme Gönderimi",
    desc: "Sözleşme formu doldurulduktan sonra sözleşme bilgileri kontrol edilir ve sözleşme karşı tarafa gönderilir.",
  },
  {
    id: 1,
    cover: "/images/works/work-2.png",
    title: "Sözleşme Kontrolü",
    desc: "Oluşturulmuş olan sözleşme için sözleşme-sorgulama ekranından sözleşme kontrol edilir.",
  },
]
export default Wdata
