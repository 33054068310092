import html2pdf from 'html2pdf.js';
// pdfConverter.ts

/**
 * Default PDF conversion configuration
 */
const PDF_CONFIG = {
    margin: 10,
    image: { type: 'jpeg', quality: 0.98 },
    enableLinks: true,
    pagebreak: { mode: 'css' },
    html2canvas: {
      scale: 2,
      useCORS: true,
      logging: false
    },
    jsPDF: {
      unit: 'mm',
      format: 'a4',
      orientation: 'portrait'
    }
  } as const;
  
  /**
   * Creates a temporary DOM element from HTML string
   * @param htmlContent - HTML string
   * @returns HTMLElement
   */
  const createTempElement = (htmlContent: string): HTMLElement => {
    const container = document.createElement('div');
    container.innerHTML = htmlContent;
    // Geçici elementi body'e ekle (html2pdf için gerekli)
    document.body.appendChild(container);
    return container;
  };
  
  /**
   * Converts HTML content to PDF Blob
   * @param htmlContent - HTML content to convert
   * @returns Promise<Blob> - Returns PDF as Blob
   */
  export const convertHtmlToPdf = async (htmlContent: string): Promise<Blob> => {
    try {
      // HTML string'den geçici element oluştur
      const element = createTempElement(htmlContent);
  
      const pdf = await html2pdf()
        .set(PDF_CONFIG)
        .from(element)
        .toPdf()
        .output('blob');
      
      // Geçici elementi temizle
      document.body.removeChild(element);
        
      return pdf;
    } catch (error) {
      console.error('PDF conversion failed:', error);
      throw new Error('PDF conversion failed');
    }
  };
  
  /**
   * Converts Blob to base64 string
   * @param blob - Blob to convert
   * @returns Promise<string> - Returns base64 string
   */
  export const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          const base64 = reader.result.split(',')[1];
          resolve(base64);
        } else {
          reject(new Error('Failed to convert blob to base64'));
        }
      };
      reader.onerror = () => reject(new Error('Failed to read blob'));
      reader.readAsDataURL(blob);
    });
  };