import AccountTree from "@mui/icons-material/AccountTree";
import ArchiveIcon from "@mui/icons-material/Archive";
import Autorenew from "@mui/icons-material/Autorenew";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import IconButton from "@mui/material/IconButton";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid, trTR } from "@mui/x-data-grid";
import axios from "axios";
import { FileSignature } from 'lucide-react';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { DocumentCreationType, DocumentTypes } from "../../actions/types";
import { convertHtmlToPdf } from "../../util/pdf/PdfConverter";
import "../Layout/datatable.scss";
import { userColumns } from "../Layout/datatablesource";
import PageHeader from "../Layout/PageHeader";
import RelatedDocuments from "./RelatedDocuments";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      Sözleşme Kaydı Bulunmamaktadır
    </Stack>
  );
}

const ContractOutgoing = () => {
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.security.user);
  const userId = user.id;
  const [url, setUrl] = useState(null);
  const [show, setShow] = useState(false);
  const [archiveShow, setArchiveShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [relatedDocument, setRelatedDocument] = useState(null);
  const [relatedDocuments, setRelatedDocuments] = useState(null);
  const [{open,text}, setOpen] = useState(false);
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  async function contractData(userId) {
    let request = {
      userId: userId,
      documentType: DocumentTypes.Contract,
      status: null,
    };
    await axios
      .post(`api/contract/get-outgoing-contracts`, request)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    contractData(userId);
  }, [userId]);

  async function handleDelete() {
    await axios
      .post(`api/contract/delete-contract/${selectedRow.contractId}`)
      .then((res) => {
        contractData(userId);
        setDeleteShow(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleArchive() {
    await axios
      .post(`api/contract/move-contract-archive/${selectedRow.contractId}`)
      .then((res) => {
        contractData(userId);
        setArchiveShow(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function analyzeData() {

    await axios.post("api/ai/risk-analyze", selectedRow)
    .then((res) => {
      const values = [...res.data.risk_analysis];
      const lines = values.filter(item => item.trim() !== "").map(item => item.trim());
      const formattedLines = lines.map(line => {
        if (line.includes(":")) {
          return line.trim() + "\n";
         }    
         else{
          return line.trim();
         }
      });
      const formattedText = formattedLines.join("\n");
      setOpen({ open: true, text: formattedText });
    })
    .catch((error) => {
      console.log(error);
    }); 
 }
  const handleClose = () => {
    setOpen({ open: false, text: null });
  };
  const create = (docDefinition) => {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      setUrl(url);
    });
  };

  async function showModal(contractId, show) {
    if (show) {
      setShow(false);
      return;
    }

    try {
      const response = await axios.get(`api/contract/get-document-file/${contractId}`);

      if (response.data.isSigned) {
        setUrl(response.data.preSignedUrl);
        setShow(true);
        return;
      }

      const content = response.data.htmlContent;

      // Sadece PDF Blob'ı al
      const pdfBlob = await convertHtmlToPdf(content);

      // Blob'dan URL oluştur - bu kısım görüntüleme mantığına ait
      const pdfUrl = URL.createObjectURL(pdfBlob);

      setUrl(pdfUrl);
      setShow(true);

    } catch (error) {
      console.error('Error in contract modal:', error);
    }
  }


  async function handleCellClick(e) {
    setSelectedRow(e.row);
  }

  async function getRelatedDocuments(row) {
    setRelatedDocuments(null);
    setRelatedDocument(row);
    await axios
      .get(
        `api/contract/get-all-document-by-related-document-id/${row.contractId}`
      )
      .then((res) => {
        if (res.data.length > 0) {
          setRelatedDocuments(res.data);
        } else {
          toast.info("İlişkili Belge Bulunamadı", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 450,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Modal show={show} onHide={() => setShow(false)}>
              <div onClick={() => setShow(false)}></div>
              <div style={{ position: "fixed" }}>
                {url && (
                  <object
                    style={{
                      width: "200%",
                      height: "75vh",
                    }}
                    data={url}
                    type="application/pdf"
                  >
                    <embed
                      frameborder="0"
                      width="300%"
                      height="500px"
                      src={url}
                      type="application/pdf"
                    />
                  </object>
                )}
              </div>
            </Modal>
            <Modal show={archiveShow} onHide={() => setArchiveShow(false)}>
              <Modal.Body>Sözleşme Arşivlensin mi?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setArchiveShow(false)}
                >
                  Hayır
                </Button>
                <Button variant="primary" onClick={(e) => handleArchive()}>
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={deleteShow} onHide={() => setDeleteShow(false)}>
              <Modal.Body>Sözleşme Silinsin mi?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setDeleteShow(false)}
                >
                  Hayır
                </Button>
                <Button variant="primary" onClick={(e) => handleDelete()}>
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Risk Analizi
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={(theme) => ({
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                  })}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                {text}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Kaydet
                </Button>
                <Button autoFocus onClick={handleClose}>
                  Vazgeç
                </Button>
              </DialogActions>
            </BootstrapDialog>
            {params.row.isSigned ? (
              <Tooltip title="İmzalı">
                <FileSignature color="#673AB7" size={48} />
              </Tooltip>
            ) : null}
            <Tooltip title="Görüntüle" onHide={() => setShow(false)}>
              <IconButton>
                <SlideshowIcon
                  color="success"
                  onClick={() =>
                    showModal(
                      params.row.contractId,
                      show
                    )
                  }
                  onHide={() => setShow(false)}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="İlişkili Belge Oluştur">
              <IconButton>
                <Link
                  to={{
                    pathname: "/entryBasicContract",
                    state: {
                      documentCreationType:
                        DocumentCreationType.RelatedDocument,
                      documentType: params.row.documentType,
                      id: params.row.contractId,
                    },
                  }}
                  style={{ color: "gray" }}
                >
                  <NoteAddIcon
                    style={{ color: "purple", variant: "fullwidth" }}
                  />
                </Link>
              </IconButton>
            </Tooltip>
            <Tooltip title="Doküman Yenile">
              <IconButton>
                <Link
                  to={{
                    pathname: "/entryBasicContract",
                    state: {
                      documentCreationType: DocumentCreationType.Renewal,
                      id: params.row.contractId,
                    },
                  }}
                  style={{ color: "gray" }}
                >
                  <Autorenew
                    style={{ color: "orange", variant: "fullwidth" }}
                  />
                </Link>
              </IconButton>
            </Tooltip>
            <Tooltip title="İlişkili Belge Listele">
              <IconButton
                onClick={() => {
                  getRelatedDocuments(params.row);
                }}
              >
                <AccountTree
                  style={{ color: "darkblue", variant: "fullwidth" }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Arşivle">
              <IconButton onClick={() => setArchiveShow(true)}>
                <ArchiveIcon color="info" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sil">
              <IconButton onClick={() => setDeleteShow(true)}>
                <DeleteIcon color="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Risk Analizi">
              <IconButton onClick={() => analyzeData()}>
                <AnalyticsIcon color="info" />
              </IconButton>
            </Tooltip>

          </div>
        );
      },
    },
  ];
  return (
    <>
      {relatedDocuments != null ? (
        <RelatedDocuments
          document={relatedDocument}
          relatedDocuments={relatedDocuments}
        />
      ) : null}
      <ToastContainer />
      <PageHeader
        title="Gönderilen Sözleşmeler"
        subTitle="Gönderilen Sözleşmeleri görüntüleyebilirsiniz."
        icon={<ArchiveIcon fontSize="large" color="action" />}
      />

      <div className="home">
        <div className="homeContainer">
          <div className="listContainer">
            <div className="datatable">
              <div className="datatableTitle">Sözleşmeler</div>
              <DataGrid
                className="datagrid"
                rows={data}
                getRowId={(row) => row.contractId}
                columns={userColumns.concat(actionColumn)}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                components={{ NoRowsOverlay }}
                pageSize={8}
                onCellClick={handleCellClick}
                rowsPerPageOptions={[10]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractOutgoing;
