import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import EInvoiceContractLinearStepper from "./EInvoiceContractLinearStepper";

const EntryEInvoiceIntegratorContract = () => {
  const user = useSelector((state) => state.security.user);
  const userId = user.id;
  let [privateIntegratorUser, setPrivateIntegratorUser] = useState();

  useEffect(() => {
    axios
      .get(`api/users/get-private-integrator/${userId}`)
      .then((res) => {
        setPrivateIntegratorUser(res.data);
        console.log(privateIntegratorUser);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {
        /*privateIntegratorUser ? */ <section className="single-page top">
          <div className="container">
            <EInvoiceContractLinearStepper />
          </div>
        </section> /*: (
                <PrivateIntegratorRegister />
            )*/
      }
    </>
  );
};

export default EntryEInvoiceIntegratorContract;
