import React from "react";
import "./About.css";
import HeadTitle from "../Layout/HeadTitle";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const About = () => {
  const token = useSelector((state) => state.security.validToken);

  return (
    <>
      {token ? (
        <Redirect to="/dashboard" />
      ) : (
        <>
          <HeadTitle title="Hakkımızda" />

          <section className="about top">
            <div className="container">
              <div className="aboutCard mtop flex_space">
                <div className="row row1">
                  <h4>Diji Söz Hakkında</h4>
                  <h1>
                    Yeni Nesil <span>Dijital Sözleşme </span> oluşturma
                    platformu
                  </h1>
                  <p>
                    Uluslararası standartlarda dijitalleşme sürecine katkı
                    sağlamak ve yapay zeka teknikleri ile karar verme
                    sistemlerini desteklemek önde gelen hedeflerimiz
                    arasındadır.
                  </p>
                  <p>
                    Firmaların fiziki ortamda yürüttüğü süreçlerini uluslararası
                    standartlarla ve ileri teknolojilerle dijital ortama
                    taşıyarak güvenirliği ve süreç otomasyonunu
                    sağlamak,toplanan verileri yapay zeka/istatistiksel
                    modelleme teknikleri ile analiz ederek gerçek ve tüzel
                    kişilere yönelik güven endeksleri oluşturmak hedeflerimiz
                    arasında yer almaktadır.
                  </p>
                  {/* <button className='secondary-btn'>
                        Hakkımızda <i className='fas fa-long-arrow-alt-right'></i>
                      </button> */}
                </div>
                <div>
                  <img src="/images/about-img-1.jpg" alt="" />
                </div>
              </div>
            </div>
          </section>

          <section className="features top">
            <div className="container aboutCard flex_space">
              <div className="row row1">
                <h1>
                  Neden <span>Diji-Söz</span>
                </h1>
                <p>
                  Sözleşmelerinizi elektronik ortamda oluşturup, onaylamanıza,
                  raporlamanıza imkan veren aynı zamanda sözleşme maddelerini
                  yaşayan kurallara dönüştürerek süreç otomasyonu sağlayan ve
                  sözleşme bilgilerinden e-fatura üretilmesine imkan sağlayan
                  uygulamadır.
                </p>
                <p>
                  Fatura Modülü ile Gelir İdaresi Başkanlığı fatura
                  gereksinimlerine uygun e-fatura/e-arşiv üretimine imkan veren
                  uygulamadır. Kayıtlı kullanıcılar listesinde yer alsın veya
                  almasın tüm firmaların fatura düzenlenmesine imkan sunmak
                  amaçlanmıştır. Sözleşme modülü ile entegre çalışabildiği gibi
                  bağımsız da çalışabilmektedir.
                </p>
                {/* <button className='secondary-btn'>
                      Explore More <i className='fas fa-long-arrow-alt-right'></i>
                    </button> */}
              </div>
              <div className="row image">
                <img src="/images/feature-img-1.jpg" alt="" />
                <div className="control-btn">
                  <button className="prev">
                    <i className="fas fa-play"></i>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default About;
