import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEstateAgentUser } from "../../actions/securityActions";
import RentalContractLinearStepper from "./RentalContractLinearStepper";

const EntryRentalContracts = () => {
  const user = useSelector((state) => state.security.user);
  const userId = user.id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEstateAgentUser(userId));
  }, []);

  return (
    <>
      <section className="single-page top">
        <div className="container">
          <RentalContractLinearStepper />
        </div>
      </section>
      {/* {estateAgentUser ? (

            ): (
                    <EstateAgentRegister />
            )} */}
    </>
  );
};

export default EntryRentalContracts;
