const Sdata = [
  {
    id: 1,
    title: "Dijital Sözleşme",
    image: "/images/singlepage/Kira-Sozlesmesi.jpg",
    desc: "Sözleşmeleri elektronik ortamda oluşturup, onaylamanıza, raporlamanıza imkan veren aynı zamanda sözleşme maddelerini yaşayan kurallara dönüştürerek süreç otomasyonu sağlayan ve sözleşme bilgilerinden e-fatura üretilmesine imkan sağlayan uygulamadır.",
    sidepara: "Sözleşmeleri elektronik ortamda oluşturup, onaylamanıza, raporlamanıza imkan veren aynı zamanda sözleşme maddelerini yaşayan kurallara dönüştürerek süreç otomasyonu sağlayan ve sözleşme bilgilerinden e-fatura üretilmesine imkan sağlayan uygulamadır.",
    paraImage_one: "/images/singlepage/single-1.jpg",
    paraImage_two: "/images/singlepage/single-2.jpg",
  },
  {
    id: 2,
    title: "Dijital Fatura",
    image: "/images/singlepage/e-fatura-hizmeti-isnet.jpg",
    desc: "Gelir İdaresi Başkanlığı fatura gereksinimlerine uygun e-fatura/e-arşiv üretimine imkan veren uygulamadır. Kayıtlı kullanıcılar listesinde yer alsın veya almasın tüm firmaların fatura düzenlenmesine imkan sunmak amaçlanmıştır. Sözleşme modülü ile entegre çalışabildiği gibi bağımsız da çalışabilmektedir.",
    sidepara: "Gelir İdaresi Başkanlığı fatura gereksinimlerine uygun e-fatura/e-arşiv üretimine imkan veren uygulamadır. Kayıtlı kullanıcılar listesinde yer alsın veya almasın tüm firmaların fatura düzenlenmesine imkan sunmak amaçlanmıştır. Sözleşme modülü ile entegre çalışabildiği gibi bağımsız da çalışabilmektedir.",
  },
  {
    id: 3,
    title: "Söz-Box",
    image: "/images/dest-3.jpg",
    desc: "Dijisöz üzerinden adınıza düzenlenen tüm sözleşmeleri üyelik kaydı gerekmeksizin belirli güvenlik önlemleriyle görüntülenmesine imkan sunmaktadır.",
    sidepara: "Dijisöz üzerinden adınıza düzenlenen tüm sözleşmeleri üyelik kaydı gerekmeksizin belirli güvenlik önlemleriyle görüntülenmesine imkan sunmaktadır.",
  }
]

export default Sdata
