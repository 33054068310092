const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
const phoneValidationRegex = /^(?!0)\d{10}$/;

export function isValidTaxIdentityNumber(identityNumber: string) {
    if (!/^\d{10}$/.test(identityNumber)) {
        return false;
      }
    
      const digits = identityNumber.split('').map(Number);  // VKN'yi bir diziye çevir

      // İlk 9 rakama göre kontrol numarası hesapla
      const sum1 = digits.slice(0, 9).reduce((acc, digit, index) => {
        if (index % 2 === 0) {
          return acc + digit * 1;  // Çift index (0, 2, 4...) için çarpan 1
        } else {
          return acc + digit * 2;  // Tek index (1, 3, 5...) için çarpan 2
        }
      }, 0);
    
      const sum2 = digits.slice(0, 9).reduce((acc, digit, index) => {
        if (index % 2 === 1) {
          return acc + digit * 1;  // Çift index (1, 3, 5...) için çarpan 1
        } else {
          return acc + digit * 2;  // Tek index (0, 2, 4...) için çarpan 2
        }
      }, 0);
    
      const check1 = (sum1 % 10);
      const check2 = (sum2 % 10);
    
      // VKN'nin son 2 hanesinin hesaplanan değerlerle eşleşip eşleşmediğini kontrol et
      return digits[8] === check1 && digits[9] === check2;    
}

// tcknValidator.ts

export function isValidIdentityNumber(identityNumber: string) {
    // TCKN'nin null, 11 haneli ve sadece rakamlardan oluşup oluşmadığını kontrol et
    if (!identityNumber || identityNumber.length !== 11 || !/^\d+$/.test(identityNumber)) {
      return false;
    }
  
    let total = 0;
    for (let i = 0; i < 10; i++) {
      total += parseInt(identityNumber.charAt(i));
    }
  
    const digit10 = total % 10;
    total = 0;
  
    for (let i = 0; i < 9; i += 2) {
      total += parseInt(identityNumber.charAt(i));
    }
    total *= 7;
  
    for (let i = 1; i < 9; i += 2) {
      total -= parseInt(identityNumber.charAt(i));
    }
  
    total = total % 10;
    const digit11 = parseInt(identityNumber.charAt(10));
  
    return digit10 === digit11 && digit11 >= 1;
  }

  export const isValidMobilePhoneNumber = (value: string) => {
    // Telefon numarası 10 haneli olmalı, 0 ile başlamamalı, 5 ile başlamalı
    const phoneRegex = /^5\d{9}$/; // 5 ile başlayıp 9 rakam içermeli
    return phoneRegex.test(value);
  };

export const isValidPassword = (value: string) => {
  if(!value || value === null || value === '')
    return true;

  return passwordRegex.test(value);
}

export const isValidPhoneNumber = (value: string) => {
  if(!value || value === null || value === '')
    return true;

  return phoneValidationRegex.test(value);
}
  