import {
  Button
} from '@mui/material';
import { React, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DocumentStatus } from "../actions/types";
import "./home.scss";
import ActionButtons from "./Layout/ActionButtons";
import Datatable from "./Layout/DataTable";
import "./Layout/list.scss";
import "./Layout/Sidebar.css";


const Dashboard = () => {
  const history = useHistory();
  const user = useSelector((state) => state.security.user);
  const contracts = useSelector((state) => state.contract.contracts);
  const numberOfContract = contracts.data?.length || 0;
  const [showIdentityMessage, setShowIdentityMessage] = useState(false);
  const counts = {
    incoming: contracts.length === 0? 0 : contracts.data.filter(doc => doc.direction === "incoming").length,
    draft: contracts.length === 0? 0 : contracts.data.filter(doc => doc.direction === "outgoing" && doc.status === DocumentStatus.Draft).length,
    approve: contracts.length === 0? 0 : contracts.data.filter(doc => doc.direction === "outgoing" && doc.status !== DocumentStatus.Draft).length,
  }
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleCreateClick = (e) => {
    let isValid = (user.identityNumber != null && user.identityNumber != "");
    isValid = isValid || (user.company != null && user.company.identityNumber != null && user.company.identityNumber != "");  // Kontrol fonksiyonunuzu buraya yazın
    console.log("Valid Kontrol çalıştır:")
    console.log(isValid)
    if (!isValid) {

      e.preventDefault();
      setShowIdentityMessage(true)
    }
  };

  return (
    <div>
      <Modal show={showIdentityMessage} onHide={() => setShowIdentityMessage(false)}>
          <Modal.Body>Belge oluşturma ve diğer işlemler için TCKN bilginizi eğer bir şirket adına işlem yapacaksanız
            şirket bilgilerinizi Profil Menusunden girebilirsiniz.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowIdentityMessage(false)}
            >
              Tamam
            </Button>

          </Modal.Footer>
        </Modal>
       <ActionButtons onSearch={handleSearch} counts={counts} handleCreateDocument={handleCreateClick}/>
    <div className="home">
      {/* <SideBar /> */}
      <div className="homeContainer">
      
        {/* <table>
          <tr>
            <td>
              <img src="../images/aylaragoresozlesme.png" alt="Home" />
            </td>
            <td>
              <img src="../images/sozlesmestatu.png" alt="Home" />
            </td>
          </tr>
          <tr>
            <td>
              <img src="../images/sozlesmetutar.png" alt="Home" />
            </td>
            <td>
              <img src="../images/personel_contract.jpg" alt="Home" />
            </td>
          </tr>
        </table> */}
        <div className="widgets">
          {/* <Widget type="user" amount={numberOfContract} /> */}
          {/* <Widget type="balance" amount="1500" /> */}
        </div>
        <div className="listContainer">
          <Datatable searchTerm={searchTerm}/>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Dashboard;
