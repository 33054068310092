import React, { useState } from "react";
import axios from "axios";
import PageHeader from "../Layout/PageHeader";
import { userColumns } from "../Layout/datatablesource";
import "../Layout/datatable.scss";
import { DataGrid, trTR } from "@mui/x-data-grid";
import AccountTree from "@mui/icons-material/AccountTree";
import Modal from "react-bootstrap/Modal";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const RelatedDocuments = (props) => {
  const [show, setShow] = useState(null);
  const [url, setUrl] = useState(null);
  const [relatedDocuments] = useState(props.relatedDocuments);
  const subTitle =
    props.document.generatedId + " numaralı Belgeyle İlişkili Belgeleriniz";

  const create = (docDefinition) => {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      setUrl(url);
    });
  };

  async function showModal(contractId, show) {
    let docDefinition;

    if (show) {
      setShow(false);
    } else {
      await axios
        .get(`api/contract/get-contract/${contractId}`)
        .then((res) => {
          let contractHtmlContent = res.data["contractHTMLContent"];
          let htmlToPdf = htmlToPdfmake(contractHtmlContent, {
            tableAutoSize: true,
          });

          docDefinition = { content: htmlToPdf };
          create(docDefinition);
          setShow(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 280,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Tooltip title="Görüntüle" onHide={() => setShow(false)}>
              <IconButton>
                <SlideshowIcon
                  color="success"
                  onClick={() => showModal(params.row.contractId, show)}
                  onHide={() => setShow(false)}
                />
              </IconButton>
            </Tooltip>
            <Modal show={show} onHide={() => setShow(false)}>
              <div onClick={() => setShow(false)}></div>
              <div style={{ position: "fixed" }}>
                {url && (
                  <object
                    style={{
                      width: "200%",
                      height: "75vh",
                    }}
                    data={url}
                    type="application/pdf"
                  >
                    <embed
                      frameborder="0"
                      width="300%"
                      height="500px"
                      src={url}
                      type="application/pdf"
                    />
                  </object>
                )}
              </div>
            </Modal>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {relatedDocuments != null ? (
        <>
          <PageHeader
            title="İlişkili Belgeler"
            subTitle={subTitle}
            icon={<AccountTree fontSize="large" color="action" />}
          />
          <div className="home">
            <div className="homeContainer">
              <div className="listContainer">
                <div className="datatable">
                  <div className="datatableTitle">Sözleşmeler</div>
                  <DataGrid
                    className="datagrid"
                    rows={relatedDocuments}
                    getRowId={(row) => row.contractId}
                    columns={userColumns.concat(actionColumn)}
                    localeText={
                      trTR.components.MuiDataGrid.defaultProps.localeText
                    }
                    //components={{ NoRowsOverlay }}
                    pageSize={8}
                    //onCellClick={handleCellClick}
                    rowsPerPageOptions={[10]}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default RelatedDocuments;
