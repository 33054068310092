import { DocumentTypes } from "../../actions/types";

const contractStatus = [
  { value: 1, label: "Taslak" },
  { value: 2, label: "Onay Bekliyor" },
  { value: 3, label: "Onaylandı" },
  { value: 4, label: "Reddedildi" },
];

const documentTypes = [
  { value: DocumentTypes.Contract, label: "Sözleşme" },
  { value: DocumentTypes.Invoice, label: "Fatura" },
  { value: DocumentTypes.Offer, label: "Teklif" },
];

export const userColumns = [
  { field: "generatedId", headerName: "No", width: 160 },
  {
    field: "documentType",
    headerName: "Dokuman Tipi",
    width: 150,
    type: "singleSelect",
    valueOptions: documentTypes,
    valueFormatter: ({ id, value, field }) => {
      const option = documentTypes.find(
        ({ value: optionValue }) => optionValue === value
      );

      return option.label;
    },
  },
  {
    field: "contractorName",
    headerName: "Doküman Tarafı",
    width: 220,
  },
  {
    field: "contractorPhoneNumber",
    headerName: "Telefon Numarası",
    width: 130,
  },
  {
    field: "contractCreateDate",
    headerName: "Tarih",
    width: 100,
  },
  {
    field: "documentFee",
    headerName: "Tutar",
    width: 100,
  },
  {
    field: "status",
    headerName: "Statü",
    width: 130,
    type: "singleSelect",
    valueOptions: contractStatus,
    valueFormatter: ({ id, value, field }) => {
      const option = contractStatus.find(
        ({ value: optionValue }) => optionValue === value
      );

      return option.label;
    },
  },
];

export const templateColumns = [
  {
    field: "contractName",
    headerName: "Adı",
    width: 280,
  },
  {
    field: "documentType",
    headerName: "Dokuman Tipi",
    width: 150,
    type: "singleSelect",
    valueOptions: documentTypes,
    valueFormatter: ({ id, value, field }) => {
      const option = documentTypes.find(
        ({ value: optionValue }) => optionValue === value
      );

      return option.label;
    },
  },
];


