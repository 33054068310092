import { Button, Grid, Paper, TextField, Typography, makeStyles } from "@material-ui/core";
import PeopleIcon from "@mui/icons-material/People";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import axios from "axios";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SET_CURRENT_USER,
} from "../actions/types";
import setJWTToken from "../securityUtils/setJWTToken";
import cities from "./Data/cities";
import PageHeader from "./Layout/PageHeader";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
}));

export default function Profile(props) {
  const [tabValue, setTabValue] = useState(0);
  const [userData, setUserData] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [username, setUsername] = useState();

  const [firmData, setFirmData] = useState({});
  const [firmId, setFirmId] = useState(0);
  const [firmName, setFirmName] = useState("");
  const [firmType, setFirmType] = useState("");
  const [publicOrPrivateFirm, setPublicOrPrivateFirm] = useState("");
  const [taxIdentityNumber, setTaxIdentityNumber] = useState("");
  const [title, setTitle] = useState("");
  const [firmPhoneNumber, setFirmPhoneNumber] = useState("");
  const [firmEmail, setFirmEmail] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const [taxOffice, setTaxOffice] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [districts, setDistricts] = useState([]);
  const [address, setAddress] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBranchName, setBankBranchName] = useState("");
  const [iban, setIban] = useState("");

  const [phoneNumber, setPhoneNumber] = useState();
  const [userInfo, setUserInfo] = useState(true);
  const [firmInfo, setFirmInfo] = useState(true);
  const [identityNumber, setIdentityNumber] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.security.user);
  const userId = user.id;

  const fillDistricts = (selectedCity) => {
    setDistrict("");
    const counties = cities.filter((obj) => {
      return obj.il === selectedCity;
    });

    if (counties.length > 0) setDistricts(counties[0]["ilceleri"]);
  };

  useEffect(() => {
    axios
      .get(`api/users/get-user/${userId}`)
      .then((res) => {
        setUserData(res.data);
        console.log(res.data);

        setFirstName(res.data["firstName"]);
        setLastName(res.data["lastName"]);
        setUsername(res.data["username"]);
        setPhoneNumber(res.data["phoneNumber"]);
        setIdentityNumber(res.data["identityNumber"]);

        if(res.data.company && res.data.company !== null) {
        setFirmData(res.data.company);
        setFirmId(res.data.company["id"]);
        setFirmName(res.data.company["name"]);
        setFirmType(res.data.company["type"]);
        setPublicOrPrivateFirm(res.data.company["publicOrPrivate"]);
        setTaxIdentityNumber(res.data.company["identityNumber"]);
        setTaxOffice(res.data.company["taxOffice"]);
        setTitle(res.data.company["title"]);
        setFirmPhoneNumber(res.data.company["phoneNumber"]);
        //setFaxNumber(res.data["faxNumber"]);
        setFirmEmail(res.data.company["email"]);
        setCity(res.data.company["city"]);
        fillDistricts(res.data.company.city);
        setDistrict(res.data.company["district"]);
        setAddress(res.data.company["address"]);

        setBankName(res.data.company.bankName);
        setBankBranchName(res.data.company.bankBranchName);
        setIban(res.data.company.iban);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    /* axios
      .get(`api/users/get-private-integrator/${userId}`)
      .then((res) => {
        setFirmData(res.data);
        setFirmId(res.data["id"]);
        setFirmName(res.data["firmName"]);
        setFirmType(res.data["firmType"]);
        setPublicOrPrivateFirm(res.data["publicOrPrivateFirm"]);
        setTaxID(res.data["taxID"]);
        setTaxAdminstration(res.data["taxAdminstration"]);
        setTitle(res.data["title"]);
        setFirmPhoneNumber(res.data["phoneNumber"]);
        setFaxNumber(res.data["faxNumber"]);
        setFirmEmail(res.data["email"]);
        setCity(res.data["city"]);
        setDistrict(res.data["district"]);
        setAddress(res.data["address"]);
      })
      .catch((error) => {
        console.log(error);
      }); */
  }, [userId]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const updateToken = (token) => {
    console.log(token)
    //updateToken(token);
    localStorage.setItem("jwtToken", token);
  
  // set our token in header ***
  setJWTToken(token);
  // decode token on React
  const decoded = jwt_decode(token);
  console.log("decoded token bilgisi")
  console.log(decoded)
  if(decoded.company !== null) {
    setFirmData(decoded.company);
    setFirmId(decoded.company.id);
  }
  // dispatch to our securityReducer
  dispatch({
    type: SET_CURRENT_USER,
    payload: decoded,
  });
  window.location.reload();
  }

  const updateUserInfo = () => {
    let newUser = {
      firstName: firstName,
      lastName: lastName,
      username: username,
      phoneNumber: phoneNumber,
      identityNumber: identityNumber,
    };

    axios
      .post(`api/users/update-user/${userId}`, newUser)
      .then((res) => {
        if (res.status === 200) {
          const {token} = res.data;
          updateToken(token);
          toast.success("Değişiklikler Kaydedildi", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Hata Oluştu", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        toast.error("Hata Oluştu", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(error);
      });
  };

  const updateContractInfo = () => {};
  const updateCompanyInfo = () => {
    let companyUser = {
      company: {
        id: firmId,
        name: firmName,
        type: firmType,
        publicOrPrivate: publicOrPrivateFirm,
        identityNumber: taxIdentityNumber,
        taxOffice: taxOffice,
        title: title,
        phoneNumber: firmPhoneNumber,
        email: firmEmail,
        city: city,
        district: district,
        address: address,
        bankName: bankName,
        bankBranchName: bankBranchName,
        iban: iban,
      },
      userId: userId,
    };

    axios
      .post(`api/users/register-user-company/`, companyUser)
      .then((res) => {
        if (res.status === 200) {
          setFirmInfo(true);
          const {token} = res.data;
          updateToken(token);
          toast.success("Değişiklikler Kaydedildi", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Hata Oluştu", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  const classes = useStyles();

  return (
    <>
      <PageHeader
        title="Profil Bilgileri"
        subTitle="Profil bilgilerinizi görüntüleyebilir ve düzenleyebilirsiniz"
        icon={<PeopleIcon fontSize="large" color="action" />}
      />

      <Paper className={classes.pageContent}>
        <form>
          <Grid container spacing={3}>
            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
              <Tabs value={tabValue} onChange={handleChange} centered>
                <Tab label="Kullanıcı Bilgileri" />
                <Tab label="Firma Bilgileri" />
                <Tab label="Şifre Değiştir" />
              </Tabs>
            </Box>

            {tabValue === 0 && (
              <>
                <Grid item xs={12}>
                  <TextField
                    label="İsim"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    name="firstName"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      if (e.target.value === userData["firstName"]) {
                        setUserInfo(true);
                      } else {
                        setUserInfo(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Soyisim"
                    placeholder="Soyisim"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    name="lastName"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      if (e.target.value === userData["lastName"]) {
                        setUserInfo(true);
                      } else {
                        setUserInfo(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    placeholder="Email"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    name="username"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                      if (e.target.value === userData["username"]) {
                        setUserInfo(true);
                      } else {
                        setUserInfo(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Telefon Numarası"
                    placeholder="Telefon Numarası"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      if (e.target.value === userData["phoneNumber"]) {
                        setUserInfo(true);
                      } else {
                        setUserInfo(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Kimlik Numarası"
                    placeholder="Kimlik Numarası"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    name="identityNumber"
                    value={identityNumber}
                    onChange={(e) => {
                      setIdentityNumber(e.target.value);
                      if (e.target.value === userData["identityNumber"]) {
                        setUserInfo(true);
                      } else {
                        setUserInfo(false);
                      }
                    }}
                  />

                  <Box textAlign="right">
                    <Button
                      onClick={() => updateUserInfo()}
                      variant="contained"
                      color="primary"
                      style={{ marginTop: "10px" }}
                      disabled={userInfo}
                    >
                      Değişiklikleri Kaydet
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
            {tabValue === 1 && (
              <>
                <Typography>
                  <Box sx={{ fontWeight: "bold" }}>Firma Bilgileri</Box>
                </Typography>
                <div style={{ width: "100%" }}>
                  <Box
                    align="center"
                    sx={{
                      display: "grid",
                      rowGap: 3,
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: 3,
                    }}
                  >
                    <TextField
                      label="Firma Adı"
                      placeholder="Firma Adı"
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      name="firmName"
                      value={firmName}
                      onChange={(e) => {
                        setFirmName(e.target.value);
                        if (e.target.value === firmData.name) {
                          setFirmInfo(true);
                        } else {
                          setFirmInfo(false);
                        }
                      }}
                    />
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ marginTop: "15px" }}
                    >
                      <InputLabel id="firmTypeLabel">
                        Firma Kişi Tipi
                      </InputLabel>
                      <Select
                        labelId="firmTypeLabel"
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        id="firmTypeSelect"
                        label="Firma Kişi Tipi"
                        value={firmType}
                        onChange={(e) => {
                          setPublicOrPrivateFirm("");
                          setFirmType(e.target.value);
                          if (e.target.value === firmData.type) {
                            setFirmInfo(true);
                          } else {
                            setFirmInfo(false);
                          }
                        }}
                        MenuProps={MenuProps}
                      >
                        <MenuItem sx={{ minWidth: 200 }} value={"Tüzel"}>
                          Tüzel
                        </MenuItem>
                        <MenuItem sx={{ minWidth: 200 }} value={"Gerçek"}>
                          Gerçek
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: 3,
                    }}
                  >
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ marginTop: "15px" }}
                    >
                      <InputLabel id="publicOrPrivateLabel">
                        Firma Tipi
                      </InputLabel>
                      <Select
                        labelId="publicOrPrivateLabel"
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        id="publicOrPrivateSelect"
                        label="Firma Tipi"
                        disabled={firmType === "Gerçek"}
                        value={publicOrPrivateFirm}
                        onChange={(e) => {
                          setPublicOrPrivateFirm(e.target.value);
                          if (
                            e.target.value === firmData.publicOrPrivate
                          ) {
                            setFirmInfo(true);
                          } else {
                            setFirmInfo(false);
                          }
                        }}
                        MenuProps={MenuProps}
                      >
                        <MenuItem sx={{ minWidth: 200 }} value={"Kamu"}>
                          Kamu
                        </MenuItem>
                        <MenuItem sx={{ minWidth: 200 }} value={"Özel"}>
                          Özel
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      label="Unvan"
                      placeholder="Unvan"
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      name="title"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        if (e.target.value === firmData["title"]) {
                          setFirmInfo(true);
                        } else {
                          setFirmInfo(false);
                        }
                      }}
                    />
                  </Box>
                </div>
                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: 3,
                    }}
                  >
                    <TextField
                      label="Vergi Dairesi"
                      placeholder="Vergi Dairesi"
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      name="taxOffice"
                      value={taxOffice}
                      onChange={(e) => {
                        setTaxOffice(e.target.value);
                        if (e.target.value === firmData["taxOffice"]) {
                          setFirmInfo(true);
                        } else {
                          setFirmInfo(false);
                        }
                      }}
                    />
                    <FormControl>
                      <TextField
                        label="Vergi Numarası"
                        placeholder="Vergi Numarası"
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        name="taxIdentityNumber"
                        value={taxIdentityNumber}
                        onChange={(e) => {
                          setTaxIdentityNumber(e.target.value);
                          if (e.target.value === firmData.identityNumber) {
                            setFirmInfo(true);
                          } else {
                            setFirmInfo(false);
                          }
                        }}
                      />
                      {!/^\d{10}$/.test(taxIdentityNumber) && (
                        <p style={{ color: "red" }}>
                          Hatalı Vergi Kimlik Numarası
                        </p>
                      )}
                    </FormControl>
                  </Box>
                </div>
                <Typography>
                  <Box sx={{ fontWeight: "bold" }}>Banka Bilgileri</Box>
                </Typography>
                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: 3,
                    }}
                  >
                    <TextField
                      label="Banka Adı"
                      placeholder="Banka Adı"
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      name="bankName"
                      value={bankName}
                      onChange={(e) => {
                        setBankName(e.target.value);
                        if (e.target.value === firmData["bankName"]) {
                          setFirmInfo(true);
                        } else {
                          setFirmInfo(false);
                        }
                      }}
                    />
                    <TextField
                      label="Şube Adı"
                      placeholder="Şube Adı"
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      name="bankBranchName"
                      value={bankBranchName}
                      onChange={(e) => {
                        setBankBranchName(e.target.value);
                        if (e.target.value === firmData["bankBranchName"]) {
                          setFirmInfo(true);
                        } else {
                          setFirmInfo(false);
                        }
                      }}
                    />
                  </Box>
                </div>
                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(1, 1fr)",
                      gap: 3,
                    }}
                  >
                    <TextField
                      label="IBAN No"
                      placeholder="IBAN No"
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      name="iban"
                      value={iban}
                      onChange={(e) => {
                        setIban(e.target.value);
                        if (e.target.value === firmData["iban"]) {
                          setFirmInfo(true);
                        } else {
                          setFirmInfo(false);
                        }
                      }}
                    />
                  </Box>
                </div>
                <Typography>
                  <Box sx={{ fontWeight: "bold" }}>İletişim Bilgileri</Box>
                </Typography>
                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: 3,
                    }}
                  >
                    <TextField
                      label="Email"
                      placeholder="Email"
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      name="firmEmail"
                      value={firmEmail}
                      onChange={(e) => {
                        setFirmEmail(e.target.value);
                        if (e.target.value === firmData["email"]) {
                          setFirmInfo(true);
                        } else {
                          setFirmInfo(false);
                        }
                      }}
                    />
                    <TextField
                      label="Telefon Numarası"
                      placeholder="Telefon Numarası"
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      name="firmPhoneNumber"
                      value={firmPhoneNumber}
                      onChange={(e) => {
                        setFirmPhoneNumber(e.target.value);
                        if (e.target.value === firmData["phoneNumber"]) {
                          setFirmInfo(true);
                        } else {
                          setFirmInfo(false);
                        }
                      }}
                    />
                    <TextField
                      label="Faks Numarası"
                      placeholder="Faks Numarası"
                      variant="outlined"
                      margin="normal"
                      disabled
                      InputLabelProps={{ shrink: true }}
                      name="FaxNumber"
                      value={faxNumber}
                      onChange={(e) => {
                        setFaxNumber(e.target.value);
                        if (e.target.value === firmData["FaxNumber"]) {
                          setFirmInfo(true);
                        } else {
                          setFirmInfo(false);
                        }
                      }}
                    />
                  </Box>
                </div>
                <Typography>
                  <Box sx={{ fontWeight: "bold" }}>Adres Bilgisi</Box>
                </Typography>
                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: 3,
                      marginTop: "15px"
                    }}
                  >
                  <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-label">İl</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    //id="demo-simple-select-helper-label"
                    label="İl"
                    margin="normal"
                    value={city}
                    fullWidth
                    MenuProps={MenuProps}
                    onChange={(e) => {
                      setCity(e.target.value);
                      fillDistricts(e.target.value);
                        if (e.target.value === firmData["city"]) {
                          setFirmInfo(true);
                        } else {
                          setFirmInfo(false);
                        }
                    }}
                  >
                    {cities.map((c, index) => {
                      return (
                        <MenuItem
                          sx={{ minWidth: 180 }}
                          value={c.il}
                          key={index}
                        >
                          {c.il}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-label">İlçe</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    //id="demo-simple-select-helper-label"
                    label="İlçe"
                    margin="normal"
                    value={district}
                    fullWidth
                    MenuProps={MenuProps}
                    onChange={(e) => {
                      setDistrict(e.target.value);
                      if (e.target.value === firmData.district) {
                        setFirmInfo(true);
                      } else {
                        setFirmInfo(false);
                      }
                    }}
                  >
                    {districts.map((countie, index) => {
                      return (
                        <MenuItem
                          sx={{ minWidth: 280 }}
                          value={countie}
                          key={index}
                        >
                          {countie}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                  </Box>
                </div>
                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(1, 1fr)",
                      gap: 3,
                    }}
                  >
                    <TextField
                      label="Adres"
                      placeholder="Adres"
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      name="address"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                        if (e.target.value === firmData["address"]) {
                          setFirmInfo(true);
                        } else {
                          setFirmInfo(false);
                        }
                      }}
                    />
                  </Box>
                  <Box textAlign="right">
                    <Button
                      onClick={() => updateCompanyInfo()}
                      variant="contained"
                      color="primary"
                      style={{ marginTop: "10px" }}
                      disabled={firmInfo}
                    >
                      Değişiklikleri Kaydet
                    </Button>
                  </Box>
                </div>
              </>
            )}
            {tabValue === 2 && (
              <>
                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(1, 1fr)",
                      gap: 3,
                    }}
                  >
                    <TextField
                      label="Eski Şifreniz"
                      placeholder="Eski Şifreniz"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  </Box>
                </div>
                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(1, 1fr)",
                      gap: 3,
                    }}
                  >
                    <TextField
                      label="Yeni Şifre"
                      placeholder="Yeni Şifre"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  </Box>
                </div>
                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(1, 1fr)",
                      gap: 3,
                    }}
                  >
                    <TextField
                      label="Yeni Şifre Tekrar"
                      placeholder="Yeni Şifre Tekrar"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  </Box>
                  <Box textAlign="right">
                    <Button
                      onClick={() => updateContractInfo()}
                      variant="contained"
                      color="primary"
                      style={{ marginTop: "10px" }}
                      disabled={false}
                    >
                      Değişiklikleri Kaydet
                    </Button>
                  </Box>
                </div>
              </>
            )}
          </Grid>
        </form>
      </Paper>
      <ToastContainer />
    </>
  );
}
