import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cities from '../Data/cities';

export default function Customer({ customer, resetForm }) {
  const user = useSelector((state) => state.security.user);

  const { control, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: {
      id: 0,
      name: '',
      type: '',
      publicOrPrivate: '',
      identityNumber: '',
      title: '',
      phoneNumber: '',
      email: '',
      taxOffice: '',
      city: '',
      district: '',
      address: '',
      isDeleted: false,
      ownerIdentityNumber: user.company.identityNumber,
      legalEmailAddress: ''
    },
  });

  const watchType = watch('type');
  const watchCity = watch('city');

  useEffect(() => {
    if (customer) {
      Object.keys(customer).forEach(key => {
        if (key !== 'city' && key !== 'district') {
          setValue(key, customer[key]);
        }
      });
      
      // İl bilgisini ayarla
      if (customer.city) {
        setValue('city', customer.city);
        
        // İlçeleri güncelle
        const counties = cities.find(city => city.il === customer.city)?.ilceleri || [];
        
        // İlçe bilgisini ayarla
        if (customer.district && counties.includes(customer.district)) {
          setValue('district', customer.district);
        } else {
          setValue('district', '');
        }
      }
    }
  }, [customer, setValue]);

  useEffect(() => {
    if (watchCity) {
      const counties = cities.find(city => city.il === watchCity)?.ilceleri || [];
      if (!counties.includes(watch('district'))) {
        setValue('district', '');
      }
    }
  }, [watchCity, setValue, watch]);

  const onSubmit = async (data) => {
    console.log('Form data:', data);
    try {
      const response = await axios.post(`api/customer/save`, data);
      if (response.status === 200) {
        toast.success('Müşteri Oluşturuldu', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        resetForm();
      }
    } catch (error) {
      console.error('Error saving customer:', error);
      toast.error(`Müşteri kaydedilirken hata oluştu: ${error.message}`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ width: '100%', margin: 'auto', marginTop: '10px' }} align="center">
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 3 }}>
            <FormControl fullWidth>
              <Controller
                name="type"
                control={control}
                rules={{ required: 'Firma Kişi Tipi gerekli' }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel>Firma Kişi Tipi</InputLabel>
                    <Select {...field} label="Firma Kişi Tipi">
                      <MenuItem value="Tüzel">Tüzel</MenuItem>
                      <MenuItem value="Gerçek">Gerçek</MenuItem>
                    </Select>
                    {error && <p style={{ color: 'red' }}>{error.message}</p>}
                  </>
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <Controller
                name="publicOrPrivate"
                control={control}
                rules={{ required: watchType === 'Tüzel' ? 'Firma Tipi gerekli' : false }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel>Firma Tipi</InputLabel>
                    <Select {...field} label="Firma Tipi" disabled={watchType !== 'Tüzel'}>
                      <MenuItem value="Kamu">Kamu</MenuItem>
                      <MenuItem value="Özel">Özel</MenuItem>
                    </Select>
                    {error && <p style={{ color: 'red' }}>{error.message}</p>}
                  </>
                )}
              />
            </FormControl>

            <Controller
              name="name"
              control={control}
              rules={{ required: 'İsim gerekli', maxLength: { value: 50, message: 'İsim 50 karakterden uzun olamaz' } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={watchType === 'Tüzel' ? 'Firma Adı' : 'Ad Soyad'}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : ''}
                />
              )}
            />
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 3, marginTop: 3 }}>
            <Controller
              name="title"
              control={control}
              rules={{ required: 'Unvan gerekli', maxLength: { value: 50, message: 'Unvan 50 karakterden uzun olamaz' } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Unvan"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : ''}
                />
              )}
            />

            <Controller
              name="taxOffice"
              control={control}
              rules={{ required: 'Vergi Dairesi gerekli' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Vergi Dairesi"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : ''}
                />
              )}
            />

            <Controller
              name="identityNumber"
              control={control}
              rules={{
                required: 'Vergi Kimlik Numarası gerekli',
                pattern: {
                  value: /^\d{10}$/,
                  message: 'Geçerli bir Vergi Kimlik Numarası giriniz'
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Vergi Kimlik Numarası"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : ''}
                />
              )}
            />
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 3, marginTop: 3 }}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: 'E-posta adresi gerekli',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Geçerli bir e-posta adresi giriniz'
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="E-posta Adresi"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : ''}
                />
              )}
            />

            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: 'Telefon numarası gerekli',
                pattern: {
                  value: /^5(0[5-7]|[3-5]\d)\d{3}\d{4}$/,
                  message: 'Geçerli bir telefon numarası giriniz'
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Telefon Numarası"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : ''}
                />
              )}
            />

            <Controller
              name="legalEmailAddress"
              control={control}
              /* rules={{
                required: 'Telefon numarası gerekli',
                pattern: {
                  value: /^5(0[5-7]|[3-5]\d)\d{3}\d{4}$/,
                  message: 'Geçerli bir telefon numarası giriniz'
                }
              }} */
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="KEP Adres"
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : ''}
                />
              )}
            />
          </Box>
          
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 3, marginTop: 3 }}>
            <FormControl fullWidth>
              <Controller
                name="city"
                control={control}
                rules={{ required: 'İl seçimi gerekli' }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel>İl</InputLabel>
                    <Select {...field} label="İl">
                      {cities.map((city, index) => (
                        <MenuItem key={index} value={city.il}>{city.il}</MenuItem>
                      ))}
                    </Select>
                    {error && <p style={{ color: 'red' }}>{error.message}</p>}
                  </>
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <Controller
                name="district"
                control={control}
                rules={{ required: 'İlçe seçimi gerekli' }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel>İlçe</InputLabel>
                    <Select {...field} label="İlçe" disabled={!watchCity}>
                      {cities.find(city => city.il === watchCity)?.ilceleri.map((district, index) => (
                        <MenuItem key={index} value={district}>{district}</MenuItem>
                      ))}
                    </Select>
                    {error && <p style={{ color: 'red' }}>{error.message}</p>}
                  </>
                )}
              />
            </FormControl>
          </Box>

          <Box sx={{ marginTop: 3 }}>
            <Controller
              name="address"
              control={control}
              rules={{
                required: 'Adres gerekli',
                maxLength: { value: 250, message: 'Adres 250 karakterden uzun olamaz' }
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Adres"
                  fullWidth
                  multiline
                  rows={4}
                  error={!!error}
                  helperText={error ? error.message : ''}
                />
              )}
            />
          </Box>

          <Box sx={{ marginTop: 3 }}>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              type="submit"
              style={{ width: '100%' }}
            >
              Kaydet
            </Button>
          </Box>
        </Box>
      </form>
      <ToastContainer />
    </div>
  );
}