import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid, trTR } from "@mui/x-data-grid";
import axios from "axios";
import htmlToPdfmake from "html-to-pdfmake";
import JoditEditor from "jodit-react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DocumentCreationType } from "../../actions/types";
import "../Layout/datatable.scss";
import { templateColumns } from "../Layout/datatablesource";
import PageHeader from "../Layout/PageHeader";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      Şablon Kaydı Bulunmamaktadır
    </Stack>
  );
}

const Templates = () => {
  const editor = useRef(null);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const user = useSelector((state) => state.security.user);
  const [url, setUrl] = useState(null);
  const [show, setShow] = useState(false);
  const [updateShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [ownerIdentityNumber] = useState(
    user.company && user.company.identityNumber != null
      ? user.company.identityNumber
      : user.identityNumber
  );

  async function handleCellClick(e) {
    setSelectedRow(e.row);
  }

  async function templateData() {
    await axios
      .get(
        `api/contract-template/get-all-by-ownerIdentityNumber/${ownerIdentityNumber}`
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const create = (docDefinition) => {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      setUrl(url);
    });
  };

  async function showModal(id, show) {
    let docDefinition;

    if (show) {
      setShow(false);
    } else {
      await axios
        .get(`api/contract-template/get-by-id/${id}`)
        .then((res) => {
          let contractHtmlContent = res.data["contractHtmlTemplate"];
          let htmlToPdf = htmlToPdfmake(contractHtmlContent, {
            tableAutoSize: true,
          });

          docDefinition = { content: htmlToPdf };
          create(docDefinition);
          setShow(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async function handleDelete() {
    await axios
      .post(`api/contract-template/delete/${selectedRow.id}`)
      .then((res) => {
        templateData();
        setDeleteShow(false);
        setSelectedRow(null);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const config = {
    readonly: false,
    enableDragAndDropFileToEditor: true,
  };

  useEffect(() => {
    templateData();
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 280,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Tooltip title="Görüntüle" onHide={() => setShow(false)}>
              <IconButton>
                <SlideshowIcon
                  color="success"
                  onClick={() => showModal(params.row.id, show)}
                  onHide={() => setShow(false)}
                />
              </IconButton>
            </Tooltip>
            <Modal show={show} onHide={() => setShow(false)}>
              <div onClick={() => setShow(false)}></div>
              <div style={{ position: "fixed" }}>
                {url && (
                  <object
                    style={{
                      width: "200%",
                      height: "75vh",
                    }}
                    data={url}
                    type="application/pdf"
                  >
                    <embed
                      frameborder="0"
                      width="300%"
                      height="500px"
                      src={url}
                      type="application/pdf"
                    />
                  </object>
                )}
              </div>
            </Modal>
            <Modal show={deleteShow} onHide={() => setDeleteShow(false)}>
              <Modal.Body>Şablon Silinsin mi?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setDeleteShow(false)}
                >
                  Hayır
                </Button>
                <Button variant="primary" onClick={(e) => handleDelete()}>
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <Tooltip title="Belge Oluştur">
              <IconButton>
                <Link
                  to={{
                    pathname: "/entryBasicContract",
                    state: {
                      documentCreationType: DocumentCreationType.FromTemplate,
                      documentType: params.row.documentType,
                      id: params.row.id,
                    },
                  }}
                  style={{ color: "gray" }}
                >
                  <NoteAddIcon
                    style={{ color: "purple", variant: "fullwidth" }}
                  />
                </Link>
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Güncelle">
              <IconButton onClick={() => {setSelectedRow(params.row); setUpdateShow(true);}}>
                <DeleteIcon color="inherit" />
              </IconButton>
            </Tooltip> */}
            {params.row.ownerIdentityNumber &&
            params.row.ownerIdentityNumber !== null &&
            params.row.ownerIdentityNumber !== "" ? (
              <Tooltip title="Sil">
                <IconButton onClick={() => setDeleteShow(true)}>
                  <DeleteIcon color="inherit" />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader
        title="Şablonlar"
        subTitle="Şablonlarınızı görüntüleyebilir, doküman oluşturabilirsiniz."
        icon={<ArchiveIcon fontSize="large" color="action" />}
      />
      {updateShow ? (
        <JoditEditor
          ref={editor}
          config={config}
          value={selectedRow.contractHtmlTemplate}
        />
      ) : null}
      <div className="home">
        <div className="homeContainer">
          <div className="listContainer">
            <div className="datatable">
              <div className="datatableTitle">Şablonlar</div>
              <DataGrid
                className="datagrid"
                rows={data}
                getRowId={(row) => row.id}
                columns={templateColumns.concat(actionColumn)}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                components={{ NoRowsOverlay }}
                pageSize={8}
                onCellClick={handleCellClick}
                rowsPerPageOptions={[10]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Templates;
