import axios from "axios";
import {
  GET_ERRORS,
  GET_CONTRACTS,
  SET_CONTRACTS,
  GET_CONTRACT,
} from "./types";
import { saveAs } from "file-saver";


export const createContract = (contract, history) => async (dispatch) => {
  try {
    await axios
      .post("api/project/contract", contract)
      .then(() => axios.get(`api/project/contract/${contract.identifierNo}`))
      .then((res) => {
        console.log(typeof res);
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        saveAs(pdfBlob, "newPdf.pdf");
      })
      .catch((error) => {
        console.log(error);
      })
      .catch((error) => {
        console.log(error);
      });

    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getContract = (contractId, history) => async (dispatch) => {
  try {
    const res = await axios.get(
      `api/rental-contract/get-contract/${contractId}`
    );

    dispatch({
      type: GET_CONTRACT,
      payload: res.data,
    });
  } catch (error) {
    history.push("/dashboard");
  }
};

export const getContracts = (userId, history) => async (dispatch) => {
  try {
    const res = await axios.get(`api/contract/get-all-contract/${userId}`);

    dispatch({
      type: GET_CONTRACTS,
      payload: res.data,
    });
  } catch (error) {
    history.push("/dashboard");
  }
};

export const setContracts = (contracts, history) => async (dispatch) => {
  try {
    dispatch({
      type: SET_CONTRACTS,
      payload: contracts,
    });
  } catch (error) {
    history.push("/dashboard");
  }
};

export const createRentalContract =
  (rentalContract, history) => async (dispatch) => {
    try {
      await axios.post("api/rental-contract/create-contract", rentalContract);
      // .then(() => axios.get(`api/project/contract/${rentalContract.contractId}`))
      // .then((res) => {
      //     console.log(typeof (res))
      // })
      //history.push("/dashboard");
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  };
