import { t } from "i18next";
import { UseFormSetError } from "react-hook-form";

export const transformAndSetApiValidationError = (
  apiErrors: Record<string, any>,
  setError: UseFormSetError<any>
) => {
  // Her alanı ve hatalarını gruplamak için bir map oluşturuyoruz
  const groupedErrors = Object.keys(apiErrors).reduce((acc, field) => {
    // Her alan için hata mesajlarını topluyoruz
    const errorMessages = apiErrors[field].map((err: any) => 
      `${t('validation:' + err.constraint, { value: err.value })}`
    );
    
    acc[field] = errorMessages;
    return acc;
  }, {} as Record<string, string[]>);

  // Gruplanmış hataları setError ile form state'ine aktarıyoruz
  Object.keys(groupedErrors).forEach((field) => {
    setError(field, {
      type: 'server',
      message: groupedErrors[field].join(' | '), // Hata mesajlarını birleştiriyoruz
      types: {
        server: groupedErrors[field] // Tüm hataları array olarak saklıyoruz
      }
    });
  });
};