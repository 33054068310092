const PopularData = [
  {
    id: 1,
    image: "images/popular6.jpg",
    country: "",
    name: "Sözleşme Yönetimi",
    price: "",
  },
  {
    id: 2,
    image: "images/popular7.jpg",
    country: "",
    name: "Fatura Yönetimi",
    price: "",
  },
  {
    id: 3,
    image: "images/popular8.jpg",
    country: "Thailand",
    name: "İlişkilendirilmiş Fatura ve Sözleşmeler",
    price: "",
  },
  {
    id: 4,
    image: "images/popular9.jpg",
    country: "Thailand",
    name: "Kira Yönetimi",
    price: "",
  },
]

export default PopularData
