import { Button, Step, StepLabel, Stepper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AttachFile, Delete, DocumentScanner } from '@mui/icons-material';
import { Box, Card, CardContent, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DocumentCreationType, DocumentTypes } from "../../actions/types";
import cities from "../Data/cities";
import FileUpload from "../FileUpload";
import BasicContractTransform from "./BasicContractTransform";
import ContractPreView from "./ContractPreView";
import CostItems from "./CostItems";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Belge Şablonu",
    "Belge Bilgileri",
    "Müşteri Bilgileri",
    "Belge İçeriği",
    "Ödeme Bilgileri",
    "Doküman Yükle",
    "Belge Tüm Bilgiler",
    "Belge",
  ];
}



const BasicContractLinearStepper = (props) => {
  const [files, setFiles] = useState([]);
  const classes = useStyles();
  const user = useSelector((state) => state.security.user);
  const fromIndirect =
    props &&
    props != null &&
    props.documentCreationInfo &&
    props.documentCreationInfo != null;
  const fromTemplate = fromIndirect && props.documentCreationInfo.documentCreationType === DocumentCreationType.FromTemplate;
  const fromRelatedDocument = fromIndirect && props.documentCreationInfo.documentCreationType === DocumentCreationType.RelatedDocument;
  const fromRenewal = fromIndirect && props.documentCreationInfo.documentCreationType === DocumentCreationType.Renewal;
  const fromUpdate = fromIndirect && props.documentCreationInfo.documentCreationType === DocumentCreationType.Update;
  const userId = user.id;
  const history = useHistory();
  const methods = useForm({
    defaultValues: {
      documentType: fromTemplate ? props.documentCreationInfo.documentType : "",
      templateId: fromTemplate ? props.documentCreationInfo.id : "",
      relatedDocumentId: fromRelatedDocument
        ? props.documentCreationInfo.id
        : null,
      documentId: "0",
      basicContractType: "",
      contractOtherTypeValue: "",
      contractStartDate: "",
      contractFinishDate: "",

      firmName: "",
      firmType: "",
      publicOrPrivateFirm: "",
      taxIdentityNumber: "",
      title: "",
      phoneNumber: "",
      email: "",
      taxOffice: "",
      city: "",
      district: "",
      address: "",
      legalEmailAddress: "",

      basicContractTopic: "",
      contractFee: 0,
      currency: "",
      currencyRatio: "",
      contractPeriod: "",
      paymentType: "",

      privacy: "",
      criminalSanctions: "",
      otherSanctions: "",

      monthlyPayment: "",
      paymentDay: "",
      paymentDate: "",
      IBAN: "",

      //costItems: [],

      contractName: "",
      contractTypeId: 3,
      userId: userId,

      fromRenewal: fromRenewal,
      fromTemplate: fromTemplate,
    },
  });
  const [dataDocumentCreationInfo, setDataDocumentCreationInfo] = useState({});
  const [costItems, setCostItems] = useState([]);
  const [costItemsTotal, setCostItemsTotal] = useState(null);
  const [paymentItems, setPaymentItems] = useState([]);
  const [activeStep, setActiveStep] = useState(fromTemplate ? 1 : 0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();
  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };
  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  /* const handleFileSelect = (selectedFiles) => {
    setFiles(selectedFiles);
  }; */
  const handleFileSelect = useCallback((selectedFiles) => {
    setFiles(selectedFiles);
  }, []);

  //const costItems = watch('costItems');
  const fee = methods.watch("contractFee")
  console.log(fee)
  function getStepContent(step, documentCreationInfo) {
    switch (step) {
      case 0:
        return <ContractDraft />;
      case 1:
        return <ContractInformations />;
      case 2:
        return <CustomerInformations />;
      case 3:
        return <ContractContent />;
      case 4:
        return <CostItems initialData={costItems} costItemTotal={costItemsTotal} initialPaymentItems={paymentItems} onChange={handleItemsChange} form={methods} />;
      case 5:
        return <FileUpload
          initialDocuments={files || []}
          onFileSelect={handleFileSelect}
        />
      case 6:
        return <ShowAllInformation />;
      case 7:
        return <ShowContractPreView />;
      default:
        return "unknown step";
    }
  }

  const handleItemsChange = useCallback((items, itemsTotal, paymentItems) => {
    setCostItems(items);
    setCostItemsTotal(itemsTotal)
    setPaymentItems(paymentItems);
  }, []);

  const handleSaveContract = () => {
    let request = {
      contractInfo: methods.getValues(),
      attachedDocuments: files,
      costItemInfos: costItems,
      costItemTotalInfo: costItemsTotal,
      paymentItemInfos: paymentItems
    }
    console.log(request);
    axios
      .post(`api/contract/create-document`, request)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          let message = "Belgeniz oluşturuldu";
          if (fromUpdate)
            message = "Belgeniz güncellendi";

          toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          let returnUrl = "/draftContracts"
          switch (methods.getValues("documentType")) {
            case DocumentTypes.Offer:
              returnUrl = "/draftOffers"
              break;
            case DocumentTypes.Invoice:
              returnUrl = "/draftInvoices"
          }

          history.push(returnUrl);

        }
      })
      .catch((error) => {
        toast.success("Belgeniz Oluşturulurken hata oluştu", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleSendContract = () => {
    let request = {
      contractInfo: methods.getValues(),
      attachedDocuments: files,
      costItemInfos: costItems,
      costItemTotalInfo: costItemsTotal,
      paymentItems: paymentItems
    }
    let returnUrl = "Contracts"
    switch (methods.getValues("documentType")) {
      case DocumentTypes.Offer:
        returnUrl = "Offers"
        break;
      case DocumentTypes.Invoice:
        returnUrl = "Invoices"
    }


    axios
      .post(`api/contract/create-document`, request)
      .then((res) => {
        let contractStatus = {
          id: res.data.id,
          status: 2,
        };
        if (res.status === 200) {
          axios
            .post(`api/contract/update-contract-status`, contractStatus)
            .then((response) => {
              if (response.status === 200) {
                toast.success("Belgeniz Onaya Gönderildi", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            })
            .catch((e) => {
              toast.success(
                "Belgeniz oluşturuldu, onaya gönderilirken hata oluştu : " +
                e.response.data["message"],
                {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );

              setTimeout(() => {
                history.push("/draft" + returnUrl);
              }, 4000);
            });

          setTimeout(() => {
            history.push("/outgoing" + returnUrl);
          }, 4000);
        }
      })
      .catch((error) => {
        toast.success("Belgeniz Oluşturulurken hata oluştu", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleNext = (data, e) => {
    setDataDocumentCreationInfo(data);
    if (activeStep === steps.length - 1) {
      setActiveStep(activeStep + 1);
      toast.info("Belge Bilgilerinizi Kontrol Ediniz", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };

  async function getRenewalDocument(id) {
    await axios
      .get(`api/contract/get-contract/${id}`)
      .then((res) => {
        let object = JSON.parse(res.data.contractContent);
        for (var key in object) {
          if (key !== "id" && key !== "relatedDocumentId")
            methods.setValue(key, object[key]);
        }

        setCostItems(res.data.costItems);
        methods.setValue("relatedDocumentId", id);
      })
      .catch((error) => {
        toast.error("Yenilenecek belge alınamadı", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  async function getUpdatedDocument(id) {
    await axios
      .get(`api/contract/get-contract/${id}`)
      .then((res) => {
        let object = JSON.parse(res.data.contractContent);
        for (var key in object) {
          methods.setValue(key, object[key]);
        }
        console.log(res.data)
        const transformedData = res.data.attachments.map((attachment) => ({
          attachment: {
            id: attachment.id,
            documentId: attachment.documentId,
            name: attachment.name,
            contentType: attachment.contentType, // MIME tipi
            path: attachment.path
          },
          content: null // Base64 string
        }));

        setFiles(transformedData);
        setCostItems(res.data.costItems);
        methods.setValue("documentId", id);
      })
      .catch((error) => {
        toast.error("Yenilenecek belge alınamadı", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  useEffect(() => {
    if (fromRenewal) getRenewalDocument(props.documentCreationInfo.id);
    if (fromUpdate) getUpdatedDocument(props.documentCreationInfo.id);
  }, []);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleDeleteFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const openFile = useCallback((file) => {
    const byteCharacters = atob(file.content.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL, '_blank');
  }, []);
  const handleSetValue = (value) => {
   
  const inputText = document.getElementById("outlined-textarea");
    const values = [...value.data.contract_subject, ...value.data.confidentiality_terms,...value.data.penalty_clauses];;
    const lines = values.filter(item => item.trim() !== "").map(item => item.trim());
    const formattedLines = lines.map(line => {
      if (line.includes(":")) {
        return line.trim() + "\n";
       }    
       else{
        return line.trim();
       }
    });
    const formattedText = formattedLines.join("\n");
    methods.setValue("basicContractTopic", formattedText);
   
  };
  async function produceData(documan) {
    await axios.post("api/ai/generate-contract", documan)
     .then((res) => {
       handleSetValue(res)
     
     })
     .catch((error) => {
       console.log(error);
     }); 
  }
  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepFalied() && activeStep === index) {
            labelProps.error = true;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div >
        {activeStep === 3 ? (
          <Button
            className={classes.button}
            onClick={() => produceData(dataDocumentCreationInfo)}
            variant="outlined"
            size="large"
            color="primary"
          >
            İçerik Üret
          </Button>
        ) : null}
      </div>
      {activeStep === steps.length ? (
        <div>
          <ToastContainer />
          <BasicContractTransform
            basicContractInformations={methods.getValues()}
          />
          <Button
            className={classes.button}
            disabled={activeStep === 0}
            onClick={handleBack}
            variant="outlined"
            size="large"
            color="primary"
          >
            Güncelle
          </Button>
        </div>
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleNext)}>
              {getStepContent(activeStep, props.documentCreationInfo)}
              <Box
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "75ch" },
                }}
                noValidate
                align="right"
                autoComplete="off"
              >

                {(files.length > 0 && activeStep === steps.length - 1) ? (
                  <Box sx={{ marginBottom: 2 }}>
                    <Card elevation={3} >
                      <CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <AttachFile sx={{ marginRight: 1 }} />
                          <Typography variant="h6" gutterBottom sx={{ flexGrow: 1, textAlign: 'left' }}>
                            Ek Dokümanlar
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          {files.map((file, index) => (
                            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <Typography
                                onClick={() => openFile(file)}
                                sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                              >
                                <DocumentScanner sx={{ marginRight: 1 }} />
                                {file.attachment.name}
                              </Typography>
                              <Button
                                startIcon={<Delete />}
                                onClick={() => handleDeleteFile(index)}
                                color="error"
                              >
                                Sil
                              </Button>
                            </Box>
                          ))}
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                ) : null}
                {activeStep !== 0 ? (
                  <Button
                    className={classes.button}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    variant="outlined"
                    size="large"
                    color="primary"
                  >
                    geri
                  </Button>
                ) : null}
                {activeStep === steps.length - 1 ? (

                  <Button
                    className={classes.button}
                    onClick={handleSaveContract}
                    variant="outlined"
                    size="large"
                    color="primary"
                  >
                    Kaydet
                  </Button>
                ) : (
                  <Button
                    className={classes.button}
                    variant="outlined"
                    size="large"
                    color="primary"
                    type="submit"
                  //onClick={handleNext}
                  >
                    İleri
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  <Button
                    className={classes.button}
                    onClick={handleSendContract}
                    variant="outlined"
                    size="large"
                    color="primary"
                  >
                    Gönder
                  </Button>
                ) : null}
              </Box>
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};
export default BasicContractLinearStepper;

const ContractInformations = () => {
  const {
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [basicContractTypeCheck, setBasicContractTypeCheck] = useState(
    getValues("basicContractType")
  );
  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  return (
    <>
      <Box
        component="form"
        sx={{ width: "50%", margin: "auto" }}
        noValidate
        align="center"
        autoComplete="off"
      >
        {getValues("documentType") === 1 ? (
          <div>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">
                Sözleşme Türü
              </InputLabel>
              <Controller
                name="basicContractType"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    id="basicContractType"
                    label="Sözleşme Türü"
                    margin="normal"
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      setBasicContractTypeCheck(e.target.value);
                      setValue("basicContractType", e.target.value);
                      setValue("contractOtherTypeValue", "");
                    }}
                    onBlur={onBlur}
                    MenuProps={MenuProps}
                  >
                    <MenuItem sx={{ minWidth: 200 }} value={"SATIŞ"}>
                      SATIŞ
                    </MenuItem>
                    <MenuItem sx={{ minWidth: 200 }} value={"HİZMET"}>
                      HİZMET
                    </MenuItem>
                    <MenuItem sx={{ minWidth: 200 }} value={"KİRALAMA"}>
                      KİRALAMA
                    </MenuItem>
                    <MenuItem sx={{ minWidth: 200 }} value={"DİĞER"}>
                      DİĞER
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            {errors.basicContractType && (
              <p style={{ color: "red" }}>Lütfen Sözleşme Türünüzü Giriniz</p>
            )}
          </div>
        ) : null}
        <TextField
          id="contractOtherTypeValue"
          label="Diğer Şözleşme Tipi"
          variant="outlined"
          fullWidth
          margin="normal"
          hidden={basicContractTypeCheck !== "DİĞER" ? true : false}
          InputLabelProps={{ shrink: true }}
          {...register("contractOtherTypeValue")}
        />
        <TextField
          id="contract-start-date"
          type="date"
          label="Başlangıç Tarihi"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          inputProps={{
            max: '9999-12-31', // Maksimum 4 haneli yıl
            min: '1900-01-01'  // Gerekirse minimum yılı da belirleyebilirsiniz
          }}
          {...register("contractStartDate", {
            required: "Başlangıç tarihi giriniz.",
          })}

        />
        {errors.contractStartDate && (
          <p style={{ color: "red" }}>Başlangıç Tarihi Giriniz</p>
        )}
        <TextField
          id="contract-end-date"
          type="date"
          label="Bitiş Tarihi"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          inputProps={{
            max: '9999-12-31', // Maksimum 4 haneli yıl
            min: '1900-01-01'  // Gerekirse minimum yılı da belirleyebilirsiniz
          }}
          {...register("contractFinishDate", {
            required: "Bitiş Tarihi girin.",
            validate: (value) => {
              if (!getValues("contractStartDate") || !value) return true;
              return new Date(value) >= new Date(getValues("contractStartDate")) ||
                "Bitiş tarihi başlangıç tarihinden küçük olamaz";
            }
          })}
        />
        {errors.contractFinishDate && (
          <p className="text-sm text-red-500">
            {errors.contractFinishDate.message}
          </p>
        )}
      </Box>
    </>
  );
};

const CustomerInformations = () => {
  const {
    register,
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [customers, setCustomers] = useState([]);
  const [firmTypeCheck, setFirmTypeCheck] = useState(getValues("firmType"));
  const [city] = useState(cities);
  const [district, setDistrict] = useState([]);
  const user = useSelector((state) => state.security.user);
  const ownerIdentityNumber = user.company !== null && user.company.identityNumber !== null && user.company.identityNumber !== "" ?
    user.company.identityNumber : user.identityNumber;

  const handleAssetProvince = (e) => {
    setValue("city", e.target.value);
    //setSelectedDistrict("");
    setValue("district", "");
    const counties = city.filter((obj) => {
      return obj.il === e.target.value;
    });

    setDistrict(counties[0]["ilceleri"]);
  };

  const fillProvince = (e) => {
    const counties = city.filter((obj) => {
      return obj.il === e;
    });

    setDistrict(counties[0]["ilceleri"]);
  };

  async function handleSaveCustomer() {
    try {
      const customer = {
        id: 0,
        name: getValues("firmName"),
        type: getValues("firmType"),
        publicOrPrivate: getValues("publicOrPrivateFirm"),
        identityNumber: getValues("taxIdentityNumber"),
        title: getValues("title"),
        phoneNumber: getValues("phoneNumber"),
        email: getValues("email"),
        taxOffice: getValues("taxOffice"),
        city: getValues("city"),
        district: getValues("district"),
        address: getValues("address"),
        isDeleted: false,
        ownerIdentityNumber: user.company.identityNumber,
        legalEmailAddress: getValues("legalEmailAddress")
      }

      const response = await axios.post(`api/customer/save`, customer);
      if (response.status === 200) {
        toast.success('Müşteri Bilgileri kaydedildi.', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error('Error saving customer:', error);
      toast.error(`Müşteri kaydedilirken hata oluştu: ${error.message}`, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  async function setCustomerInfo(e) {
    if (e == null) return;
    setValue("firmType", e.type);
    setFirmTypeCheck(e.type);
    setValue("firmName", e.name);
    setValue("publicOrPrivateFirm", e.publicOrPrivate);
    //setPp(e.publicOrPrivate);
    setValue("taxIdentityNumber", e.identityNumber);
    setValue("taxOffice", e.taxOffice);
    setValue("title", e.title);
    setValue("phoneNumber", e.phoneNumber);
    setValue("email", e.email);
    setValue("city", e.city);
    if (e.city && e.city !== null && e.city !== "") fillProvince(e.city);
    setValue("district", e.district);
    //setSelectedDistrict(e.district);
    setValue("address", e.address);
    setValue("legalEmailAddress", e.legalEmailAddress)
  }

  async function customerData() {
    console.log(ownerIdentityNumber);
    await axios
      .get(`api/customer/get-all/${ownerIdentityNumber}`)
      .then((res) => {
        setCustomers(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    customerData();

    if (getValues("city") !== null && getValues("city") !== "") {
      fillProvince(getValues("city"));
    }
  }, []);

  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <Autocomplete
              id="title"
              freeSolo
              options={customers}
              getOptionLabel={(option) => option.title}
              onChange={(option, value) => {
                setCustomerInfo(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Kayıtlı Müşterilerden Seç"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  placeholder="Müşteri Ara"
                />
              )}
            />
          </FormControl>
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <Box
          align="center"
          sx={{
            display: "grid",
            rowGap: 3,
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 3,
          }}
        >
          <FormControl fullWidth variant="outlined">
            <InputLabel>Firma Kişi Tipi</InputLabel>
            <Controller
              name="firmType"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="firmType"
                  label="Firma Kişi Tipi"
                  margin="normal"
                  value={value}
                  fullWidth
                  onChange={(e) => {
                    onChange(e);
                    setFirmTypeCheck(e.target.value);
                    //setValue("firmType", e.target.value);
                    //setPp("");
                    setValue("publicOrPrivateFirm", "");
                  }}
                  onBlur={onBlur}
                  MenuProps={MenuProps}
                >
                  <MenuItem sx={{ minWidth: 200 }} value={"Tüzel"}>
                    Tüzel
                  </MenuItem>
                  <MenuItem sx={{ minWidth: 200 }} value={"Gerçek"}>
                    Gerçek
                  </MenuItem>
                </Select>
              )}
            />
            {/* {errors.firmType && <p style={{ color: "red" }}>Lütfen Firma Tipini Seçiniz</p>} */}
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <InputLabel>Firma Tipi</InputLabel>
            <Controller
              name="publicOrPrivateFirm"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="publicOrPrivateFirm"
                  label="Firma Tipi"
                  margin="normal"
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e);
                    //setPp(e.target.value);
                    //setValue("publicOrPrivateFirm", e.target.value);
                  }}
                  fullWidth
                  MenuProps={MenuProps}
                >
                  <MenuItem sx={{ minWidth: 200 }} value={"Kamu"}>
                    Kamu
                  </MenuItem>
                  <MenuItem sx={{ minWidth: 200 }} value={"Özel"}>
                    Özel
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            {firmTypeCheck === "Tüzel" ? (
              <TextField
                label="Firma Kısa Adı"
                placeholder="Firma Kısa Adı"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                {...register("firmName", {
                  required: "error message",
                  maxLength: 50,
                })}
              />
            ) : (
              <TextField
                label="Ad Soyad"
                placeholder="Ad Soyad"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                {...register("firmName", {
                  required: "error message",
                  maxLength: 50,
                })}
              />
            )}
            {errors.firmName && <p style={{ color: "red" }}>Hatalı İsim</p>}
          </FormControl>
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <TextField
              label="Unvan"
              placeholder="Unvan"
              variant="outlined"
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              {...register("title", {
                required: "error message",
                maxLength: 50,
              })}
            />
            {errors.title && (
              <p style={{ color: "red" }}>Hatalı Unvan Bilgisi</p>
            )}
          </FormControl>
          <FormControl>
            <TextField
              label="Vergi Dairesi"
              placeholder="Vergi Dairesi"
              variant="outlined"
              margin="normal"
              InputLabelProps={{ shrink: true }}
              fullWidth
              {...register("taxOffice", {
                required: "error message",
              })}
            />
            {errors.taxOffice && (
              <p style={{ color: "red" }}>Vergi Dairesi İsmini Giriniz</p>
            )}
          </FormControl>
          <FormControl>
            <TextField
              label="Vergi Kimlik Numarası"
              placeholder="Vergi Kimlik Numarası"
              variant="outlined"
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              {...register("taxIdentityNumber", {
                required: "error message",
                pattern: /^\d{10}$/,
              })}
            />
            {errors.taxIdentityNumber && (
              <p style={{ color: "red" }}>Hatalı Vergi Kimlik Numarası</p>
            )}
          </FormControl>
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 10,
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <TextField
              label="Mail Adresi"
              placeholder="Mail Adresi"
              variant="outlined"
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              {...register("email", {
                required: "error message",
                pattern: /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/,
              })}
            />
            {errors.email && <p style={{ color: "red" }}>Hatalı mail adresi</p>}
          </FormControl>
          <FormControl>
            <TextField
              label="Telefon Numarası"
              placeholder="5374753696"
              variant="outlined"
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              {...register("phoneNumber", {
                required: "Telegon Numarası Girin",
                maxLength: 20,
                pattern: /^5(0[5-7]|[3-5]\d)\d{3}\d{4}$/,
              })}
            />
            {errors.phoneNumber && (
              <p style={{ color: "red" }}>Hatalı Telefon Numarası</p>
            )}
          </FormControl>
          <FormControl>
            <TextField
              label="KEP Adresi"
              variant="outlined"
              margin="normal"
              {...register("legalEmailAddress")}
              fullWidth
            />
            {/* {errors.phoneNumber && <p style={{ color: "red" }}>Hatalı Telefon Numarası</p>} */}
          </FormControl>
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 3,
          }}
        >
          <Box sx={{ margin: "12px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>İl</InputLabel>
              <Controller
                name="city"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    id="city"
                    label="İl"
                    margin="normal"
                    fullWidth
                    value={value}
                    onBlur={onBlur}
                    MenuProps={MenuProps}
                    onChange={(e) => {
                      onChange(e);
                      handleAssetProvince(e);
                    }}
                  >
                    {city.map((c, index) => {
                      return (
                        <MenuItem
                          sx={{ minWidth: 180 }}
                          value={c.il}
                          key={index}
                        >
                          {c.il}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
            </FormControl>
          </Box>
          <Box sx={{ margin: "12px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>İlçe</InputLabel>
              <Controller
                name="district"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    id="district"
                    label="İlçe"
                    margin="normal"
                    fullWidth
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => {
                      onChange(e);
                      //setSelectedDistrict(e.target.value);
                      //setValue("district", e.target.value);
                    }}
                    MenuProps={MenuProps}
                  >
                    {district.map((countie, index) => {
                      return (
                        <MenuItem
                          sx={{ minWidth: 280 }}
                          value={countie}
                          key={index}
                        >
                          {countie}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
            </FormControl>
          </Box>
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: 3,
            marginBottom: 1
          }}
        >
          <FormControl>
            <TextField
              label="Adres"
              placeholder="Adres bilgisi giriniz"
              variant="outlined"
              margin="normal"
              InputLabelProps={{ shrink: true }}
              fullWidth
              {...register("address", {
                required: "error message",
                maxLength: 250,
              })}
            />
            {errors.address && (
              <p style={{ color: "red" }}>Hatalı Adres Bilgisi</p>
            )}
          </FormControl>
          <Button
            //className={classes.button}
            onClick={handleSubmit(handleSaveCustomer)}
            variant="outlined"
            size="large"
            color="primary"
          >
            Müşteri Kayıt
          </Button>
        </Box>
        <ToastContainer />
      </div>
    </>
  );
};

const ContractContent = () => {
  const {
    register,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  const [autoUpdateContract, setAutoUpdateContract] = useState(false);
  const [term, setTerm] = useState(false);
  //const [contractFeeUpdateMetric, setContractFeeUpdateMetric] = useState("");
  const [constantRate, setConstantRate] = useState(false);
  const [constantAmount, setConstantAmount] = useState(false);
  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: 3,
            margin: "6px",
          }}
        >
          <FormControl>
            <TextField
              id="outlined-textarea"
              label="Konusu"
              placeholder="Lütfen Belge konusunu bu kısma metin olarak ekleyiniz. "
              multiline
              //value={getValues.value}
              inputProps={{
                style: {
                  height: "150px",
                },
              }}
              fullWidth
              {...register("basicContractTopic", {
                maxLength: 10000,
              })}
            />
            {errors.basicContractTopic && (
              <p style={{ color: "red" }}>
                Karakter Limiti aşımı.Maksimum 10000 karakter giriniz.{" "}
              </p>
            )}
          </FormControl>
        </Box>
      </div>
      {/* <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: 3,
            margin: "6px",
          }}
        >
          <FormControl>
            <TextField
              id="outlined-textarea"
              label="Gizlilik Koşulları"
              placeholder="Lütfen Gizlilik Koşullarını bu kısma metin olarak ekleyiniz. "
              multiline
              inputProps={{
                style: {
                  height: "150px",
                },
              }}
              fullWidth
              {...register("privacy", {
                maxLength: 400,
              })}
            />
            {errors.privacy && (
              <p style={{ color: "red" }}>
                Karakter Limiti aşımı.Maksimum 400 karakter giriniz.
              </p>
            )}
          </FormControl>
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: 3,
            margin: "6px",
          }}
        >
          <FormControl>
            <TextField
              id="outlined-textarea"
              label="Cezai Yaptırımlar"
              placeholder="Lütfen Cezai Yaptırımlarınızı bu kısma metin olarak ekleyiniz. "
              multiline
              inputProps={{
                style: {
                  height: "150px",
                },
              }}
              fullWidth
              {...register("criminalSanctions", {
                maxLength: 400,
              })}
            />
            {errors.criminalSanctions && (
              <p style={{ color: "red" }}>
                Karakter Limiti aşımı.Maksimum 400 karakter giriniz.
              </p>
            )}
          </FormControl>
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: 3,
            margin: "6px",
          }}
        >
          <FormControl>
            <TextField
              id="outlined-textarea"
              label="Diğer"
              placeholder="Lütfen belgenize ait eklemek istediğiniz şartları bu alana girin."
              multiline
              inputProps={{
                style: {
                  height: "150px",
                },
              }}
              fullWidth
              {...register("otherSanctions", {
                maxLength: 400,
              })}
            />
            {errors.otherSanctions && (
              <p style={{ color: "red" }}>
                Karakter Limiti aşımı.Maksimum 400 karakter giriniz.
              </p>
            )}
          </FormControl>
        </Box>
      </div> */}
    </>
  );
};

const PaymentInformations = () => {
  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [monthlyPayment, setMonthlyPayment] = useState(false);

  const menuItems = [];

  for (let i = 1; i <= 31; i++) {
    menuItems.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }

  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  useEffect(() => {
    if (getValues("monthlyPayment") === "Evet") {
      setMonthlyPayment(true);
      setValue("paymentDate", "");
    } else if (getValues("monthlyPayment") === "Hayır") {
      setMonthlyPayment(false);
      setValue("paymentDay", "");
    }
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box
          align="center"
          sx={{
            display: "grid",
            rowGap: 3,
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 3,
          }}
        >
          <FormControl fullWidth variant="outlined">
            <TextField
              type="number"
              label="Anlaşma Tutarı (KDV Hariç)"
              placeholder="Anlaşma Tutarı (KDV Hariç)"
              variant="outlined"
              fullWidth
              {...register("contractFee", {
                required: false,
              })}
            />

            {errors.contractFee && (
              <p style={{ color: "red" }}>Hatalı Anlaşma Tutarı Girişi</p>
            )}
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Para Birimi</InputLabel>
            <Controller
              name="currency"
              control={control}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="currency"
                  label="Kur Bilgisi"
                  margin="normal"
                  fullWidth
                  MenuProps={MenuProps}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                >
                  <MenuItem sx={{ minWidth: 200 }} value={"TL"}>
                    TL ₺
                  </MenuItem>
                  <MenuItem sx={{ minWidth: 200 }} value={"Dolar"}>
                    Dolar $
                  </MenuItem>
                  <MenuItem sx={{ minWidth: 200 }} value={"Euro"}>
                    Euro €{" "}
                  </MenuItem>
                  <MenuItem sx={{ minWidth: 200 }} value={"Sterlin"}>
                    Sterlin £
                  </MenuItem>
                  <MenuItem sx={{ minWidth: 200 }} value={"other"}>
                    Diğer
                  </MenuItem>
                </Select>
              )}
            />
            {errors.currency && (
              <p style={{ color: "red" }}>Lütfen Para Birimi Girin</p>
            )}
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Ödeme Tipi</InputLabel>
            <Controller
              name="paymentType"
              control={control}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  id="paymentType"
                  label="Ödeme Tipi"
                  margin="normal"
                  fullWidth
                  MenuProps={MenuProps}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                >
                  <MenuItem sx={{ minWidth: 200 }} value={"Nakit"}>
                    Nakit
                  </MenuItem>
                  <MenuItem sx={{ minWidth: 200 }} value={"Kredi Kartı"}>
                    Kredi Kartı
                  </MenuItem>
                  <MenuItem sx={{ minWidth: 200 }} value={"Havale/EFT"}>
                    Havale / EFT
                  </MenuItem>
                  <MenuItem sx={{ minWidth: 200 }} value={"Çek"}>
                    Çek
                  </MenuItem>
                  <MenuItem sx={{ minWidth: 200 }} value={"Senet"}>
                    Senet
                  </MenuItem>
                </Select>
              )}
            />
            {errors.paymentType && (
              <p style={{ color: "red" }}>Lütfen ödeme tipi girin</p>
            )}
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField
              type="number"
              label="Kur"
              placeholder="Kur"
              variant="outlined"
              fullWidth
              {...register("currencyRatio", {
                required: false,
              })}
            />
            {errors.contractFee && (
              <p style={{ color: "red" }}>Hatalı Kur bilgisi</p>
            )}
          </FormControl>
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: 3,
          }}
        >
          <Box sx={{ margin: "12px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">
                Aylık Tekrar eden ödeme mi?
              </InputLabel>
              <Controller
                name="monthlyPayment"
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    id="monthlyPayment"
                    label="Otomatik Yenilensin mi?"
                    margin="normal"
                    fullWidth
                    onBlur={onBlur}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      if (e.target.value === "Evet") {
                        setMonthlyPayment(true);
                        setValue("paymentDate", "");
                      } else if (e.target.value === "Hayır") {
                        setMonthlyPayment(false);
                        setValue("paymentDay", "");
                      } else {
                      }
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem sx={{ minWidth: 200 }} value={"Evet"}>
                      Evet
                    </MenuItem>
                    <MenuItem sx={{ minWidth: 200 }} value={"Hayır"}>
                      Hayır
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Box>
        </Box>
      </div>
      {monthlyPayment ? (
        <div style={{ width: "100%" }}>
          <Box
            sx={{
              display: "grid",
              rowGap: 5,
              gridTemplateColumns: "repeat(1, 1fr)",
              gap: 3,
              margin: "6px",
            }}
          >
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">Ödeme Günü</InputLabel>
              <Controller
                name="paymentDay"
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    id="paymentDay"
                    label="Ödeme Günü"
                    margin="normal"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    MenuProps={MenuProps}
                  >
                    {menuItems}
                  </Select>
                )}
              />
              {errors.paymentDay && (
                <p style={{ color: "red" }}>
                  Lütfen Ödeme Günü Bilgisi Giriniz
                </p>
              )}
            </FormControl>
          </Box>
        </div>
      ) : (
        <></>
      )}
      {!monthlyPayment ? (
        <div style={{ width: "100%" }}>
          <Box
            sx={{
              display: "grid",
              rowGap: 5,
              gridTemplateColumns: "repeat(1, 1fr)",
              gap: 3,
              margin: "6px",
            }}
          >
            <FormControl fullWidth variant="outlined">
              <TextField
                id="payment-date"
                type="date"
                label="Ödeme Vadesi"
                variant="outlined"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                {...register("paymentDate", {
                  required: "error message",
                })}
              />
              {errors.paymentDate && (
                <p style={{ color: "red" }}>
                  Lütfen Ödeme Vadesi Bilgisi Giriniz
                </p>
              )}
            </FormControl>
          </Box>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const ContractDraft = () => {
  const [userTemplateList, setUserTemplateList] = useState();
  const {
    getValues,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 150 },
    },
  };
  const watchedValues = watch("documentType");

  async function informationForDraft(documentType) {
    await axios
      .get(
        `api/contract-template/get/${getValues(
          "userId"
        )}/${documentType}`
      )
      .then((res) => {
        setUserTemplateList(res.data);
        if (!res.data.find((s) => s.id === getValues("templateId")))
          setValue("templateId", "");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    let documentType = getValues("documentType");
    if (
      documentType &&
      documentType !== null &&
      documentType !== "" &&
      documentType > 0
    )
      informationForDraft(documentType);
  }, [watchedValues]);

  return (
    <>
      <Box
        component="form"
        sx={{
          width: "50%",
          margin: "auto",
          rowGap: 5,
          gap: 3,
          display: "grid",
        }}
        noValidate
        align="center"
        autoComplete="off"
      >
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-helper-label">
            Belge Tipi
          </InputLabel>
          <Controller
            name="documentType"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                labelId="l1"
                id="documentType"
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e);
                }}
                value={value}
                label="Belge Tipi"
                margin="normal"
                //variant="outlined"
                fullWidth
                MenuProps={MenuProps}
              >
                <MenuItem sx={{ minWidth: 200 }} value={1}>
                  Sözleşme
                </MenuItem>
                <MenuItem sx={{ minWidth: 200 }} value={2}>
                  Teklif
                </MenuItem>
                <MenuItem sx={{ minWidth: 200 }} value={3}>
                  Fatura
                </MenuItem>
              </Select>
            )}
          />
          {errors.documentType && (
            <p style={{ color: "red" }}>Lütfen Belge Tipi Giriniz</p>
          )}
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="templateId">Belge Şablonu</InputLabel>
          <Controller
            name="templateId"
            control={control}
            rules={{
              required: "Belge Şablonu Seçin",
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                id="templateId"
                label="Belge Şablonu"
                value={value}
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                MenuProps={MenuProps}
              >
                {userTemplateList ? (
                  userTemplateList.map((template, index) => {
                    return (
                      <MenuItem
                        sx={{ minWidth: 200 }}
                        value={template.id}
                        key={index}
                      >
                        {template.contractName}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem></MenuItem>
                )}
              </Select>
            )}
          />
          {errors.templateId && (
            <p style={{ color: "red" }}>Lütfen Belge Şablonu Seçin</p>
          )}
        </FormControl>
        {errors.contractName && (
          <p style={{ color: "red" }}>Lütfen Belge Şablonu Seçiniz</p>
        )}
      </Box>
    </>
  );
};

const ShowAllInformation = () => {
  const {
    getValues,
  } = useFormContext();

  return <BasicContractTransform basicContractInformations={getValues()} />;
};

const ShowContractPreView = () => {
  const { getValues } = useFormContext();



  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <ContractPreView contractInformations={getValues()} />

    </Box>
  );
};
