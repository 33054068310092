import { Button, Step, StepLabel, Stepper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cities from "../Data/cities";
import EInvoiceContractTransform from "./EInvoiceContractTransform";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Sözleşme Şablonu",
    "Firma Bilgileri",
    "Sözleşme Süresi",
    "Hizmet Bedeli",
    "Ödeme Bilgileri",
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <ContractDraft />;
    case 1:
      return <FirmInformations />;
    case 2:
      return <ContractDuration />;
    case 3:
      return <ServicePriceInformations />;
    case 4:
      return <PaymentInformations />;
    default:
      return "unknown step";
  }
}

const FirmInformations = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [district, setDistrict] = useState([]);
  const [firmTypeCheck, setFirmTypeCheck] = useState("false");

  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 4;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  const handleAssetProvince = (e) => {
    const counties = cities.filter((obj) => {
      return obj.il === e.target.value;
    });

    setDistrict(counties[0]["ilceleri"]);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 3,
          }}
        >
          <FormControl fullWidth variant="outlined">
            <InputLabel id="demo-simple-select-label">
              Firma Kişi Tipi
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper-label"
              label="Firma Kişi Tipi"
              margin="normal"
              fullWidth
              {...register("firmType", {
                required: "error message",
              })}
              onChange={(e) => {
                console.log(e.target.value);
                if (e.target.value === "Tüzel") {
                  setFirmTypeCheck(true);
                } else {
                  setFirmTypeCheck(false);
                }
              }}
              MenuProps={MenuProps}
            >
              <MenuItem sx={{ minWidth: 200 }} value={"Tüzel"}>
                Tüzel
              </MenuItem>
              <MenuItem sx={{ minWidth: 200 }} value={"Gerçek"}>
                Gerçek
              </MenuItem>
            </Select>
            {/* {errors.firmType && <p style={{ color: "red" }}>Lütfen Firma Tipini Seçiniz</p>} */}
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <InputLabel id="demo-simple-select-label">Firma Tipi</InputLabel>
            {firmTypeCheck ? (
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="Firma Tipi"
                margin="normal"
                fullWidth
                {...register("publicOrPrivateFirm", {
                  required: "error message",
                })}
                MenuProps={MenuProps}
              >
                <MenuItem sx={{ minWidth: 200 }} value={"Kamu"}>
                  Kamu
                </MenuItem>
                <MenuItem sx={{ minWidth: 200 }} value={"Özel"}>
                  Özel
                </MenuItem>
              </Select>
            ) : (
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="Firma Tipi"
                fullWidth
                {...register("publicOrPrivateFirm", {
                  required: "error message",
                })}
                MenuProps={MenuProps}
              >
                <MenuItem sx={{ minWidth: 200 }} defaultValue={" "}></MenuItem>
              </Select>
            )}
          </FormControl>
          <FormControl fullWidth variant="outlined">
            {firmTypeCheck ? (
              <TextField
                label="Firma Adı"
                placeholder="Firma Adı"
                variant="outlined"
                fullWidth
                {...register("firmName", {
                  required: "error message",
                })}
              />
            ) : (
              <TextField
                label="Ad Soyad"
                placeholder="Ad Soyad"
                variant="outlined"
                fullWidth
                {...register("firmName", {
                  required: "error message",
                })}
              />
            )}
            {errors.firmName && <p style={{ color: "red" }}>Hatalı İsim</p>}
          </FormControl>
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <TextField
              label="Unvan"
              placeholder="Unvan"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("title", {
                required: "error message",
                maxLength: 20,
              })}
            />
            {errors.title && (
              <p style={{ color: "red" }}>Hatalı Unvan Bilgisi</p>
            )}
          </FormControl>
          <FormControl>
            <TextField
              label="Vergi Dairesi"
              placeholder="Vergi Dairesi"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("taxAdminstration", {
                required: "error message",
              })}
            />
            {errors.taxAdminstration && (
              <p style={{ color: "red" }}>Vergi Dairesi İsmini Giriniz</p>
            )}
          </FormControl>
          <FormControl>
            <TextField
              label="Vergi Kimlik Numarası"
              placeholder="Vergi Kimlik Numarası"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("taxID", {
                required: "error message",
                maxLength: 11,
                pattern: /^\d{10}$/,
              })}
            />
            {errors.taxID && (
              <p style={{ color: "red" }}>Hatalı Vergi Kimlik Numarası</p>
            )}
          </FormControl>
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 10,
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <TextField
              label="Mail Adresi"
              placeholder="Mail Adresi"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("email", {
                required: "error message",
                pattern: /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/,
              })}
            />
            {errors.email && <p style={{ color: "red" }}>Hatalı mail adresi</p>}
          </FormControl>
          <FormControl>
            <TextField
              label="Telefon Numarası"
              placeholder="5374753696"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("phoneNumber", {
                required: "error message",
                maxLength: 20,
                pattern: /^5(0[5-7]|[3-5]\d)\d{3}\d{4}$/,
              })}
            />
            {errors.phoneNumber && (
              <p style={{ color: "red" }}>Hatalı Telefon Numarası</p>
            )}
          </FormControl>
          <FormControl>
            <TextField
              label="Faks Numarası"
              placeholder="5374753696"
              variant="outlined"
              margin="normal"
              fullWidth
              // {...register("phoneNumber", {
              //     required: 'error message',
              //     maxLength: 20,
              //     pattern: /^5(0[5-7]|[3-5]\d)\d{3}\d{4}$/
              // })}
            />
            {/* {errors.phoneNumber && <p style={{ color: "red" }}>Hatalı Telefon Numarası</p>} */}
          </FormControl>
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 3,
          }}
        >
          <Box sx={{ margin: "12px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">İl</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="İl"
                margin="normal"
                fullWidth
                {...register("city")}
                MenuProps={MenuProps}
                onChange={(e) => {
                  handleAssetProvince(e);
                }}
              >
                {cities.map((c, index) => {
                  return (
                    <MenuItem key={index} sx={{ minWidth: 180 }} value={c.il}>
                      {c.il}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ margin: "12px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">İlçe</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="İlçe"
                margin="normal"
                fullWidth
                {...register("district")}
                MenuProps={MenuProps}
              >
                {district.map((countie, index) => {
                  return (
                    <MenuItem
                      key={index}
                      sx={{ minWidth: 280 }}
                      value={countie}
                    >
                      {countie}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 5,
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <TextField
              label="Adres"
              placeholder="Adres bilgisi giriniz"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("address", {
                required: "error message",
                maxLength: 150,
              })}
            />
            {errors.address && (
              <p style={{ color: "red" }}>Hatalı Adres Bilgisi</p>
            )}
          </FormControl>
        </Box>
      </div>
    </>
  );
};

const ContractDuration = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const menuItems = [];

  for (let i = 1; i <= 31; i++) {
    menuItems.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }

  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  return (
    <>
      <Box
        component="form"
        sx={{ width: "50%", margin: "auto" }}
        noValidate
        align="center"
        autoComplete="off"
      >
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-label">
            Sözleşme Periyodu
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper-label"
            label="Sözleşme Periyodu"
            margin="normal"
            fullWidth
            {...register("contractPeriod", {
              required: "error message",
            })}
            MenuProps={MenuProps}
          >
            <MenuItem sx={{ minWidth: 200 }} value={"Günlük"}>
              Günlük
            </MenuItem>
            <MenuItem sx={{ minWidth: 200 }} value={"Aylık"}>
              Aylık
            </MenuItem>
            <MenuItem sx={{ minWidth: 200 }} value={"Yıllık"}>
              Yıllık
            </MenuItem>
            <MenuItem sx={{ minWidth: 200 }} value={"Tek Seferlik"}>
              Tek Seferlik
            </MenuItem>
          </Select>
        </FormControl>
        {errors.contractPeriod && (
          <p style={{ color: "red" }}>Lütfen Kontrat Periyodu Seçiniz</p>
        )}

        <Box sx={{ margin: "12px 0px 0px 0px" }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="demo-simple-select-label">
              Sözleşme Periyot Zamanı
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper-label"
              label="Sözleşme Periyot Zamanı"
              margin="normal"
              fullWidth
              {...register("periodValue", {
                required: "error message",
              })}
              MenuProps={MenuProps}
            >
              {menuItems}
            </Select>
          </FormControl>
          {errors.periodValue && (
            <p style={{ color: "red" }}>Lütfen Kontrat Periyodu Seçiniz</p>
          )}
        </Box>

        <TextField
          id="contract-start-date"
          type="date"
          label="Sözleşme Başlangıç Tarihi"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          {...register("contractStartDate", {
            required: "error message",
          })}
        />
        {errors.contractStartDate && (
          <p style={{ color: "red" }}>Sözleşme Başlangıç Tarihi Giriniz</p>
        )}

        <TextField
          id="contract-start-date"
          type="date"
          label="Sözleşme Bitiş Tarihi"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          {...register("contractFinishDate", {
            required: "error message",
          })}
        />
        {errors.contractStartDate && (
          <p style={{ color: "red" }}>Sözleşme Bitiş Tarihi Giriniz</p>
        )}
      </Box>
    </>
  );
};

const ServicePriceInformations = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box
          align="center"
          sx={{
            display: "grid",
            rowGap: 3,
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <TextField
              id="service-fee"
              type="number"
              label="Hizmet Ücreti"
              variant="outlined"
              fullWidth
              {...register("serviceFee", {
                required: "error message",
              })}
            />
            {errors.serviceFee && (
              <p style={{ color: "red" }}>Hizmet Ücreti Giriniz</p>
            )}
          </FormControl>
          <FormControl>
            <InputLabel id="demo-simple-select-label">
              Hizmet Ücreti Kur Bilgisi
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper-label"
              label="Sözleşme Kur Bilgisi"
              {...register("currency", {
                required: "error message",
              })}
              MenuProps={MenuProps}
            >
              <MenuItem sx={{ minWidth: 200 }} value={"TL"}>
                TL ₺
              </MenuItem>
              <MenuItem sx={{ minWidth: 200 }} value={"Dolar"}>
                Dolar $
              </MenuItem>
              <MenuItem sx={{ minWidth: 200 }} value={"Euro"}>
                Euro €{" "}
              </MenuItem>
              <MenuItem sx={{ minWidth: 200 }} value={"Sterlin"}>
                Sterlin £
              </MenuItem>
            </Select>
            {errors.currency && (
              <p style={{ color: "red" }}>Lütfen Kur Bilgisini Giriniz</p>
            )}
          </FormControl>
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <Box
          align="center"
          sx={{
            display: "grid",
            rowGap: 3,
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 3,
          }}
        >
          <FormControl>
            <TextField
              id="monthly-document-limit"
              type="number"
              label="Aylık Belge Üst Limiti"
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("monthlyDocumentLimit", {
                required: "error message",
              })}
            />
            {errors.monthlyDocumentLimit && (
              <p style={{ color: "red" }}>Aylık Belge Üst Limiti Giriniz</p>
            )}
          </FormControl>
          <FormControl>
            <TextField
              id="over-limit-fee"
              type="number"
              label="Limit Aşım Ücreti"
              variant="outlined"
              margin="normal"
              fullWidth
              defaultValue="0.1"
              {...register("overLimitFee", {
                required: "error message",
              })}
            />
            {errors.monthlyDocumentLimit && (
              <p style={{ color: "red" }}>Aşım Ücreti Giriniz</p>
            )}
          </FormControl>
          <Box sx={{ margin: "15px 0px 0px 0px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">
                Hizmet Fiyatı Güncelleme Bilgisi
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper-label"
                label="Hizmet Ücreti Güncelleme Bilgisi"
                {...register("serviceFeeUpdate", {
                  required: "error message",
                })}
                MenuProps={MenuProps}
              >
                <MenuItem sx={{ minWidth: 200 }} value={"TEFE-TÜFE"}>
                  TEFE-TÜFE Ortalaması
                </MenuItem>
                <MenuItem sx={{ minWidth: 200 }} value={"TEFE"}>
                  TEFE (Toptan Eşya Fiyat Endeksi)
                </MenuItem>
                <MenuItem sx={{ minWidth: 200 }} value={"TÜFE"}>
                  TÜFE (Tüketici fiyat endeksi)
                </MenuItem>
                <MenuItem sx={{ minWidth: 200 }} value={"DövizArtışOranı"}>
                  Döviz Kuru Artış Oranı
                </MenuItem>
              </Select>
            </FormControl>
            {errors.serviceFeeUpdate && (
              <p style={{ color: "red" }}>
                Lütfen Fiyat Güncelleme Bilgisi Giriniz
              </p>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
};

const PaymentInformations = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Box
        component="form"
        sx={{ width: "50%", margin: "auto" }}
        noValidate
        align="center"
        autoComplete="off"
      >
        <TextField
          id="contract-start-date"
          label="Fatura Ödeme Vadesi"
          variant="outlined"
          fullWidth
          margin="normal"
          {...register("invoiceFeePeriod", {
            required: "error message",
          })}
        />
        {errors.invoiceFeePeriod && (
          <p style={{ color: "red" }}>Fatura Ödeme Vadesi Giriniz</p>
        )}

        <TextField
          id="contract-start-date"
          label="Fatura Ödeme Gecikmesi Vade Farkı Oranı"
          variant="outlined"
          fullWidth
          margin="normal"
          {...register("invoiceDelayPaymentRate", {
            required: "error message",
          })}
        />
        {errors.invoiceDelayPaymentRate && (
          <p style={{ color: "red" }}>
            Fatura Ödeme Gecikme Vade Farkı Oranı Giriniz
          </p>
        )}
      </Box>
    </>
  );
};

const ContractDraft = () => {
  const [userTemplateList, setUserTemplateList] = useState();

  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  const ITEM_HEIGHT = 88;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  let userId = getValues("userId");
  let contractTypeId = getValues("contractTypeId");

  async function informationForDraft() {
    await axios
      .get(`api/contract-template/get/${userId}/${contractTypeId}`)
      .then((res) => {
        setUserTemplateList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    informationForDraft();
  }, []);

  return (
    <>
      <Box
        component="form"
        sx={{ width: "50%", margin: "auto" }}
        noValidate
        align="center"
        autoComplete="off"
      >
        <FormControl fullWidth variant="outlined">
          <InputLabel id="test-select-label">Kontrat Şablonu</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper-label"
            label="Kontrat Şablonu"
            {...register("contractName", {
              required: "error message",
            })}
            fullWidth
            MenuProps={MenuProps}
          >
            {userTemplateList ? (
              userTemplateList.map((template, index) => {
                return (
                  <MenuItem
                    key={index}
                    sx={{ minWidth: 200 }}
                    value={template.contractName}
                  >
                    {template.contractName}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem></MenuItem>
            )}
          </Select>
        </FormControl>
        {errors.contractName && (
          <p style={{ color: "red" }}>Lütfen Sözleşme Şablonu Seçiniz</p>
        )}

        {/* <form>
                    <input type="file" name="file_upload"  onChange={uploadFileHandler} />
                    <button type='submit'>Upload</button>
                </form>
                 <form onChange={uploadFileHandler}>
                    <input ref={register} type="file" name="attachment" />
                    <button>Submit</button>
                </form> */}
      </Box>
    </>
  );
};

const EInvoiceContractLinearStepper = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.security.user);
  const userId = user.id;
  let contractDateNow = new Date().toLocaleDateString();

  const methods = useForm({
    defaultValues: {
      firmName: "",
      fullName: "",
      firmType: "Tüzel",
      publicOrPrivateFirm: "Özel",
      taxID: "",
      title: "",
      phoneNumber: "",
      email: "",
      taxAdminstration: "",
      city: "",
      district: "",
      address: "",

      contractPeriod: "",
      periodValue: "",
      contractStartDate: "",
      contractFinishDate: "",

      serviceFee: "",
      currency: "",
      monthlyDocumentLimit: 0,
      overLimitFee: 0,
      serviceFeeUpdate: "",

      invoiceFeePeriod: "",
      invoiceDelayPaymentRate: 0,

      contractDateNow: contractDateNow,

      contractName: "",
      contractTypeId: 1,
      userId: userId,

      attachment: {},
    },
  });

  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();

  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };
  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = (data) => {
    if (activeStep === steps.length - 1) {
      setActiveStep(activeStep + 1);
      toast.info("Sözleşme Bilgilerinizi Kontrol Ediniz", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          // if (isStepOptional(index)) {
          //     labelProps.optional = (
          //         <Typography
          //             variant="caption"
          //             align="center"
          //             style={{ display: "block" }}
          //         >
          //             Opsiyonel
          //         </Typography>
          //     );
          // }
          if (isStepFalied() && activeStep === index) {
            labelProps.error = true;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <div>
          <ToastContainer />
          <EInvoiceContractTransform
            eInvoiceServiceContractInformation={methods.getValues()}
          />
        </div>
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleNext)}>
              {getStepContent(activeStep)}
              <Box
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "75ch" },
                }}
                noValidate
                align="right"
                autoComplete="off"
              >
                <Button
                  className={classes.button}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  variant="outlined"
                  size="large"
                  color="primary"
                >
                  geri
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  size="large"
                  color="primary"
                  // onClick={handleNext}
                  type="submit"
                >
                  {activeStep === steps.length - 1 ? "Tamamla" : "İleri"}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};

export default EInvoiceContractLinearStepper;
