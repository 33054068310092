import React, { useState, useEffect } from "react";
import "./ContractTypes.css";
import ContractCards from "./ContractCards";
import { useSelector } from "react-redux";
import axios from "axios";

const BasicContracts = () => {
  const [items, setItems] = useState();
  const user = useSelector((state) => state.security.user);
  const userId = user.id;
  let contractType = "BasicContract";

  async function getContractCards(contractType, userId) {
    await axios
      .get(`api/contract/get-contract-cards/${contractType}/${userId}`)
      .then((res) => {
        console.log(res.data);
        setItems(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getContractCards(contractType, userId);
  }, [contractType, userId]);

  return (
    <>
      {items ? (
        <section className="blog top">
          <div className="container">
            <div className="content grid">
              {items.map((item, index) => {
                return <ContractCards item={item} key={index} />;
              })}
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default BasicContracts;
