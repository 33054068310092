import axios from 'axios';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ResetPassword.css';

const ResetPassword = () => {
  const [step, setStep] = useState(1);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [email, setEmail] = useState('');
  const [captchaError, setCaptchaError] = useState(false);
  const { register, handleSubmit, reset, formState: { errors }, watch } = useForm();
  const history = useHistory();
  const recaptchaRef = useRef(null);

  const handleEmailSubmit = async (data) => {
    if (!captchaValue) {
      setCaptchaError(true);
      return;
    }
    
    setCaptchaError(false);

    try {
      const response = await axios.post('/api/users/send-reset-password-email', {
        email: data.email,
        captcha: captchaValue,
      });
      
      if (response.data.code === "100") {
        toast.success('E-posta gönderildi');
        setEmail(data.email);
        setStep(2);
        reset();
      } else {
        toast.error('Mail gönderimi esnasında hata oluştu, yeniden deneyin.');
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error('Mail gönderimi esnasında hata oluştu, yeniden deneyin.');
    } finally {
      recaptchaRef.current.reset();
      setCaptchaValue(null);
    }
  };

  const handlePasswordSubmit = async (data) => {
    if (!captchaValue) {
      setCaptchaError(true);
      return;
    }

    setCaptchaError(false);

    try {
      const response = await axios.post('/api/users/verify-reset-password-code', {
        email: email,
        verificationCode: data.verificationCode,
        newPassword: data.newPassword,
        captcha: captchaValue,
        confirmPassword: data.confirmPassword,
      });
      
      if (response.data.code === "100") {
        toast.success('Şifreniz başarıyla güncellendi.');
        history.push('/login');
      } else if (response.data.code === "101") {
        toast.error('Şifre güncelleme esnasında hata oluştu.');
        console.error(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error('Şifre güncelleme esnasında hata oluştu.');
    } finally {
      recaptchaRef.current.reset();
      setCaptchaValue(null);
    }
  };

  return (
    <div className="remember-container">
      <ToastContainer />
      <h2 className="remember-header">Şifremi Unuttum</h2>
      {step === 1 ? (
        <form onSubmit={handleSubmit(handleEmailSubmit)}>
          <div>
            <label htmlFor="email">E-posta Adresi:</label>
            <input
              type="email"
              {...register('email', { required: true, pattern: /^\S+@\S+\.\S+$/ })}
              placeholder="E-posta adresinizi girin"
            />
            {errors.email && <span style={{ color: 'red' }}>Geçersiz e-posta adresi</span>}
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SECRET}
            onChange={setCaptchaValue}
          />
          {captchaError && <span style={{ color: 'red' }}>Robot olmadığınızı işaretleyin</span>}
          <button className='remember-button' type="submit">Kod Gönder</button>
        </form>
      ) : (
        <form onSubmit={handleSubmit(handlePasswordSubmit)}>
          <div>
            <label htmlFor="verificationCode">Doğrulama Kodu:</label>
            <input
              type="text"
              autoComplete="off"
              {...register('verificationCode', { required: true })}
              placeholder="Doğrulama kodunu girin"
            />
            {errors.verificationCode && <span style={{ color: 'red' }}>Kodu girmeniz gerekiyor</span>}
          </div>
          <div>
            <label htmlFor="newPassword">Yeni Şifre:</label>
            <input
              type="password"
              autoComplete="off"
              {...register('newPassword', { 
                required: true, 
                minLength: { value: 8, message: 'Şifre en az 8 karakter olmalıdır.' },
                validate: {
                  hasNumber: value => /[0-9]/.test(value) || 'Şifre en az bir rakam içermelidir.',
                  hasSpecialChar: value => /[!@#$%^&*]/.test(value) || 'Şifre en az bir özel karakter içermelidir.'
                }
              })}
              placeholder="Yeni şifrenizi girin"
            />
            {errors.newPassword && <span style={{ color: 'red' }}>{errors.newPassword.message}</span>}
          </div>
          <div>
            <label htmlFor="confirmPassword">Yeni Şifre Tekrar:</label>
            <input
              type="password"
              autoComplete="off"
              {...register('confirmPassword', { 
                validate: value => value === watch('newPassword') || 'Şifreler eşleşmiyor' 
              })}
              placeholder="Yeni şifrenizi tekrar girin"
            />
            {errors.confirmPassword && <span style={{ color: 'red' }}>{errors.confirmPassword.message}</span>}
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SECRET}
            onChange={setCaptchaValue}
          />
          {captchaError && <span style={{ color: 'red' }}>Robot olmadığınızı işaretleyin</span>}
          <button type="submit">Şifreyi Sıfırla</button>
        </form>
      )}
    </div>
  );
};

export default ResetPassword;