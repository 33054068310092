import React from "react"
import "./About.css"
import { Link } from "react-router-dom";

const AboutCard = () => {
  return (
    <>
      <div className='aboutCard mtop flex_space'>
        <div className='row row1'>
          <h4>Diji Söz Hakkında</h4>
          <h1>
            Yeni Nesil <span>Dijital Sözleşme </span> oluşturma platformu
          </h1>
          <p>Uluslararası standartlarda dijitalleşme sürecine katkı sağlamak ve yapay zeka teknikleri ile karar verme sistemlerini desteklemek önde gelen hedeflerimiz arasındadır.</p>
          <p>Firmaların fiziki ortamda yürüttüğü süreçlerini uluslararası standartlarla ve ileri teknolojilerle dijital ortama taşıyarak güvenirliği ve süreç otomasyonunu sağlamak,toplanan verileri yapay zeka/istatistiksel modelleme teknikleri ile analiz ederek gerçek ve tüzel kişilere yönelik güven endeksleri oluşturmak hedeflerimiz arasında yer almaktadır.</p>
          <a href="/about">
            <Link to="/about" style={{ color: 'white' }}>
              <button className='secondary-btn'>
                Hakkımızda <i className='fas fa-long-arrow-alt-right'></i>
              </button>
            </Link>
          </a>
        </div>
        <div >
          <img src='/images/about-img-1.jpg' alt='' />
        </div>
      </div>
    </>
  )
}

export default AboutCard
