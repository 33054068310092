import * as Validation from "yup";
import { CompanyInfo, CompanyInfoSchema } from "./CompanyInfo";
import { UserInfo, UserInfoSchema } from "./UserInfo";

export interface CompanySubscriptionRequest {
  company: CompanyInfo;
  representative: UserInfo;
  captcha: string | null;
}

export class CompanySubscriptionRequest implements CompanySubscriptionRequest {
  company: CompanyInfo;
  representative: UserInfo;
  captcha: string | null;

  constructor(company: CompanyInfo, representative: UserInfo, captcha: string) {
    this.company = company;
    this.representative = representative;
    this.captcha = captcha;
  }

  // create metodunu ekliyoruz
  static create(): CompanySubscriptionRequest {
    return new CompanySubscriptionRequest(
      CompanyInfo.create(),      // CompanyInfo'nun create metodunu çağırıyoruz
      UserInfo.create(),         // UserInfo'nun create metodunu çağırıyoruz
      ''                         // captcha'yı boş bir değer ile başlatıyoruz
    );
  }
}

export const CompanySubscriptionRequestSchema = (t: Function) => 
  Validation.object().shape({
  company: CompanyInfoSchema(t),        // CompanyInfo'nun doğrulama şeması
  representative: UserInfoSchema(t),    // UserInfo'nun doğrulama şeması
  captcha: Validation.string().nullable()
  .default(null)
  .transform((value) => value === undefined ? null : value),  // Captcha alanı da doğrulanmalı
});