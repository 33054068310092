import { TextField } from '@mui/material';
import React from 'react';
import { useController } from 'react-hook-form';

const PhoneInput = ({ control, name }) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules: {
      required: "Telefon numarası gereklidir",
      validate: (value) => {
        const digits = value.replace(/\D/g, '');
        return digits.length === 10 || "Geçerli bir telefon numarası giriniz (10 rakam)";
      }
    },
  });

  const handleChange = (event) => {
    let input = event.target.value.replace(/\D/g, ''); // Sadece rakamları al
    
    // Eğer ilk rakam 5 değilse, 5 ekle
    if (input.charAt(0) !== '5') {
      input = '5' + input.substr(input.charAt(0) === '5' ? 1 : 0);
    }
    
    // Maksimum 10 rakamla sınırla
    input = input.substr(0, 10);
    
    let formatted = '';

    if (input.length > 0) {
      formatted += '(' + input.substr(0, 3);
      if (input.length > 3) {
        formatted += ') ' + input.substr(3, 3);
        if (input.length > 6) {
          formatted += ' ' + input.substr(6, 2);
          if (input.length > 8) {
            formatted += ' ' + input.substr(8);
          }
        }
      }
    }

    onChange(formatted);
  };

  // İlk render'da ve value boş olduğunda default değeri set et
  React.useEffect(() => {
    if (!value) {
      onChange('(5');
    }
  }, [value, onChange]);

  return (
    <TextField
      label="Telefon Numarası"
      placeholder="(5XX) XXX XX XX"
      variant="outlined"
      margin="normal"
      fullWidth
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      inputRef={ref}
      error={!!error}
      helperText={error ? error.message : null}
      inputProps={{
        maxLength: 15,
      }}
    />
  );
};

export default PhoneInput;