import { Box, Button, Card, CardContent, Divider, Grid, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Pencil, Plus, Trash2 } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CostItemUnit } from '../../constant/CostItemUnit';
import { CurrencyUnit } from '../../constant/CurrencyUnit';
import { VatRateUnit } from '../../constant/VatRateUnit';

interface PaymentItem {
    id: number | null,
    date: string,
    type: string,
    amount: number
}

interface CostItem {
    id: number | null;
    productName: string;
    quantity: number;
    unit: string;
    unitPrice: number;
    vatRate: number;
    discountRate: number;
    total?: number;
}

interface CostItemTotal {
    id: number | null;
    subTotal: number;
    totalDiscount: number;
    totalVat: number;
    grandTotal: number;
    offer: number;
}

interface CostItemsProps {
    initialData?: CostItem[];
    costItemTotal?: CostItemTotal;
    initialPaymentItems?: PaymentItem[];
    onChange?: (items: CostItem[], itemTotal: CostItemTotal, paymentItems: PaymentItem[]) => void;
}

const calculateItemTotal = (item: Partial<CostItem>) => {
    if (!item.quantity || !item.unitPrice) return 0;

    const baseAmount = item.quantity * item.unitPrice;
    const afterDiscount = baseAmount * ((100 - (item.discountRate || 0)) / 100);
    return afterDiscount * ((100 + (item.vatRate || 0)) / 100);
};

const calculateSubTotal = (items: CostItem[]) => {
    return items.reduce((sum, item) => sum + (item.quantity * item.unitPrice), 0);
};

const calculateTotalDiscount = (items: CostItem[]) => {
    return items.reduce((sum, item) => {
        const baseAmount = item.quantity * item.unitPrice;
        return sum + (baseAmount * (item.discountRate || 0) / 100);
    }, 0);
};

const calculateTotalVat = (items: CostItem[]) => {
    return items.reduce((sum, item) => {
        const baseAmount = item.quantity * item.unitPrice;
        const afterDiscount = baseAmount * ((100 - (item.discountRate || 0)) / 100);
        return sum + (afterDiscount * (item.vatRate || 0) / 100);
    }, 0);
};

const CostItems: React.FC<CostItemsProps> = ({ initialData = [], costItemTotal, initialPaymentItems = [], onChange }) => {
    const [items, setItems] = useState<CostItem[]>(initialData);
    const [paymentItems, setPaymentItems] = useState<PaymentItem[]>(initialPaymentItems);
    const [currentPaymentItemIndex, setCurrentPaymentItemIndex] = React.useState<number | null>(null);
    const [currentPaymentItem, setCurrentPaymentItem] = useState<Partial<PaymentItem>>({
        id: null,
        date: '',
        type: '',
        amount: undefined
    });
    const [itemTotal, setItemTotal] = useState<CostItemTotal>(costItemTotal || {
        id: null,
        subTotal: 0,
        totalDiscount: 0,
        totalVat: 0,
        grandTotal: 0,
        offer: 0
    });

    const [otherItemUnit, setOtherItemUnit] = React.useState<string | ''>('');
    const [currentItem, setCurrentItem] = React.useState<Partial<CostItem>>({
        productName: '',
        quantity: undefined,
        unit: '',
        unitPrice: undefined,
        vatRate: undefined,
        discountRate: undefined
    });
    const [currentItemIndex, setCurrentItemIndex] = React.useState<number | null>(null);

    const {
        register,
        formState: { errors },
        setValue,
        getValues,
        watch,
    } = useFormContext();

    useEffect(() => {
        const subTotal = calculateSubTotal(items);
        const totalDiscount = calculateTotalDiscount(items);
        const totalVat = calculateTotalVat(items);
        const grandTotal = subTotal - totalDiscount + totalVat;
        console.log("items changed");
        setValue("contractFee", grandTotal);
    }, [items]);

    // Toplam değerlerin ana forma set edilmesi için effect
    useEffect(() => {
        const subTotal = calculateSubTotal(items);
        const totalDiscount = calculateTotalDiscount(items);
        const totalVat = calculateTotalVat(items);
        const grandTotal = subTotal - totalDiscount + totalVat;

        const newItemTotal = {
            ...itemTotal,
            subTotal,
            totalDiscount,
            totalVat,
            grandTotal,
            offer: getValues('contractFee') || 0
        };

        setItemTotal(newItemTotal);

        // Only call onChange when we have actual changes
        if (onChange) {
            onChange(items, newItemTotal, paymentItems);
        }
    }, [items, getValues('contractFee'), setValue, paymentItems]);

    const handleAddItem = () => {
        let unit = currentItem.unit;
        if (unit && unit === 'Diğer')
            unit = otherItemUnit;
        const newItem: CostItem = {
            productName: currentItem.productName || '',
            quantity: currentItem.quantity || 0,
            unit: unit || '', // Direkt currentItem'dan al
            unitPrice: currentItem.unitPrice || 0,
            vatRate: currentItem.vatRate || 0,
            discountRate: currentItem.discountRate || 0,
            total: calculateItemTotal(currentItem),
            id: currentItem.id || null
        };

        if (currentItemIndex !== null) {
            setItems(prevItems => {
                const updatedItems = [...prevItems];
                updatedItems[currentItemIndex] = newItem;
                return updatedItems;
            });
        } else {
            setItems(prevItems => [...prevItems, newItem]);
        }

        setCurrentItem({
            productName: '',
            quantity: undefined,
            unit: '',
            unitPrice: undefined,
            vatRate: undefined,
            discountRate: undefined,
            id: null
        });
        setCurrentItemIndex(null);
        setOtherItemUnit('');
    };

    const handleEdit = (index: number) => {
        let itemToEdit = items[index];
        const exists = CostItemUnit.some(item => item.value === itemToEdit.unit);
        if (!exists) {
            setOtherItemUnit(itemToEdit.unit);
            itemToEdit.unit = 'Diğer';
        }
        setCurrentItem(itemToEdit);
        setCurrentItemIndex(index);
    };

    const handleDelete = (index: number) => {
        const updatedItems = [...items];
        updatedItems.splice(index, 1);
        setItems(updatedItems);
    };

    const handleAddPaymentItem = () => {
        const newItem: PaymentItem = {
            id: currentPaymentItem.id || null,
            date: currentPaymentItem.date || '',
            type: currentPaymentItem.type || '',
            amount: currentPaymentItem.amount || 0
        };

        if (currentPaymentItemIndex !== null) {
            setPaymentItems(prevItems => {
                const updatedItems = [...prevItems];
                updatedItems[currentPaymentItemIndex] = newItem;
                return updatedItems;
            });
        } else {
            setPaymentItems(prevItems => [...prevItems, newItem]);
        }

        setCurrentPaymentItem({
            id: null,
            date: '',
            type: '',
            amount: undefined
        });
        setCurrentPaymentItemIndex(null);
        /* console.log("3");console.log(items)
        onChange?.(items); */
    };

    const handleUnitChange = (e: any) => {
        const selectedUnit = e.target.value;
        setCurrentItem({ ...currentItem, unit: selectedUnit })
        if (selectedUnit && selectedUnit !== 'Diğer')
            setOtherItemUnit('');
    }
    const handlePaymentItemEdit = (index: number) => {
        const itemToEdit = paymentItems[index];
        setCurrentPaymentItem(itemToEdit);
        setCurrentPaymentItemIndex(index);
    };

    const handlePaymentItemDelete = (index: number) => {
        const updatedItems = [...paymentItems];
        updatedItems.splice(index, 1);
        setPaymentItems(updatedItems);
    };

    const validatePaymentItem = () => {
        if (!currentPaymentItem.date || currentPaymentItem.date.trim() === '') {
            return false;
        }

        if (!currentPaymentItem.type || currentPaymentItem.type.trim() === '') {
            return false;
        }

        if (!currentPaymentItem.amount || currentPaymentItem.amount <= 0 || currentPaymentItem.amount > 1000000000) {
            return false;
        }

        return true;
    }

    const validateForm = () => {
        if (!currentItem.productName || currentItem.productName.trim() === '') {
            return false;
        }
        if (!currentItem.quantity || currentItem.quantity <= 0 || currentItem.quantity > 1000) {
            return false;
        }
        if (!currentItem.unit) {
            return false;
        }
        if (currentItem.unit && currentItem.unit === 'Diğer' && (!otherItemUnit || otherItemUnit === '' || otherItemUnit === null)) {
            return false;
        }
        if (!currentItem.unitPrice || currentItem.unitPrice <= 0 || currentItem.unitPrice > 100000) {
            return false;
        }
        if (currentItem.vatRate && (currentItem.vatRate < 0 || currentItem.vatRate > 200)) {
            return false;
        }
        if (currentItem.discountRate && (currentItem.discountRate < 0 || currentItem.discountRate > 99)) {
            return false;
        }
        return true;
    };

    const subTotal = calculateSubTotal(items);
    const totalDiscount = calculateTotalDiscount(items);
    const totalVat = calculateTotalVat(items);
    const grandTotal = subTotal - totalDiscount + totalVat;

    const handleContractFeeChange = (value: number) => {
        setValue('contractFee', value);
        setItemTotal(prev => ({
            ...prev,
            offer: value
        }));
    };

    return (
        <Card sx={{ mb: 1 }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            value={currentItem.productName || ''}
                            onChange={(e) => setCurrentItem({ ...currentItem, productName: e.target.value })}
                            label="Ürün/Hizmet Adı"
                            fullWidth
                            error={!currentItem.productName}
                            helperText={!currentItem.productName ? 'Ürün/Hizmet adı zorunludur' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            value={currentItem.quantity || ''}
                            onChange={(e) => setCurrentItem({ ...currentItem, quantity: parseFloat(e.target.value) })}
                            type="number"
                            label="Miktar"
                            fullWidth
                            error={!currentItem.quantity || currentItem.quantity <= 0 || currentItem.quantity > 1000000}
                            helperText={!currentItem.quantity || currentItem.quantity <= 0 ? 'Miktar 0\'dan büyük olmalıdır' : currentItem.quantity > 1000000 ? 'Miktar 1.000.0000\'den büyük olamaz' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            value={currentItem.unit || ''}
                            onChange={(e) => handleUnitChange(e)}
                            label="Birim"
                            fullWidth
                            select
                            error={!currentItem.unit}
                            helperText={!currentItem.unit ? 'Birim seçimi zorunludur' : ''}
                        >
                            {CostItemUnit.map((unit) => (
                                <MenuItem key={unit.value} value={unit.value}>
                                    {unit.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {currentItem.unit === 'Diğer' ? (
                        <Grid item xs={12} sm={3}>
                            <TextField
                                value={otherItemUnit || ''}
                                onChange={(e) => setOtherItemUnit(e.target.value)}
                                label="Diğer Birim"
                                fullWidth
                                error={currentItem.unit === 'Diğer' && (!otherItemUnit || otherItemUnit === '' || otherItemUnit === null)}
                                helperText={(currentItem.unit === 'Diğer' && (!otherItemUnit || otherItemUnit === '' || otherItemUnit === null)) ? 'Diğer Birim girin' : ''}
                            />
                        </Grid>
                    ) : null}
                    <Grid item xs={12} sm={3}>
                        <TextField
                            value={currentItem.unitPrice || ''}
                            onChange={(e) => setCurrentItem({ ...currentItem, unitPrice: parseFloat(e.target.value) })}
                            type="number"
                            label="Birim Fiyat"
                            fullWidth
                            error={!currentItem.unitPrice || currentItem.unitPrice <= 0 || currentItem.unitPrice > 100000}
                            helperText={!currentItem.unitPrice || currentItem.unitPrice <= 0 ? 'Birim fiyat 0\'dan büyük olmalıdır' : currentItem.unitPrice > 10000000 ? 'Birim fiyat 10.000.000\'den büyük olamaz' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            value={currentItem.vatRate || ''}
                            onChange={(e) => setCurrentItem({ ...currentItem, vatRate: parseFloat(e.target.value) })}
                            select
                            label="KDV"
                            fullWidth
                            error={!currentItem.vatRate}
                            helperText={!currentItem.vatRate ? 'KDV oranı seçin' : ''}
                        >
                            {VatRateUnit.map((unit) => (
                                <MenuItem key={unit.value} value={unit.value}>
                                    {unit.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            value={currentItem.discountRate || ''}
                            onChange={(e) => setCurrentItem({ ...currentItem, discountRate: parseFloat(e.target.value) })}
                            type="number"
                            label="İndirim %"
                            fullWidth
                            error={currentItem.discountRate !== undefined && (currentItem.discountRate < 0 || currentItem.discountRate > 99)}
                            helperText={currentItem.discountRate !== undefined && (currentItem.discountRate < 0 || currentItem.discountRate > 99) ? 'İndirim oranı 0-99 arasında olmalıdır' : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="body1" fontWeight="bold">
                                Toplam: {calculateItemTotal(currentItem).toFixed(2)}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddItem}
                                startIcon={<Plus />}
                                disabled={!validateForm()}
                            >
                                {currentItemIndex !== null ? 'Güncelle' : 'Ekle'}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>

            <Divider />

            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Ürün/Hizmet</TableCell>
                            <TableCell align="right">Miktar</TableCell>
                            <TableCell align="right">Birim</TableCell>
                            <TableCell align="right">Birim Fiyat</TableCell>
                            <TableCell align="right">KDV %</TableCell>
                            <TableCell align="right">İndirim %</TableCell>
                            <TableCell align="right">Toplam</TableCell>
                            <TableCell align="center">İşlemler</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.productName}</TableCell>
                                <TableCell align="right">{item.quantity}</TableCell>
                                <TableCell align="right">{item.unit}</TableCell>
                                <TableCell align="right">{item.unitPrice.toFixed(2)}</TableCell>
                                <TableCell align="right">{item.vatRate || 0}</TableCell>
                                <TableCell align="right">{item.discountRate || 0}</TableCell>
                                <TableCell align="right">{item.total?.toFixed(2)}</TableCell>
                                <TableCell align="center">
                                    <IconButton
                                        onClick={() => handleEdit(index)}

                                        color="primary"
                                        size="small"
                                    >
                                        <Pencil />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleDelete(index)}
                                        color="error"
                                        size="small"
                                    >
                                        <Trash2 />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>

            <Divider />

            <CardContent>
                <Box display="flex" justifyContent="flex-end">
                    <Table sx={{ maxWidth: '400px' }}>
                        <TableBody>
                            <TableRow>
                                <TableCell>Ara Toplam:</TableCell>
                                <TableCell align="right">{subTotal.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Toplam İndirim:</TableCell>
                                <TableCell align="right">{totalDiscount.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Toplam KDV:</TableCell>
                                <TableCell align="right">{totalVat.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="subtitle1" fontWeight="bold">Genel Toplam:</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="subtitle1" fontWeight="bold">{grandTotal.toFixed(2)}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="subtitle1" fontWeight="bold">Ödenecek Tutar:</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <TextField
                                        id='contractFee'
                                        type="number"
                                        label="Ödenecek Tutar"
                                        fullWidth
                                        inputProps={{
                                            min: 0,
                                            max: 1000000000,
                                            step: "any"
                                        }}
                                        {...register("contractFee", {
                                            required: false,
                                            setValueAs: (value: string) => value === '' ? 0 : parseFloat(value),
                                            min: {
                                                value: 0,
                                                message: "Ödenecek tutar 0'dan küçük olamaz"
                                            },
                                            max: {
                                                value: 1000000000,
                                                message: "Ödenecek tutar 1 milyardan büyük olamaz"
                                            },

                                            validate: {
                                                positive: (value) => value >= 0 || "Ödenecek tutar 0'dan küçük olamaz",
                                                lessThanBillion: (value) => value <= 1000000000 || "Ödenecek tutar 1 milyardan büyük olamaz"
                                            }
                                        })}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </CardContent>
            <Divider />
            <CardContent>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 3, width: '100%' }}>
                    <TextField
                        id="payment-date"
                        type="date"
                        label="Ödeme Vadesi"
                        //variant="outlined"
                        value={currentPaymentItem.date || ''}
                        onChange={(e) => setCurrentPaymentItem({ ...currentPaymentItem, date: e.target.value })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        //margin="normal"
                        error={!currentPaymentItem.date || currentPaymentItem.date.trim() === ''}
                        helperText={!currentPaymentItem.date || currentPaymentItem.date.trim() === '' ? 'Tarih seçin' : ''}
                    />
                    <TextField
                        id="payment-Type"
                        variant="outlined"
                        label="Ödeme Türü"
                        fullWidth
                        //margin="normal"
                        select
                        value={currentPaymentItem.type || ''}
                        onChange={(e) => setCurrentPaymentItem({ ...currentPaymentItem, type: e.target.value })}
                        error={!currentPaymentItem.type || currentPaymentItem.type.trim() === ''}
                        helperText={!currentPaymentItem.type || currentPaymentItem.type.trim() === '' ? 'Ödeme Türü seçin' : ''}
                    >
                        <MenuItem sx={{ minWidth: 200 }} value={"Nakit"}>
                            Nakit
                        </MenuItem>
                        <MenuItem sx={{ minWidth: 200 }} value={"Kredi Kartı"}>
                            Kredi Kartı
                        </MenuItem>
                        <MenuItem sx={{ minWidth: 200 }} value={"Havale/EFT"}>
                            Havale / EFT
                        </MenuItem>
                        <MenuItem sx={{ minWidth: 200 }} value={"Çek"}>
                            Çek
                        </MenuItem>
                        <MenuItem sx={{ minWidth: 200 }} value={"Senet"}>
                            Senet
                        </MenuItem>
                    </TextField>
                    <TextField
                        value={currentPaymentItem.amount || ''}
                        onChange={(e) => setCurrentPaymentItem({ ...currentPaymentItem, amount: parseFloat(e.target.value) })}
                        type="number"
                        label="Tutar"
                        fullWidth
                        error={!currentPaymentItem.amount || currentPaymentItem.amount <= 0 || currentPaymentItem.amount > 10000000}
                        helperText={!currentPaymentItem.amount || currentPaymentItem.amount <= 0 ? 'Birim fiyat 0\'dan büyük olmalıdır' : currentPaymentItem.amount > 10000000 ? 'Birim fiyat 10.000.000\'den büyük olamaz' : ''}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddPaymentItem}
                        startIcon={<Plus />}
                        disabled={!validatePaymentItem()}
                        sx={{
                            height: '56px', // Yüksekliği TextField ile eşitle
                            //gridColumn: 'span 4', // Butonu tüm sütun boyunca genişletmek
                            display: 'flex', // Butonu merkezlemek
                            alignItems: 'center', // Buton metnini dikey olarak ortalamak
                        }}
                    >
                        {currentPaymentItemIndex !== null ? 'Güncelle' : 'Ekle'}
                    </Button>
                </Box>
            </CardContent>
            <Divider />
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Ödeme Vadesi</TableCell>
                            <TableCell>Ödeme Türü</TableCell>
                            <TableCell>Tutar</TableCell>
                            <TableCell align="center">İşlemler</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paymentItems.map((record, index) => (
                            <TableRow key={index}>
                                <TableCell>{record.date}</TableCell>
                                <TableCell>{record.type}</TableCell>
                                <TableCell>{record.amount}</TableCell>
                                <TableCell align="center">
                                    <IconButton
                                        onClick={() => handlePaymentItemEdit(index)} // Güncelle
                                        color="primary"
                                        size="small"
                                    >
                                        <Pencil />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handlePaymentItemDelete(index)} // Sil
                                        color="error"
                                        size="small"
                                    >
                                        <Trash2 />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
            <Divider />
            <CardContent>
                <Grid item xs={12} sm={3}>
                    <TextField
                        id="currency"
                        select
                        label="Belge Doviz Türü"
                        fullWidth
                        defaultValue={getValues("currency") || ""}
                        {...register("currency", {required: true})}
                        error={!getValues("currency")}
                        helperText={!getValues("currency") ? "Para Birimi seçin" : ""}
                    >
                        {CurrencyUnit.map((unit) => (
                            <MenuItem key={unit.value} value={unit.value}>
                                {unit.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CostItems;