import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';

const LanguageSwitcher: React.FC = () => {
    // localStorage'dan dil tercihi alınır, yoksa default dil 'en' olur
    const storedLanguage = localStorage.getItem('language');
    const [language, setLanguage] = useState<string>(storedLanguage || i18n.language || 'en'); // İlgili dil değerini başlangıçta ayarla

    useEffect(() => {
        // localStorage'dan dil tercihini kontrol et
        if (storedLanguage) {
            // Eğer dil tercihi varsa, i18next'e de bu dili uygula
            i18n.changeLanguage(storedLanguage);
        } else {
            // Eğer dil tercihi yoksa, otomatik dil algılama mekanizmasını çalıştır
            const detectedLanguage = i18n.language || 'en';
            i18n.changeLanguage(detectedLanguage);
            // Algılanan dil varsa, onu localStorage'a kaydedelim
            localStorage.setItem('language', detectedLanguage);
        }
    }, [storedLanguage]);

    // Dil değiştirildiğinde çalışacak fonksiyon
    const handleLanguageChange = (event: SelectChangeEvent<string>) => {
        const selectedLang = event.target.value;
        
        // Dil değiştirme
        i18n.changeLanguage(selectedLang);

        // Seçilen dili localStorage'da sakla
        localStorage.setItem('language', selectedLang);

        // Dil durumunu güncelle
        setLanguage(selectedLang);
    };

    return (
        <div>
            <FormControl variant="outlined">
                <InputLabel>Language</InputLabel>
                <Select
                    value={language}
                    onChange={handleLanguageChange}
                    label="Language"
                >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="tr">Türkçe</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};

export default LanguageSwitcher;
