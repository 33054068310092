import * as Validation from "yup";

export interface CompanyInfo {
  name: string;
  type: string | null;
  publicOrPrivate: string | null;
  identityNumber: string;
  title: string;
  phoneNumber: string;
  email: string;
  taxOffice: string;
  city: string;
  district: string;
  address: string;
  bankName: string | null;
  bankBranchName: string | null;
  iban: string | null;
}

export class CompanyInfo implements CompanyInfo {
  name = '';
  type: string | null = null;
  publicOrPrivate: string | null = null;
  identityNumber = '';
  title = '';
  phoneNumber = '';
  email = '';
  taxOffice = '';
  city = '';
  district = '';
  address = '';
  bankName: string | null = null;
  bankBranchName: string | null = null;
  iban: string | null = null;

  static create(): CompanyInfo {
    return new CompanyInfo();
  }
}

export const CompanyInfoSchema = (t: Function) => 
  Validation.object().shape({
    name: Validation.string().required(t('validation:NotBlank')),
    type: Validation.string()
      .nullable()
      .default(null)
      .transform((value) => value === undefined ? null : value),
    publicOrPrivate: Validation.string()
      .nullable()
      .default(null)
      .transform((value) => value === undefined ? null : value),
    identityNumber: Validation.string().required(t('validation:NotBlank')),
    title: Validation.string().required(t('validation:NotBlank')),
    phoneNumber: Validation.string().required(t('validation:NotBlank')),
    email: Validation.string().required(t('validation:NotBlank')),
    taxOffice: Validation.string().required(t('validation:NotBlank')),
    city: Validation.string().required(t('validation:NotBlank')),
    district: Validation.string().required(t('validation:NotBlank')),
    address: Validation.string().required(t('validation:NotBlank')),
    bankName: Validation.string()
      .nullable()
      .default(null)
      .transform((value) => value === undefined ? null : value),
    bankBranchName: Validation.string()
      .nullable()
      .default(null)
      .transform((value) => value === undefined ? null : value),
    iban: Validation.string()
      .nullable()
      .default(null)
      .transform((value) => value === undefined ? null : value),
  });