import React from "react"
import "./download.css"

const Download = () => {
  return (
    <>
      <section className='download top'>
        <div className='container flex_space'>
          <div className='row'>
            <h3>Yakında! </h3>
            <h1>Mobil Uygulamamızdan Sözleşme Oluşturabilirsiniz</h1>
            <ul>
              <li>&#10003; Müşterilerle Kolayca Sözleşme Oluşturma ve Gönderim Sağlama</li>
              <li>&#10003; Sözleşme Takip ve Bildirimleri</li>
              <li>&#10003; Sözleşme Arşivleme</li>
              <li>&#10003; Genel Sözleşme Şablonları</li>
              <li>&#10003; Güvenli Sözleşme Sorgulama</li>
              <li>&#10003; Sözleşme Taslağı Oluşturma</li>
            </ul>
            <div className='img flex'>
              <img src='/images/appstore-button.png' alt='' />
              <img src='/images/google-play-button.png' alt='' />
            </div>
          </div>
          <div className='row row2'>
            <img src='/images/app-image-1.png' alt='' className='image' />
          </div>
        </div>
      </section>
    </>
  )
}

export default Download
