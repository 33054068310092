import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import common_en from './english/common.json';
import companySubscription_en from './english/companySubscription.json';
import returnCode_en from './english/returnCode.json';
import validation_en from './english/validation.json';

import common_tr from './turkish/common.json';
import companySubscription_tr from './turkish/companySubscription.json';
import returnCode_tr from './turkish/returnCode.json';
import validation_tr from './turkish/validation.json';

export const defaultNS = 'common';
export const resources = {
    en: {
        common: common_en,
        validation: validation_en,
        returnCode: returnCode_en,
        companySubscription: companySubscription_en
    },
    tr: {
        common: common_tr,
        validation: validation_tr,
        returnCode: returnCode_tr,
        companySubscription: companySubscription_tr
    },
} as const;

const setup = () => {
    const savedLanguage = localStorage.getItem('language');
    const detectedLanguage = savedLanguage || navigator.language.split('-')[0] || 'en';

    i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            resources,
            fallbackLng: 'en',
            lng: detectedLanguage, // Burada dil tercihi `localStorage` veya otomatik algılama ile belirlenir
            ns: ['common', 'validation', 'returnCode'],
            defaultNS,
            interpolation: {
                escapeValue: false,
            },
            detection: {
                order: ['navigator', 'htmlTag', 'path', 'subdomain'],
                caches: ['localStorage'], // Dil tercihini localStorage'a kaydederiz
            },
        });
};

export default setup;
