import React from "react";
import "../Contract/ContractTypes.css";
import InvoiceLinearStrepper from "./InvoiceLinearStrepper";

const EntryInvoice = () => {
  return (
    <>
      <section className="blog top">
        <InvoiceLinearStrepper />
      </section>
    </>
  );
};

export default EntryInvoice;
