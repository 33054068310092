import axios from 'axios';
import ReactDOM from 'react-dom';
import App from './App';
import { API_BASE_URL } from './config/env';
import { ResponseCode } from './constant/ResponseCode';
import './index.css';
import setup from './localization/setup';

setup();
axios.defaults.baseURL = API_BASE_URL;
//axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
//axios.defaults.headers.post['Content-Type'] = 'application/json';

/* axios.interceptors.request.use(request => {
    console.log(request);
    // Edit request config
    return request;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    console.log(response);
    //edit response config
    return response;
}, error => {
    console.log(error);
    return Promise.reject(error);
}); */

axios.interceptors.response.use(
    (response) => {
      // Başarılı yanıtlar için, güvenli bir şekilde 'code' alanını kontrol et
      if (response.data && typeof response.data === 'object' && 'code' in response.data) {
        if (response.data.code === ResponseCode.SYSTEM_ERROR) {
          console.error('Backend Error (code 500):', response.data.message);
          return Promise.reject(new Error('Backend returned code 500'));
        }
      }
      return response;
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
  


ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();


