import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const SecuredRoute = ({
  adminComponent: AdminComponent,
  component: Component,
  security,
  ...otherProps
}) => (
  <Route
    {...otherProps}
    render={(props) =>
      security.validToken === true ? (
        getComponentByUserType(
          security.user["role"],
          props,
          Component,
          AdminComponent
        )
      ) : (
        // (1 === 1) ? (<Component {...props} />) : (<AdminComponent {...props} />)
        <Redirect to="/login" />
      )
    }
  />
);

SecuredRoute.propTypes = {
  security: PropTypes.object.isRequired,
};

const getComponentByUserType = (role, props, Component, AdminComponent) => {
  if (role === "ROLE_COMPANY_REPRESENTATIVE" || role === "ROLE_USER") {
    return <Component {...props} />;
  } else if (role === "ROLE_ADMIN") {
    return <AdminComponent {...props} />;
  }
};

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps)(SecuredRoute);
