export const CostItemUnit = [
  {label:'Adet', value:'Adet'},
  {label: 'Metreküp', value:'Metreküp'},
  {label: 'Litre', value:'Litre'},
  {label: 'Kilogram', value:'Kilogram'},
  {label: 'Gram', value:'Gram'},
  {label:'Metre', value:'Metre'},
  {label:'Saat', value:'Saat'},
  {label:'Gün', value:'Gün'},
  {label:'Santimetreküp', value:'Santimetreküp'},
  {label:'Santimetre', value:'Santimetre'},
  {label:'Milimetre', value:'Milimetre'},
  {label:'Kilowatt Saat', value:'Kilowatt Saat'},
  {label:'Ton', value:'Ton'},
  {label:'Parça (Each)', value:'Parça (Each)'},
  {label:'Set', value:'Set'},
  {label:'Çift (Pair)', value:'Çift (Pair)'},
  {label:'Kutu', value:'Kutu'},
  {label:'Paket', value:'Paket'},
  {label:'Çuval', value:'Çuval'},
  {label:'Düzine (Dozen)', value:'Düzine (Dozen)'},
  {label:'Feetküp', value:'Feetküp'},
  {label:'Pound', value:'Pound'},
  {label:'İnç', value:'İnç'},
  {label:'Metrekare', value:'Metrekare'},
  {label:'Feetkare', value:'Feetkare'},
  {label:'Karet', value:'Karet'},
  {label:'Şişe', value:'Şişe'},
  {label:'Tanker', value:'Tanker'},
  {label:'Diğer', value:'Diğer'}
  ];