import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const RentalContractTransform = (props) => {
  const user = useSelector((state) => state.security.user);
  const userId = user.id;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
  const tenantFirstName = props.rentalContractInformations.tenantFirstName;
  const tenantLastName = props.rentalContractInformations.tenantLastName;
  const tenantIdentifierNumber =
    props.rentalContractInformations.tenantIdentifierNumber;
  const tenantCity = props.rentalContractInformations.tenantCity;
  const tenantDistrict = props.rentalContractInformations.tenantDistrict;
  const tenantAddress = props.rentalContractInformations.tenantAddress;
  const tenantPhoneNumber = props.rentalContractInformations.tenantPhoneNumber;
  const guarantorFirstName =
    props.rentalContractInformations.guarantorFirstName;
  const guarantorLastName = props.rentalContractInformations.guarantorLastName;
  const guarantorPhoneNumber =
    props.rentalContractInformations.guarantorPhoneNumber;
  const ownerFirstName = props.rentalContractInformations.ownerFirstName;
  const ownerLastName = props.rentalContractInformations.ownerLastName;
  const ownerIdentifierNumber =
    props.rentalContractInformations.ownerIdentifierNumber;
  const ownerCity = props.rentalContractInformations.ownerCity;
  const ownerDistrict = props.rentalContractInformations.ownerDistrict;
  const ownerAddress = props.rentalContractInformations.ownerAddress;
  const ownerPhoneNumber = props.rentalContractInformations.ownerPhoneNumber;
  const ownerIBAN = props.rentalContractInformations.ownerIBAN;
  const mernisId = props.rentalContractInformations.mernisId;
  const assetType = props.rentalContractInformations.assetType;
  const assetProvince = props.rentalContractInformations.assetProvince;
  const assetDistrict = props.rentalContractInformations.assetDistrict;
  const assetNeighbourhood =
    props.rentalContractInformations.assetNeighbourhood;
  const assetStreet = props.rentalContractInformations.assetStreet;
  const assetBuildingNo = props.rentalContractInformations.assetBuildingNo;
  const numberOfRoom = props.rentalContractInformations.numberOfRoom;
  const assetSquareMeters = props.rentalContractInformations.assetSquareMeters;
  const processType = props.rentalContractInformations.processType;
  const rentalStartDate = props.rentalContractInformations.rentalStartDate;
  const rentalPeriod = props.rentalContractInformations.rentalPeriod;
  const rentalPeriodYear = props.rentalContractInformations.rentalPeriodYear;
  const rentalPeriodMonth = props.rentalContractInformations.rentalPeriodMonth;
  const deposit = props.rentalContractInformations.deposit;
  const monthlyRentalFee = props.rentalContractInformations.monthlyRentalFee;
  const rentalRaiseRate = props.rentalContractInformations.rentalRaiseRate;
  const timeToPayRent = props.rentalContractInformations.timeToPayRent;
  const fixture = props.rentalContractInformations.fixture;
  const purposeOfUsage = props.rentalContractInformations.purposeOfUsage;
  const rentalPaymentMethod =
    props.rentalContractInformations.rentalPaymentMethod;
  const stateOfAsset = props.rentalContractInformations.stateOfAsset;
  const yearlyRentalPay = props.rentalContractInformations.yearlyRentalPay;
  const bankName = props.rentalContractInformations.bankName;
  const bankBranchName = props.rentalContractInformations.bankBranchName;
  const evacuationNotice = props.rentalContractInformations.evacuationNotice;
  const contractDateNow = props.rentalContractInformations.contractDateNow;
  const contractName = props.rentalContractInformations.contractName;
  const contractTypeId = props.rentalContractInformations.contractTypeId;

  const sendRentalContract = () => {
    const rentalContract = {
      tenantFirstName: tenantFirstName,
      tenantLastName: tenantLastName,
      tenantIdentifierNumber: tenantIdentifierNumber,
      tenantCity: tenantCity,
      tenantDistrict: tenantDistrict,
      tenantAddress: tenantAddress,
      tenantPhoneNumber: tenantPhoneNumber,
      guarantorFirstName: guarantorFirstName,
      guarantorLastName: guarantorLastName,
      guarantorPhoneNumber: guarantorPhoneNumber,
      ownerFirstName: ownerFirstName,
      ownerLastName: ownerLastName,
      ownerIdentifierNumber: ownerIdentifierNumber,
      ownerCity: ownerCity,
      ownerDistrict: ownerDistrict,
      ownerAddress: ownerAddress,
      ownerPhoneNumber: ownerPhoneNumber,
      ownerIBAN: ownerIBAN,
      mernisId: mernisId,
      assetType: assetType,
      assetProvince: assetProvince,
      assetDistrict: assetDistrict,
      assetNeighbourhood: assetNeighbourhood,
      assetStreet: assetStreet,
      assetBuildingNo: assetBuildingNo,
      numberOfRoom: numberOfRoom,
      assetSquareMeters: assetSquareMeters,
      processType: processType,
      rentalStartDate: rentalStartDate,
      rentalPeriod: rentalPeriod,
      rentalPeriodMonth: rentalPeriodMonth,
      rentalPeriodYear: rentalPeriodYear,
      deposit: deposit,
      monthlyRentalFee: monthlyRentalFee,
      rentalRaiseRate: rentalRaiseRate,
      timeToPayRent: timeToPayRent,
      fixture: fixture,
      rentalPaymentMethod: rentalPaymentMethod,
      purposeOfUsage: purposeOfUsage,
      stateOfAsset: stateOfAsset,
      yearlyRentalPay: yearlyRentalPay,
      contractId: "",
      bankName: bankName,
      bankBranchName: bankBranchName,
      evacuationNotice: evacuationNotice,
      contractDateNow: contractDateNow,
      contractName: contractName,
      userId: userId,
      userType: "EstateAgent",
      contractTypeId: contractTypeId,
    };

    setShow(false);

    const contractHeader = {
      //contractType: "kira sözleşmesi kadıköy",
      //userId: userId
    };

    Object.entries(contractHeader).forEach(([key, value]) => {
      rentalContract[key] = value;
    });

    axios
      .post(`api/contract/create-contract`, rentalContract)
      .then((res) => {
        setShow(false);

        if (res.status === 200) {
          toast.success(
            "Kira Sözleşmeniz Oluşturuldu. Anasayfaya Yönlendiriliyorsunuz ...",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );

          setTimeout(() => {
            history.push("/dashboard");
          }, 6000);
        }
      })
      .catch((error) => {
        toast.success("Sözleşmeniz Oluşturulurken hata oluştu.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <Table stripped bordered hover>
        <th>Kiracı Bilgileri</th>
        <tbody>
          <tr>
            <td>Ad</td>
            <td>{tenantFirstName}</td>
          </tr>
          <tr>
            <td>Soyad</td>
            <td>{tenantLastName}</td>
          </tr>
          <tr>
            <td>TC/VK No</td>
            <td>{tenantIdentifierNumber}</td>
          </tr>
          <tr>
            <td>Telefon Numarası:</td>
            <td>{tenantPhoneNumber}</td>
          </tr>
          <tr>
            <td>Kiracı Adresi</td>
            <td>
              {tenantAddress} - {tenantDistrict} / {tenantCity}
            </td>
          </tr>
          <tr>
            <td>Kefil İsim</td>
            <td>{guarantorFirstName}</td>
          </tr>
          <tr>
            <td>Kefil Soyisim</td>
            <td>{guarantorLastName}</td>
          </tr>
          <tr>
            <td>Kefil Telefon Numarası</td>
            <td>{guarantorPhoneNumber}</td>
          </tr>
        </tbody>
        <th>Mülk Sahibi Bilgileri</th>
        <tbody>
          <tr>
            <td>Ad</td>
            <td>{ownerFirstName}</td>
          </tr>
          <tr>
            <td>Soyad</td>
            <td>{ownerLastName}</td>
          </tr>
          <tr>
            <td>TC/VK No</td>
            <td>{ownerIdentifierNumber}</td>
          </tr>
          <tr>
            <td>Telefon Numarası</td>
            <td>{ownerPhoneNumber}</td>
          </tr>
          <tr>
            <td>Mülk Sahibi Adresi</td>
            <td>
              {ownerAddress} - {ownerDistrict}/{ownerCity}
            </td>
          </tr>
          <tr>
            <td>IBAN</td>
            <td>{ownerIBAN}</td>
          </tr>
        </tbody>
        <th>Mülk Bilgileri</th>
        <tbody>
          <tr>
            <td>MERNIS ID</td>
            <td>{mernisId}</td>
          </tr>
          <tr>
            <td>Varlık Tipi</td>
            <td>{assetType}</td>
          </tr>
          <tr>
            <td>İl</td>
            <td>{assetProvince}</td>
          </tr>
          <tr>
            <td>İlçe</td>
            <td>{assetDistrict}</td>
          </tr>
          <tr>
            <td>Semt/Mahalle</td>
            <td>{assetNeighbourhood}</td>
          </tr>
          <tr>
            <td>Sokak/Cadde</td>
            <td>{assetStreet}</td>
          </tr>
          <tr>
            <td>Bina No / Kapı No</td>
            <td>{assetBuildingNo}</td>
          </tr>
          <tr>
            <td>Oda Sayısı</td>
            <td>{numberOfRoom}</td>
          </tr>
          <tr>
            <td>Metrekare</td>
            <td>{assetSquareMeters}</td>
          </tr>
        </tbody>
        <th>Sözleşme Maddeleri</th>
        <tbody>
          <tr>
            <td>Kira Başlangıç Tarihi</td>
            <td>{rentalStartDate}</td>
          </tr>
          <tr>
            <td>Kira Süresi</td>
            <td>
              {" "}
              {rentalPeriodMonth} {rentalPeriodYear} {rentalPeriod}
            </td>
          </tr>
          <tr>
            <td>Depozito</td>
            <td>{deposit}</td>
          </tr>
          <tr>
            <td>Aylık Kira Bedeli</td>
            <td>{monthlyRentalFee}</td>
          </tr>
          <tr>
            <td>Kira Zam Oranı</td>
            <td>{rentalRaiseRate}</td>
          </tr>
          <tr>
            <td>Kira Ödeme Günü </td>
            <td>Her ayın {timeToPayRent}. Günü</td>
          </tr>
          <tr>
            <td>Demirbaş</td>
            <td>{fixture}</td>
          </tr>
          <tr>
            <td>Kullanım Amacı</td>
            <td>{purposeOfUsage}</td>
          </tr>
          <tr>
            <td>Kira Ödeme Yöntemi</td>
            <td>{rentalPaymentMethod}</td>
          </tr>
          <tr>
            <td>Banka Adı</td>
            <td>{bankName}</td>
          </tr>
          <tr>
            <td>Banka Şube Adı</td>
            <td>{bankBranchName}</td>
          </tr>
        </tbody>
      </Table>

      {/* <button onClick={(e) => create()} className="btn btn-danger btn-cli" type="button" id="button-addon2"><i className="far fa-file-pdf"></i> Sözleşmeyi İndir</button> */}
      <span> </span>
      <button
        className="btn btn-success"
        onClick={handleShow}
        type="button"
        style={{ marginRight: "'100px'" }}
      >
        Gönder
      </button>
      <ToastContainer />
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Ev Sahibi Bilgileri</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {ownerFirstName} {ownerLastName}{" "}
          </Modal.Body>
          <Modal.Body>Telefon: {ownerPhoneNumber} </Modal.Body>
          <Modal.Header closeButton>
            <Modal.Title>Kiracı Bilgileri</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {tenantFirstName} {tenantLastName}{" "}
          </Modal.Body>
          <Modal.Body>Telefon: {tenantPhoneNumber} </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Kapat
            </Button>
            <Button variant="primary" onClick={(e) => sendRentalContract()}>
              Gönder
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default RentalContractTransform;
