import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { createNewUser } from "../../actions/securityActions";
import { RegisterFrom } from "../../actions/types";
import "./Login.css";

class Register extends Component {
  constructor() {
    super();

    this.state = {
      firstName: "",
      lastName: "",
      username: "",
      phoneNumber: "",
      userType: "",
      password: "",
      confirmPassword: "",
      identityNumber: "",
      errors: {},
      captcha: null
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.security.validToken) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log("will receive ")
    console.log(nextProps)
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    if(this.state.captcha === null) {
      this.setState({errors: {captcha: "Lütfen robot olmadığınızı doğrulayın."}})
      return;
    }

    const newUser = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      username: this.state.username,
      phoneNumber: this.state.phoneNumber,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      registerFrom: RegisterFrom.Dijisoz
      //identityNumber: this.state.identityNumber
    };

    const registerRequest = {
      user: newUser,
      captcha: this.state.captcha
    }

    this.props.createNewUser(registerRequest, this.props.history);
    console.log("hata oluştu......:")
    console.log(this.state.errors)
    this.recaptchaRef.current.reset();
    this.setState({captcha: null});
    if (this.state.errors) {
        console.log("hata oluştu......:")
        console.log(this.state.errors)
    }
  }

  onChange(e) {

    this.setState({
      [e.target.name]: e.target.value,
      error: e.target.validationMessage
    });

  }

  handleCaptchaChange = (value) => {
    this.setState({captcha: value});
    if(value !== null) {
      this.setState({errors: {captcha: null}})
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <section className="forms top">
        <div className="container">
          <div className="sign-box">
            <p>Hesabınız Yok mu? Kısa bir süre içinde hesabınızı oluşturabilirsiniz.</p>
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">Kayıt Formu</h1>
              <p className="lead text-center">Üyelik Oluştur</p>
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                   /*  className={classnames("form-control form-control-lg", {
                      "is-invalid": errors.firstName
                    })} */
                    placeholder="İsim"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.onChange}
                    // pattern="[A-Za-z0]+"
                    // onInvalid={e => e.target.setCustomValidity('Hatalı İsim girişi')}
                  />
                  {errors.firstName && (
                    <div style={{ color: "red" }}>{errors.firstName}</div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className={classnames("form-control form-control-lg", {
                      "is-invalid": errors.lastName
                    })}
                    placeholder="Soyisim"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.onChange}
                  />
                  {errors.lastName && (
                    <div className="invalid-feedback">{errors.lastName}</div>
                  )}
                </div>
                {/* <div className="form-group">
                  <input
                    type="number"
                    
                    className={classnames("form-control form-control-lg", {
                      "is-invalid": errors.identityNumber
                    })}
                    placeholder="TC Kimlik Numarası"
                    name="identityNumber"
                    value={this.state.identityNumber}
                    onChange={this.onChange}
                    maxLength={11}
                  />
                  {errors.identityNumber && (
                    <div className="invalid-feedback">{errors.identityNumber}</div>
                  )}
                </div> */}
                <div className="form-group">
                  <input
                    type="text"
                    className={classnames("form-control form-control-lg", {
                      "is-invalid": errors.username
                    })}
                    placeholder="Email Adresi"
                    name="username"
                    value={this.state.username}
                    onChange={this.onChange}
                  />
                  {errors.username && (
                    <div className="invalid-feedback">{errors.username}</div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className={classnames("form-control form-control-lg", {
                      "is-invalid": errors.phoneNumber
                    })}
                    placeholder="Telefon Numarası"
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                    onChange={this.onChange}
                  />
                  {errors.phoneNumber && (
                    <div className="invalid-feedback">{errors.phoneNumber}</div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className={classnames("form-control form-control-lg", {
                      "is-invalid": errors.password
                    })}
                    placeholder="Şifre"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                  {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className={classnames("form-control form-control-lg", {
                      "is-invalid": errors.confirmPassword
                    })}
                    placeholder="Şifre Doğrula"
                    name="confirmPassword"
                    value={this.state.confirmPassword}
                    onChange={this.onChange}
                  />
                  {errors.confirmPassword && (
                    <div className="invalid-feedback">
                      {errors.confirmPassword}
                    </div>
                  )}
                  <ReCAPTCHA
                    ref={this.recaptchaRef}
                    sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SECRET}
                    onChange={this.handleCaptchaChange}
                  />
                  {errors.captcha && (
                  <div style={{ color: "red" }}>{errors.captcha}</div>
                )}
                </div>
                <button type='submit' className='primary-btn'>
                  Kayıt Ol
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Register.propTypes = {
  createNewUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  errors: state.errors,
  security: state.security
});
export default connect(
  mapStateToProps,
  { createNewUser }
)(Register);
