import React from "react";
import BasicContractLinearStepper from "./BasicContractLinearStepper";
import { useLocation } from "react-router-dom";

const EntryBasicContract = () => {
  const location = useLocation();
  //console.log(location);

  return (
    <>
      <section className="single-page top">
        <div className="container">
          <BasicContractLinearStepper documentCreationInfo={location.state} />
        </div>
      </section>
    </>
  );
};

export default EntryBasicContract;
