
export const GET_ERRORS = "GET_ERRORS";
export const GET_CONTRACTS = "GET_CONTRACTS";
export const SET_CONTRACTS = "SET_CONTRACTS";
export const GET_CONTRACT= "GET_CONTRACT";
export const DELETE_CONTRACTS = "DELETE_CONTRACTS";
export const GET_BACKLOG = "GET_BACKLOG";
export const GET_PROJECT_TASK = "GET_PROJECT_TASK";
export const DELETE_PROJECT_TASK = "DELETE_PROJECT_TASK";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USER = "SET_USER";
export const GET_ESTATE_AGENT_USER = "GET_ESTATE_AGENT_USER";
export const DocumentTypes = { All : 0, Contract : 1, Offer : 2, Invoice : 3}
export const DocumentStatus = {Draft : 1, SentApprove : 2, Approve : 3, Reject : 4}
export const DocumentCreationType = {FromTemplate : 1, RelatedDocument : 2, Renewal : 3, Update: 4}
export const RegisterFrom = {Dijisoz : 1, Google : 2, Facebook : 3}
export const SignType = {Dizisoz : 1, e : 2, Mobile : 3}
